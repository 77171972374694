<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-secondary-menu">
        <!-- Lens Types -->
        <template
            v-for="tag in $const.lensFilters.level1"
            :key="tag"
        >
            <router-link
                v-slot="{ navigate, href, isActive }"
                :to="{
                    name: 'order-lenses',
                    params: {
                        lang: $route.params.lang,
                        entity_id: $route.params.entity_id,
                    },
                    query: { type: tag }
                }"
                custom
            >
                <a
                    v-has-active-prototypes-by-tag="[tag]"
                    :href="href"
                    class="sl-secondary-menu__link-item"
                    :class="{ 'sl-secondary-menu__link-item--active': isActive && $route.query.type === tag }"
                    @click="trackNav($event, tag, navigate)"
                >
                    {{ $store.getters['prototypes/allTags'].find(t => t.code === tag)?.label || tag }}
                </a>
            </router-link>

            <span class="sl-secondary-menu__separator"></span>
        </template>

        <!-- Cleaning Products -->
        <router-link v-slot="{ navigate, href, isActive }" :to="{ name: 'cleaning-products', params: { lang: $route.params.lang, entity_id: $route.params.entity_id } }" custom>
            <a
                v-has-active-articles-by-tag="['CLEANING_PRODUCT']"
                :href="href"
                class="sl-secondary-menu__link-item"
                :class="{ 'sl-secondary-menu__link-item--active': isActive }"
                @click="navigate"
            >
                {{ $t('secondary_menu.cleaning_products') }}
            </a>
        </router-link>

        <span class="sl-secondary-menu__separator"></span>

        <!-- Accessories -->
        <router-link v-slot="{ navigate, href, isActive }" :to="{ name: 'accessories', params: $route.params }" custom>
            <a
                v-has-active-articles-by-tag="['ACCESSORY']"
                :href="href"
                class="sl-secondary-menu__link-item"
                :class="{ 'sl-secondary-menu__link-item--active': isActive }"
                @click="navigate"
            >
                {{ $t('secondary_menu.accessories') }}
            </a>
        </router-link>

        <span class="sl-secondary-menu__separator"></span>

        <!-- Topographers -->
        <router-link v-slot="{ navigate, href, isActive }" :to="{ name: 'topographers', params: $route.params }" custom>
            <a
                v-has-active-articles-by-tag="['TOPOGRAPHER']"
                :href="href"
                class="sl-secondary-menu__link-item"
                :class="{ 'sl-secondary-menu__link-item--active': isActive }"
                @click="navigate"
            >
                {{ $t('secondary_menu.topographers') }}
            </a>
        </router-link>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'SecondaryMenu',

    methods:
    {
        /**
         * @param {string} item
         */
        trackNav($event, item, navigate)
        {
            const eventName = 'nav-' + item.toLowerCase();
            this.$gtag.event(eventName);

            return navigate($event);
        },

        getTagLabel(code)
        {
            return this.$store.getters['prototypes/allTags'].find(t => t.code === code)?.label || code;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-secondary-menu
{
    @apply flex justify-center items-center;
}

.sl-secondary-menu__link-item
{
    @apply cursor-pointer mx-3 border border-transparent rounded-sm px-3 py-0.5 text-sm leading-4 text-center text-primary-500 transition;

    &:hover,
    &.sl-secondary-menu__link-item--active
    {
        @apply border-primary-500 text-primary-700;
    }
}

.sl-secondary-menu__separator
{
    @apply hidden text-center border-l border-primary-200;

    height: 15px;
}

.sl-secondary-menu__link-item + .sl-secondary-menu__separator:not(.sl-secondary-menu__separator + .sl-secondary-menu__separator):not(:last-child)
{
    @apply inline-block;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <div class="sl-page__header">
                <div class="sl-page__title">
                    {{ $t('home.title') }}
                </div>
            </div>

            <div class="sl-page__content">
                <!-- First Line -->
                <div class="flex w-full">
                    <shortcuts />

                    <orders-follow-up />
                </div>

                <!-- Second Line -->
                <div class="flex w-full">
                    <news-widget />

                    <notifications />
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OrdersFollowUp from './widgets/follow-up/FollowUp.vue';
import NewsWidget     from './widgets/news/NewsWidget.vue';
import Notifications  from './widgets/notifications/Notifications.vue';
import Shortcuts      from './widgets/shortcuts/Shortcuts.vue';

export default
{
    components:
    {
        OrdersFollowUp,
        NewsWidget,
        Notifications,
        Shortcuts,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>

import { isEmpty } from 'lodash-es';
import axios       from '@/axios';
import Parameter   from '@/models/Parameter';

export default class LensPrototype
{
    // ------------------------------------------------------------------------- CLASS MEMBERS

    /**
     * Prototype attributes.
     */
    code         = null;
    model_code   = null;
    variant_code = null;
    version      = null;
    label        = null;
    model_label  = null;
    sort_order   = null;

    /**
     * Parameters of the prototype.
     * @var {Map}
     */
    parameters;

    /**
     * Templating status.
     * @var {Boolean}
     */
    templating = false;

    /**
     * Validating status.
     * @var {Boolean}
     */
    validating = false;

    /**
     * AbortController object.
     */
    abortController = null;

    /**
     * If current lens prototype is based on an existing order.
     * @var {Boolean}
     */
    isReOrder = false;

    /**
     * If current lens prototype is based on an existing order, here
     * are the values of the previous lens.
     */
    previousValues = {};

    /**
     * Set to `true` if prototype has been templated once.
     * @var {Boolean}
     */
    initialized = false;

    // ------------------------------------------------------------------------- CONSTRUCTOR

    constructor(attributes = {})
    {
        console.log('PROTO CREATED WITH ATTRIBUTES: ', attributes);

        this.parameters = new Map();

        if(attributes)
        {
            this.setAttributes(attributes);
        }
    }


    // ------------------------------------------------------------------------- PROTOTYPE ATTRIBUTES

    setAttributes(attributes)
    {
        console.log('PROTO RE-SET');

        for(let key in attributes)
        {
            if(this[key] !== undefined)
            {
                this[key] = attributes[key];
            }
        }
    }


    // ------------------------------------------------------------------------- STATUS

    isInitialized()
    {
        return this.initialized;
    }


    // ------------------------------------------------------------------------- PARAMETERS

    template(eid, initValues = {}, viewAs)
    {
        if(typeof viewAs !== 'string')
        {
            throw new TypeError('LensPrototype.template(): missing required parameter `viewAs`');
        }

        let values;

        // Set "templating" status
        this.templating = true;

        // If a request was already ongoing, abort it
        if(this.abortController)
        {
            this.abortController.abort();
        }

        // Build URL
        const url = '/api/prototype/template/:eid/:prototypeCode'
            .replace(':eid', eid)
            .replace(':prototypeCode', this.code)
            .concat(viewAs === '' ? '' : `?as=${viewAs}`);

        // Template with given values (on intialization) or with parameter values.
        if(isEmpty(initValues))
        {
            values = this.getValues();
            console.log('TEMPLATE WITH PROTOTYPE VALUES', values);
        }
        else
        {
            values = initValues;
            console.log('TEMPLATE WITH INITIAL VALUES', values);
        }

        // Get parameter values
        const data = {
            values,
            old_values:      this.getOldValues(),
            previous_values: this.previousValues,
        };

        // Create AbortController
        this.abortController = new AbortController();

        // Run query
        return axios.post(url, data, { signal: this.abortController.signal })
            .then(response =>
            {
                // Reset AbortController
                this.abortController = null;

                // Set parameters (template).
                this.setParameters(response.data);

                // If initial values have been given => set parameter values with them.
                if(initValues)
                {
                    this.setValues(initValues);
                }

                // For all parameters => set old value from value (must be after this.setParameters())
                this.setOldValuesFromValues();

                // Set as initialized.
                this.initialized = true;

                return response;
            })
            .catch(error =>
            {
                if(!axios.isCancel(error))
                {
                    console.log(error);

                    throw error;
                }
            })
            .finally(() =>
            {
                this.templating = false;
            });
    }

    setParameters(parameters)
    {
        const codes = [];

        // Create parameter instance and add it to parameters map (if not already exists) or update existing parameter instance.
        parameters.forEach(parameter =>
        {
            codes.push(parameter.code);

            if(this.parameters.has(parameter.code))
            {
                this.parameters.get(parameter.code).setProperties(parameter);
            }
            else
            {
                this.parameters.set(parameter.code, new Parameter(parameter));
            }
        });

        // Delete parameter instance that does not exist anymore (regarding given "parameters" arguments).
        this.parameters.forEach((parameter, key) =>
        {
            if(!codes.includes(key))
            {
                this.parameters.delete(key);
            }
        });
    }

    getParametersAsArray()
    {
        let parametersAsArray = [];

        this.parameters.forEach(parameter =>
        {
            parametersAsArray.push(parameter);
        });

        return parametersAsArray;
    }

    getParameters()
    {
        return this.parameters;
    }

    getParameterByCode(parameterCode)
    {
        return this.parameters.has(parameterCode) ? this.parameters.get(parameterCode) : null;
    }

    hasParameter(parameterCode)
    {
        return this.parameters.has(parameterCode);
    }


    // ------------------------------------------------------------------------- VALUES

    setValues(parameterValues)
    {
        for(let parameterCode in parameterValues)
        {
            this.setValue(parameterCode, parameterValues[parameterCode]);
        }
    }

    setValue(parameterCode, parameterValue)
    {
        if(this.parameters.has(parameterCode))
        {
            this.parameters.get(parameterCode).setValue(parameterValue);
        }
    }

    getValues(nonNullValueOnly = false)
    {
        const values = {};

        this.parameters.forEach((parameter, key) =>
        {
            const value = parameter.getValue();

            if(!nonNullValueOnly || value !== null)
            {
                values[key] = value;
            }
        });

        return values;
    }

    getValue(parameterCode)
    {
        if(this.parameters.has(parameterCode))
        {
            return this.parameters.get(parameterCode).getValue();
        }

        return null;
    }


    // ------------------------------------------------------------------------- OLD VALUES

    setOldValuesFromValues()
    {
        this.parameters.forEach(parameter =>
        {
            parameter.setOldValueFromValue();
        });
    }

    getOldValues(nonNullValueOnly = false)
    {
        let oldValues = {};

        this.parameters.forEach((parameter, key) =>
        {
            let oldValue = parameter.getOldValue();

            if(!nonNullValueOnly || oldValue !== null)
            {
                oldValues[key] = oldValue;
            }
        });

        return oldValues;
    }


    // ------------------------------------------------------------------------- PREVIOUS VALUES

    setPreviousValues(previousValues)
    {
        this.previousValues = previousValues;
        this.isReOrder = true;
    }

    getPreviousValues()
    {
        return this.previousValues;
    }

    hasPreviousValues()
    {
        return this.previousValues.length > 0;
    }


    // ------------------------------------------------------------------------- VALIDATION

    validate(eid, options = {})
    {
        // Set "validating" status
        this.validating = true;

        // Build URL
        const url = '/api/prototype/validate/:eid/:prototypeCode'
            .replace(':eid', eid)
            .replace(':prototypeCode', this.code);

        // Get parameter values.
        const data = {
            values:          this.getValues(),
            previous_values: this.previousValues,
            options:         options,
        };

        // Run query
        return axios.post(url, data)
            .then(response =>
            {
                this.clearErrors();

                return response;
            })
            .catch(error =>
            {
                this.clearErrors();
                this.setErrors(error.response.errors);

                console.log('ERRORS:', error.response.errors);

                throw error;
            })
            .finally(() =>
            {
                this.validating = false;
            });
    }

    setErrors(errors)
    {
        for(let parameterCode in errors)
        {
            if(this.parameters.has(parameterCode))
            {
                this.parameters.get(parameterCode).setError(errors[parameterCode][0]);
            }
        }
    }

    getErrorByParameterCode(parameterCode)
    {
        if(this.parameters.has(parameterCode))
        {
            return this.parameters.get(parameterCode).getError();
        }

        return null;
    }

    clearErrors()
    {
        this.parameters.forEach(parameter =>
        {
            parameter.clearError();
        });
    }


    // ------------------------------------------------------------------------- TEMPLATE AND VALIDATE

    templateAndValidate(eid, options, viewAs)
    {
        if(typeof viewAs !== 'string')
        {
            throw new TypeError('LensPrototype.templateAndValidate(): missing required parameter `viewAs`');
        }

        let values;

        // Set "templating" and "validating" status
        this.templating = true;
        this.validating = true;

        // If a request was already ongoing, abort it
        if(this.abortController)
        {
            this.abortController.abort();
        }

        // Build URL
        const url = '/api/prototype/template-validate/:eid/:prototypeCode'
            .replace(':eid', eid)
            .replace(':prototypeCode', this.code)
            .concat(viewAs === '' ? '' : `?as=${viewAs}`);

        console.log('TEMPLATE & VALIDATE WITH PROTOTYPE VALUES', values);

        // Get parameter values
        const data = {
            values:          this.getValues(),
            old_values:      this.getOldValues(),
            previous_values: this.previousValues,
            options:         options,
        };

        // Create AbortController
        this.abortController = new AbortController();

        // Run query
        return axios.post(url, data, { signal: this.abortController.signal })
            .then(response =>
            {
                // Reset AbortController
                this.abortController = null;

                // Set parameters (template).
                this.setParameters(response.data.template);

                // For all parameters => set old value from value (must be after this.setParameters())
                this.setOldValuesFromValues();

                // Suppress all error messages
                this.clearErrors();

                // If there is error messages
                if(response.data.validation)
                {
                    // Set error messages
                    this.setErrors(response.data.validation);
                }

                return response;
            })
            .catch(error =>
            {
                if(!axios.isCancel(error))
                {
                    console.log(error);

                    throw error;
                }
            })
            .finally(() =>
            {
                this.templating = false;
                this.validating = false;
            });
    }
}

import $const from '@/constants/constants';

// Any tag matching PC{number} or PCS{number},
// i.e. PC1, PCS6, PCS20, etc.
export const piecesTagRegex = /^PCS?(\d+)$/;

export const intendedUseTags = {
    [$const.tags.SOFT]:                  'bg-red-500 text-white',
    [$const.tags.RGP]:                   'bg-red-700 text-white',
    [$const.tags.MULTI_PURPOSE]:         'bg-green-500 text-white',
    [$const.tags.OXIDIZER]:              'bg-green-600 text-white',
    [$const.tags.PRESERVATION]:          'bg-green-700 text-white',
    [$const.tags.CLEANER]:               'bg-green-800 text-white',
    [$const.tags.MULTI_PACK]:            'bg-blue-500 text-white',
    [$const.tags.FLIGHT_PACK]:           'bg-blue-600 text-white',
    [$const.tags.ARTIFICIAL_TEARS]:      'bg-yellow-500 text-white',
    [$const.tags.PROSTHETIC_COMPATIBLE]: 'bg-yellow-600 text-white',
};

export function getFilterBadgesCallback()
{
    const tags = Object.keys(intendedUseTags);

    return article => article.tags.map(t =>
    {
        if(!tags.includes(t.code))
        {
            return false;
        }

        return {
            tag:   t.label,
            class: intendedUseTags[t.code],
        };
    }).filter(t => t); // remove empty values
};

export function getBadgesCallback()
{
    return article => article.tags.map(t =>
    {
        if(piecesTagRegex.test(t.code))
        {
            // Replace PC1 => 1×, PCS6 => 6×, PCS20 => 20×, etc.
            return t.code.replace(piecesTagRegex, '$1×');
        }
        else
        {
            return false;
        }
    }).filter(t => t); // remove empty values
};

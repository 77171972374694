<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Order Date -->
    <lsn-table-cell class="sl-order-cell__date">
        {{ odate }}
    </lsn-table-cell>

    <!-- Order Reference -->
    <lsn-table-cell class="sl-order-cell__reference">
        {{ order.reference }}

        <div v-if="sn" class="sl-order-cell__sn">
            SN: {{ sn }}
        </div>
    </lsn-table-cell>

    <!-- Article Details -->
    <lsn-table-cell class="sl-order-cell__article">
        <div class="sl-order-cell__article-wrapper">
            <!-- Article Label -->
            {{ getLabel(order) }}

            <div class="sl-order-subcell__article-label">
                <!-- External Reference (if any) -->
                {{ order.extra?.ext_ref }}
            </div>
        </div>
    </lsn-table-cell>

    <!-- Status -->
    <lsn-table-cell class="sl-order-cell__status">
        <div
            v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
            class="lsn-table__tag"
            :content="at"
        >
            {{ $t(`order_table.status.${status.code}.label`) }}
        </div>
    </lsn-table-cell>

    <!-- Quantity -->
    <lsn-table-cell class="sl-order-cell__quantity">
        {{ order.quantity }}
    </lsn-table-cell>

    <!-- Price -->
    <lsn-table-cell class="sl-order-cell__price">
        <span>{{ order.currency_code }}</span>
        <span>{{ formattedActualPrice }}</span>
    </lsn-table-cell>

    <!-- Action Buttons -->
    <lsn-table-cell class="sl-order-cell__actions">
        <!-- Discount information (if any) -->
        <tippy class="mr-auto">
            <div v-if="order.special_price !== null">
                <mdi-icon icon="mdiSale" class="w-4 h-4 text-primary-500" />
            </div>

            <template #content>
                <div class="text-gray-500">
                    <span class="text-primary-500">{{ $t('order_table.base_price_colon') }}</span>
                    {{ order.currency_code }}&nbsp;{{ formattedBasePrice }}
                    <br>

                    <span class="text-primary-500">{{ $t('order_table.discount_colon') }}</span>
                    {{ formattedDiscount }}
                </div>
            </template>
        </tippy>
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import moment from 'moment';
import {
    actualPrice,
    basePrice,
    discount,
    formattedActualPrice,
    formattedBasePrice,
    formattedDiscount,
    getLabel,
    price,
    quantity,
} from '@/shared/OrderTableSharedObjects';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'DefaultOrderLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        /**
         * Unused, but Vue warns about it being
         * passed if it's not defined here.
         */
        context:
        {
            type:    String,
            default: '',
        },

        order:
        {
            type:     Object,
            required: true,
        },

        isReOrdered:
        {
            type:    Boolean,
            default: false,
        },
    },

    emits: [
        'reordered',
        'unreordered',
    ],

    data()
    {
        return {
            deleteConfirmationOpen: false,
        };
    },

    computed:
    {
        odate()
        {
            return moment(this.order.odate).format('DD.MM.YYYY');
        },

        sn()
        {
            return (this.order.article_data['LENS_SERIAL_NUMBER']) ? this.order.article_data['LENS_SERIAL_NUMBER'] : null;
        },

        status()
        {
            return [].concat(this.order.status).pop(); // last status
        },

        at()
        {
            return moment(this.status.at).format('DD.MM.YYYY | HH:MM');
        },

        quantity,
        price,
        basePrice,
        formattedBasePrice,
        actualPrice,
        formattedActualPrice,
        discount,
        formattedDiscount,
    },

    methods:
    {
        getLabel,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-cell__date
{
    @apply text-gray-400 text-xs;
}

.sl-order-cell__reference
{
    @apply text-gray-400 text-sm text-center;
}

.sl-order-cell__sn
{
    @apply mt-1 text-gray-500 text-xs;
}

.sl-order-cell__article
{
}

.sl-order-subcell__article-label
{
    @apply text-xs text-gray-400 whitespace-nowrap text-ellipsis;
}

.lsn-table__tag
{
    @apply text-xs;
}

.sl-order-cell__base-price
{
    @apply text-gray-400 text-sm text-center;
}

.sl-order-cell__discount
{
    @apply text-gray-400 text-sm text-center;
}

.sl-order-cell__price
{
    @apply text-gray-400 text-sm text-center;
}


// -------------------- Action Buttons

.sl-order-cell__actions
{
    @apply flex items-center justify-end;
}

.lsn-table-btn__wrapper
{
    @apply flex items-center justify-center border border-primary-100 cursor-pointer;

    height:      34px;
    width:       34px;
    margin-left: 10px;
}

.lsn-table-btn__wrapper:hover
{
    @apply bg-primary-200;
}

.lsn-table-btn
{
    width:  20px;
    height: 20px;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-article-grid">
        <article-grid-item
            v-for="article in articles"
            :key="article.id"
            :article="article"
            :filter-badges="filterBadgesCallback?.(article) || []"
            :badges="badgesCallback?.(article) || []"
            :hide-unit-price="hideUnitPrice"
        />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ArticleGridItem from './ArticleGridItem.vue';

export default
{
    name: 'ArticlesGrid',

    components:
    {
        ArticleGridItem,
    },

    props:
    {
        articles:
        {
            type:     Array,
            required: true,
        },

        filterBadgesCallback:
        {
            type: [Function],
            default()
            {
                // nothing
            },
        },

        badgesCallback:
        {
            type: [Function],
            default()
            {
                // nothing
            },
        },

        hideUnitPrice:
        {
            type:    Boolean,
            default: false,
        },
    },

    created()
    {
        this.fetchImages();
    },

    methods:
    {
        fetchImages()
        {
            const data = {
                eid:   this.$store.state.account.cEntity.id,
                codes: this.articles.map(a => a.code),
            };

            this.$store.dispatch('catalog/fetchImagesByArticleCodes', data)
                .then(images =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // TODO: Show error
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

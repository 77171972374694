<template>
    <lsn-modal :is-open="isOpen">
        <div class="sl-prompt sl-prompt--danger">
            <div class="sl-prompt__contents">
                <div class="sl-prompt__title">
                    {{ $t('order_edition.deletion_confirmation') }}
                </div>

                <div class="sl-prompt__text">
                    {{ $t('common.action_irreversible') }}
                </div>
            </div>

            <div class="sl-prompt__actions">
                <button class="sl-prompt__button sl-prompt__button--white" @click.stop="$emit('cancel')">
                    {{ $t('common.cancel') }}
                </button>

                <button class="sl-prompt__button sl-prompt__button--red" @click.stop="$emit('remove')">
                    {{ $t('common.remove') }}
                </button>
            </div>
        </div>
    </lsn-modal>
</template>

<script>
export default
{
    name: 'DeleteOrderConfirmationModal',

    props:
    {
        isOpen:
        {
            type:     Boolean,
            required: true,
        },
    },

    emits: ['cancel', 'remove'],
};
</script>

<style lang="scss" scoped>
</style>

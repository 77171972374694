/* =============
 * Orders Module
 * =============
 */

import axios from '@/axios';

export function setupOrdersModule()
{
    return {

        namespaced: true,


        // ------------------------------------------------------------ STATE

        state()
        {
            return {
                set:   [],
                total: 0,

                waitingForReview:                [],
                waitingForReviewRefreshInterval: 0,
            };
        },


        // ------------------------------------------------------------ GETTERS

        getters:
        {
            getWaitingForReviewOrderByLensPairId: state => lensPairId =>
            {
                // Build other side lens pair ID.
                let letter = lensPairId.endsWith('A') ? 'B' : 'A';
                let otherSideLensPairId = lensPairId.slice(0, -1) + letter;

                // Look for other side lens.
                for(let i in state.waitingForReview)
                {
                    let order = state.waitingForReview[i];

                    if(order.article_data.LENS_PAIR_ID == otherSideLensPairId)
                    {
                        return order;
                    }
                }

                return null;
            },
        },


        // ------------------------------------------------------------ MUTATIONS

        mutations:
        {
            setOrderSet(state, orders)
            {
                state.set = orders;
            },

            setTotal(state, total)
            {
                state.total = total;
            },

            setWaitingForReview(state, orders)
            {
                state.waitingForReview = orders;
            },

            setWaitingForReviewRefreshInterval(state, intervalId)
            {
                state.waitingForReviewRefreshInterval = intervalId;
            },

            setDeletionRequested(state, { orderId, deletionRequested })
            {
                const order = state.set.find(o => o.id == orderId);
                if(!order)
                {
                    throw new ReferenceError(`Order with ID "${orderId}" not found`);
                }

                order.extra.deletion_requested = deletionRequested;
            },
        },


        // ------------------------------------------------------------ ACTIONS

        actions:
        {
            fetchOrders({ commit, rootState }, { offset, limit })
            {
                const url = '/api/order/entity/:eid/:offset/:limit'
                    .replace(':eid', rootState.account.cEntity.id)
                    .replace(':offset', offset)
                    .replace(':limit', limit);

                return axios.get(url)
                    .then(response =>
                    {
                        commit('setOrderSet', response.data);
                        commit('setTotal', response.meta.total);

                        return response;
                    });
            },

            searchOrders({ commit, rootState }, { offset, limit, query = '', dateFrom, dateTo, prototypeCode, articleCode, statusCodes, patientReference1, extRef })
            {
                return new Promise((resolve, reject) =>
                {
                    let url = '/api/order/search/entity/:eid/:offset/:limit?q=:searchText'
                        .replace(':eid', rootState.account.cEntity.id)
                        .replace(':offset', offset)
                        .replace(':limit', limit)
                        .replace(':searchText', query);

                    // Append advanced filters
                    if(dateFrom)          url += `&date_from=${dateFrom}`;
                    if(dateTo)            url += `&date_to=${dateTo}`;
                    if(prototypeCode)     url += `&prototype_code=${prototypeCode}`;
                    if(articleCode)       url += `&article_code=${articleCode}`;
                    if(statusCodes)       url += `&status_codes=${statusCodes}`;
                    if(patientReference1) url += `&patient_reference1=${patientReference1}`;
                    if(extRef)            url += `&ext_ref=${extRef}`;

                    axios.get(url)
                        .then(({ data: orders, meta }) =>
                        {
                            commit('setOrderSet', orders);
                            commit('setTotal', meta.total);

                            resolve(orders);
                        })
                        .catch(error =>
                        {
                            reject(error);
                        });
                });
            },

            /**
             * Update given orders.
             */
            update({ }, { orders, options })
            {
                return new Promise((resolve, reject) =>
                {
                    let data = {
                        orders:  orders,
                        options: options,
                    };

                    axios.put('/api/order', data)
                        .then(response =>
                        {
                            resolve(response);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },

            fetchWaitingForReview({ commit })
            {
                return new Promise((resolve, reject) =>
                {
                    axios.get('/api/order/waiting-for-review')
                        .then(({ data: orders }) =>
                        {
                            commit('setWaitingForReview', orders);

                            resolve(orders);
                        })
                        .catch(error =>
                        {
                            reject(error);
                        });
                });
            },

            startWaitingForReviewRefreshInterval({ commit, state, dispatch }, prefetch = true)
            {
                // Ensure only one interval is running
                if(state.waitingForReviewRefreshInterval)
                {
                    // return Promise.reject(`Interval already started: ${state.waitingForReviewRefreshInterval}`);
                    return Promise.resolve(state.waitingForReviewRefreshInterval);
                }

                // Retrieve the orders before waiting for the first interval to run
                if(prefetch)
                {
                    dispatch('fetchWaitingForReview');
                }

                // Refresh orders every minute
                const intervalId = setInterval(() =>
                {
                    dispatch('fetchWaitingForReview');
                }, 60_000);
                commit('setWaitingForReviewRefreshInterval', intervalId);

                return Promise.resolve(intervalId);
            },

            stopWaitingForReviewRefreshInterval({ state })
            {
                if(state.waitingForReviewRefreshInterval)
                {
                    clearInterval(state.waitingForReviewRefreshInterval);
                    commit('setWaitingForReviewRefreshInterval', 0);
                }

                return Promise.resolve();
            },

            setDeletionRequested({ commit }, { orderId, deletionRequested = '1' })
            {
                return new Promise((resolve, reject) =>
                {
                    try
                    {
                        commit('setDeletionRequested', { orderId, deletionRequested });

                        resolve();
                    }
                    catch(e)
                    {
                        reject(e);
                    }
                });
            },
        },
    };
};

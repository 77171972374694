import axios from '@/axios';
import Order from '@/models/Order';
import LensOrderUrlManager from '../LensOrderUrlManager';

export default class FetchPreviousOrderByLensOemNumber
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        /** @type {LensOrderUrlManager} */
        const lensOrderUrlManager = this.v.lensOrderUrlManager;

        // Skip unless there's a lens OEM number in the URL
        if(!lensOrderUrlManager.hasOemNumber(this.side))
        {
            console.log(`No lens OEM number in URL for ${this.side} side`);

            return true;
        }

        const urlOrderIdentifier = lensOrderUrlManager.getOemNumber(this.side);
        console.log(`There is a lens OEM number in URL for ${this.side} side: ${urlOrderIdentifier}`);

        const previousOrder = this.v.sides[this.side].previousOrder;
        const previousOrderIdentifier = previousOrder?.getArticleDataAttribute('LENS_OEM_NUMBER') || null;

        // Check that URL lens OEM number is different from current previous order lens OEM number.
        if(!previousOrder || urlOrderIdentifier != previousOrderIdentifier)
        {
            console.log(`Lens OEM number is set or has changed in URL for ${this.side} side`);

            return axios.get(`/api/order/lens-oem/${urlOrderIdentifier}`)
                .then(response =>
                {
                    this.v.sides[this.side].previousOrder = new Order(response.data);

                    return Promise.resolve(response);
                })
                .catch(err =>
                {
                    this.v.sides[this.side].previousOrder = null;
                    this.v.sides[this.side].error = this.v.$t('order.renewal_form.error.not_found');

                    return Promise.reject(err);
                });
        }
        else
        {
            console.log(`Lens OEM number in URL for ${this.side} side is the same as in last dispatch`);
        }

        return true;
    }
}

import { createVueConfigurations } from 'vue-configurations';

const $aconfig = createVueConfigurations();
const $uconfig = createVueConfigurations();


export function getApplicationConfigurations()
{
    return $aconfig;
}

export function getUserConfigurations()
{
    return $uconfig;
}

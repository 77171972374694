<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-related-articles">
        <article-grid-item
            v-for="article in articles"
            :key="article.id"
            :article="article"
            :filter-badges="getFilterBadgesCallback()(article)"
            class="sl-related-articles__item"
        />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { sampleSize }              from 'lodash-es';
import { getFilterBadgesCallback } from '@/shared/ArticlesGridSharedObjects';
import Arrays                      from '@/utils/Arrays';
import ArticleGridItem             from '@/components/articles-grid/ArticleGridItem.vue';
import constants                   from '@/constants/constants';

export default
{
    name: 'RelatedArticles',

    components:
    {
        ArticleGridItem,
    },

    data()
    {
        return {
            articleCodes: [],
        };
    },

    computed:
    {
        articles()
        {
            return this.$store.state.catalog.articles.filter(a => this.articleCodes.includes(a.code));
        },

        /**
         * Tags of lenses that are in the basket (need to be matched with cleaning products or accessories).
         */
        basketLensTags()
        {
            const codes = this.$store.state.basket.lines.map(l => l.article_code);

            return Arrays.unique(
                this.$store.state.catalog.articles
                    .filter(a => a.tags.some(t => t.code === 'LENS') && codes.includes(a.code)) // get all articles in the basket
                    .flatMap(a => a.tags)                                                       // keep only their tags
                    .map(t => t.code)                                                           // keep only the codes
                    .filter(t => t !== 'LENS')                                                  // omit the LENS tag
            );
        },
    },

    created()
    {
        this.suggest();
    },

    methods:
    {
        suggest()
        {
            const selectedCleaningProducts = [];
            const selectedAccessories      = [];

            // Retrieve cleaning products
            const allCleaningProducts = this.$store.state.catalog.articles
                .filter(a => a.tags.some(t => t.code === 'CLEANING_PRODUCT'))
                .filter(a => a.status === constants.articles.status.ACTIVE);

            // Retrieve accessories
            const allAccessories = this.$store.state.catalog.articles
                .filter(a => a.tags.some(t => t.code === 'ACCESSORY'))
                .filter(a => a.status === constants.articles.status.ACTIVE);

            // Suffle arrays
            Arrays.shuffle(allCleaningProducts);
            Arrays.shuffle(allAccessories);

            // Pick 2 distinct random cleaning products that share at least one other tag with something from the basket
            selectedCleaningProducts.push(
                ...sampleSize(
                    allCleaningProducts.filter(a => a.tags.some(t => this.basketLensTags.includes(t.code))),
                    2
                )
            );

            // Pick 2 distinct random accessories that share at least one other tag with something from the basket
            selectedAccessories.push(
                ...sampleSize(
                    allAccessories.filter(a => a.tags.some(t => this.basketLensTags.includes(t.code))),
                    2
                )
            );

            // Ensure there are 2 cleaning products
            if(selectedCleaningProducts.length < 2)
            {
                selectedCleaningProducts.push(...sampleSize(
                    allCleaningProducts.filter(a => !selectedCleaningProducts.includes(a.code)),
                    2 - selectedCleaningProducts.length
                ));
            }

            // Ensure there are 2 accessories
            if(selectedAccessories.length < 2)
            {
                selectedAccessories.push(...sampleSize(
                    allAccessories.filter(a => !selectedAccessories.includes(a.code)),
                    2 - selectedAccessories.length
                ));
            }

            this.articleCodes = [
                ...selectedCleaningProducts.map(a => a.code),
                ...selectedAccessories.map(a => a.code),
            ];

            // Load article photos
            this.fetchImages();
        },

        fetchImages()
        {
            const data = {
                eid:   this.$store.state.account.cEntity.id,
                codes: this.articleCodes,
            };

            this.$store.dispatch('catalog/fetchImagesByArticleCodes', data)
                .then(images =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // TODO: Show error
                });
        },

        getFilterBadgesCallback,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-related-articles
{
    @apply grid grid-cols-4 text-gray-400;
}

.sl-related-articles__item
{
    @apply border-0 border-l;
}

.sl-related-articles__item:first-child
{
    @apply border-l-0;
}
</style>

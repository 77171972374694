<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Order Date -->
    <lsn-table-cell class="sl-order-cell__date">
        {{ odate }}
    </lsn-table-cell>

    <!-- Order Reference / Lens Serial Number -->
    <lsn-table-cell class="sl-order-cell__reference">
        {{ order.reference }}

        <div v-if="sn" class="sl-order-cell__sn">
            SN: {{ sn }}
        </div>
    </lsn-table-cell>

    <!-- Article Details -->
    <lsn-table-cell class="sl-order-cell__article">
        <div class="flex flex-wrap grow">
            <div class="sl-order-cell__article-wrapper">
                <!-- Patient -->
                <div class="sl-order-subcell__patient">
                    {{ patient }}
                </div>

                <!-- Order Reference (if any) -->
                <div v-if="order.extra?.ext_ref" class="sl-order-subcell__ext-ref">
                    {{ order.extra.ext_ref }}
                </div>

                <!-- Prototype Label -->
                <div class="sl-order-subcell__article-label">
                    {{ getPrototypeLabel(order) }}
                </div>
            </div>

            <!-- Lens Side -->
            <div class="sl-order-subcell__side">
                <sl-lens-side :side="order.article_data.LENS_SIDE" />
            </div>
        </div>
    </lsn-table-cell>

    <!-- Status -->
    <lsn-table-cell class="sl-order-cell__status">
        <tippy>
            <div class="lsn-table__tag">
                {{ $t(`order_table.status.${status.code}.label`) }}
            </div>

            <template #content>
                <div class="text-gray-500 text-center space-y-1">
                    <div v-if="$store.getters['account/isDeveloper']" class="text-gray-400">
                        {{ status.code }}
                    </div>

                    <div>
                        {{ statusAt }}
                    </div>
                </div>
            </template>
        </tippy>
    </lsn-table-cell>

    <!-- Quantity -->
    <lsn-table-cell class="sl-order-cell__quantity">
        {{ order.quantity }}
    </lsn-table-cell>

    <!-- Price -->
    <lsn-table-cell class="sl-order-cell__price">
        <span>{{ order.currency_code }}</span>
        <span>{{ formattedActualPrice }}</span>
    </lsn-table-cell>

    <!-- Action Buttons -->
    <lsn-table-cell class="sl-order-cell__actions">
        <!-- Discount Information (if any) -->
        <tippy class="mr-auto">
            <div v-if="order.special_price !== null && order.special_price < order.price">
                <mdi-icon icon="mdiSale" class="w-4 h-4 text-primary-500" />
            </div>

            <template #content>
                <div class="text-gray-500">
                    <span class="text-primary-500">{{ $t('order_table.base_price_colon') }}</span>
                    {{ order.currency_code }}&nbsp;{{ formattedBasePrice }}
                    <br>

                    <span class="text-primary-500">{{ $t('order_table.discount_colon') }}</span>
                    {{ formattedDiscount }}
                </div>
            </template>
        </tippy>

        <!-- Reorder Button -->
        <template v-if="context === 'on-going'">
            <div
                v-if="!canReorder"
                v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                class="lsn-table-btn__wrapper lsn-table-btn__wrapper--disabled"
                :content="$t('orders.reorder_btn.not_allowed.help')"
                @click.stop
            >
                <mdi-icon class="lsn-table-btn" icon="mdiRepeat" />
            </div>

            <div
                v-else-if="!isReOrdered"
                v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                class="lsn-table-btn__wrapper text-primary-400"
                :content="$t('orders.reorder_btn.label')"
                @click.stop="toggleReOrder()"
            >
                <mdi-icon class="lsn-table-btn" icon="mdiRepeat" />
            </div>

            <div
                v-else
                class="lsn-table-btn__wrapper text-yellow-500"
                @click.stop="toggleReOrder()"
            >
                <mdi-icon class="lsn-table-btn" icon="mdiCheck" />
            </div>
        </template>
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import moment from 'moment';
import {
    actualPrice,
    basePrice,
    discount,
    formattedActualPrice,
    formattedBasePrice,
    formattedDiscount,
    getPrototypeLabel,
    price,
    quantity,
} from '@/shared/OrderTableSharedObjects';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import constants    from '@/constants/constants';

export default
{
    name: 'LensOrderLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        order:
        {
            type:     Object,
            required: true,
        },

        context:
        {
            type:     String,
            required: true,
        },

        isReOrdered:
        {
            type:    Boolean,
            default: false,
        },
    },

    emits: [
        'reordered',
        'unreordered',
    ],

    data()
    {
        return {
            deleteConfirmationOpen: false,
        };
    },

    computed:
    {
        odate()
        {
            return moment(this.order.odate).format('DD.MM.YYYY');
        },

        sn()
        {
            return (this.order.article_data['LENS_SERIAL_NUMBER']) ? this.order.article_data['LENS_SERIAL_NUMBER'] : null;
        },

        patient()
        {
            if(this.order.article_data.PATIENT_REFERENCE1)
            {
                return this.order.article_data.PATIENT_REFERENCE1;
            }
            else if(this.order.article_data.PATIENT_ID)
            {
                let patient = this.$store.getters['patients/getById'](this.order.article_data.PATIENT_ID);

                return patient.reference1 ? patient.reference1 : '-';
            }
            else
            {
                return '-';
            }
        },

        status()
        {
            return [].concat(this.order.status).pop(); // last status
        },

        statusAt()
        {
            return moment(this.status.at).format('DD.MM.YYYY | HH:MM');
        },

        canReorder()
        {
            const prototype = this.$store.state.prototypes.prototypes.find(p => p.code === this.order.article_data.PROTOTYPE_CODE);
            if(!prototype)
            {
                return false;
            }

            return prototype?.status !== constants.articles.status.INACTIVE;
        },

        actualPrice,
        basePrice,
        discount,
        formattedActualPrice,
        formattedBasePrice,
        formattedDiscount,
        price,
        quantity,
    },

    methods:
    {
        toggleReOrder()
        {
            if(this.isReOrdered)
            {
                this.$emit('unreordered', this.order);
            }
            else
            {
                this.$emit('reordered', this.order);
            }
        },

        getPrototypeLabel,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-cell__date
{
    @apply text-gray-400 text-xs;
}

.sl-order-cell__reference
{
    @apply text-gray-400 text-sm text-center;
}

.sl-order-cell__sn
{
    @apply mt-1 text-gray-500 text-xs;
}

.sl-order-subcell__patient
{
    @apply text-sm text-gray-500;
}

.sl-order-subcell__ext-ref
{
    @apply ml-2 text-sm text-gray-500;
}

.sl-order-cell__article-wrapper
{
    @apply flex flex-wrap grow;

    width: calc(100% - 28px); // SlLensSide is 28px wide
}

.sl-order-subcell__article-label
{
    @apply w-full text-xs text-gray-400 whitespace-nowrap text-ellipsis;
}

.sl-order-subcell__side
{
    @apply flex items-center justify-center;
}

.lsn-table__tag
{
    @apply text-xs;
}

.sl-order-cell__base-price
{
    @apply text-gray-400 text-sm text-center;
}

.sl-order-cell__discount
{
    @apply text-gray-400 text-sm text-center;
}

.sl-order-cell__price
{
    @apply text-gray-400 text-sm text-center;
}

/*--- action buttons --*/

.sl-order-cell__actions
{
    @apply flex items-center justify-end;
}

.lsn-table-btn__wrapper
{
    height: 34px;
    width: 34px;
    margin-left: 10px;
    @apply flex items-center justify-center border border-primary-100 cursor-pointer;
}

.lsn-table-btn__wrapper:hover
{
    @apply bg-primary-200;
}

.lsn-table-btn__wrapper--disabled
{
    @apply cursor-not-allowed border-gray-300 text-gray-300
        hover:bg-transparent;
}

.lsn-table-btn
{
    width: 20px;
    height: 20px;
}
</style>

import axios from 'axios';


// ------------------------------------------------------------ INTERCEPTOR

axios.interceptors.response.use(
    response =>
    {
        if(response.data !== undefined && response.data.data !== undefined) // don't touch!
        {
            response.meta = response.data.meta;
            response.data = response.data.data;
        }

        return response;
    },
    error =>
    {
        if(error.response?.data)
        {
            error.response.meta = error.response.data.meta;
            error.response.errors = error.response.data.errors;
            delete error.response.data;
        }

        return Promise.reject(error);
    }
);


// ------------------------------------------------------------

export default axios;

import { ref, toValue } from 'vue';
import axios from '@/axios';

export default function useFileDownloader(fileUrl, outputFilename)
{
    // ------------------------------------------------------------ DATA

    const isFileDownloading = ref(false);


    // ------------------------------------------------------------ METHODS

    function downloadFile()
    {
        // Prevent downloading multiple concurrent files
        if(isFileDownloading.value)
        {
            return;
        }

        isFileDownloading.value = true;

        axios.get(toValue(fileUrl), { timeout: 5000 })
            .then(response =>
            {
                // Use an in-memory <a> tag to download the base64-encoded PDF file
                const a    = document.createElement('a');
                a.href     = 'data:application/octet-stream;base64,' + response.data;
                a.target   = '_blank';
                a.download = toValue(outputFilename);
                a.click();
            })
            .catch(error =>
            {
                // Nothing
            })
            .then(() =>
            {
                isFileDownloading.value = false;
            });
    }


    // ------------------------------------------------------------ EXPORT

    return {
        isFileDownloading,
        downloadFile,
    };
};

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line sl-order-form__line-grouped" :class="{ 'sl-order-form__line--error': error }">
        <!-- Label -->
        <slot name="label" />

        <!-- Helper -->
        <div v-if="helper" class="sl-order-form__helper-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__helper" v-html="helper"></div>
        </div>

        <!-- Field Wrapper -->
        <div class="sl-order-form__field-wrapper sl-order-form__field-wrapper--fixed-width">
            <!-- Field -->
            <input
                v-model.lazy="tempValue"
                class="sl-order-form__input"
                type="text"
                :name="name"
                :tabindex="tabIndex"
                autocomplete="off"
                @focus="onFocus"
                @blur="onBlur"
            >
        </div>

        <!-- Supplement -->
        <div v-if="supplement" class="sl-order-form__supplement-wrapper">
            <div class="sl-order-form__supplement">
                {{ supplement }}
            </div>
        </div>

        <!-- Note -->
        <div v-if="parameter.note" class="sl-order-form__note-wrapper mt-2">
            <div class="sl-order-form__note">
                {{ parameter.note }}
            </div>
        </div>

        <!-- Error -->
        <div v-if="error" class="sl-order-form__error-wrapper mt-2">
            <div class="sl-order-form__error">
                {{ error }}
            </div>
        </div>

        <!-- Splitter -->
        <slot name="splitter" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractGroupedField from './AbstractGroupedField.vue';

export default
{
    name: 'GroupedInputTextField',

    extends: AbstractGroupedField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                return this.modelValue;
            },
            set(value)
            {
                this.$emit('update:modelValue', value);
            },
        },

        helper()
        {
            return null;
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__input:focus
{
    @apply outline-none border-primary-400;
}
</style>

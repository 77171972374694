<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div
        class="sl-order-form__line sl-order-form__line-grouped"
        :class="{
            'sl-order-form__line--error': error,
            'sl-order-form__line--disabled': !parameter.is_enabled
        }"
    >
        <!-- Label -->
        <div class="sl-order-form__label-wrapper" :class="{ 'cursor-pointer': parameter.is_enabled }" @click="toggleValue()">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <label class="sl-order-form__label text-center block" v-html="parameter.form_label"></label>
        </div>

        <!-- Helper -->
        <div v-if="helper" class="sl-order-form__helper-wrapper" :class="{ 'cursor-pointer': parameter.is_enabled }" @click="toggleValue()">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__helper" v-html="helper"></div>
        </div>

        <!-- Field Wrapper -->
        <div
            class="sl-order-form__field-wrapper"
            :tabindex="(parameter.is_enabled ? tabIndex : false)"
            :class="{ 'cursor-pointer': parameter.is_enabled }"
            @click="toggleValue()"
            @keypress.enter="toggleValue()"
            @keypress.space.prevent="toggleValue()"
        >
            <!-- Field -->
            <mdi-icon
                :icon="tempValue ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                class="sl-checkbox__check-icon"
                :class="{ 'sl-checkbox--checked': tempValue }"
            />
        </div>

        <!-- Supplement -->
        <div v-if="supplement" class="sl-order-form__supplement-wrapper">
            <div class="sl-order-form__supplement">
                {{ supplement }}
            </div>
        </div>

        <!-- Note -->
        <div v-if="parameter.note" class="sl-order-form__note-wrapper pb-2">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__note" v-html="parameter.note"></div>
        </div>

        <!-- Error -->
        <div v-if="error" class="sl-order-form__error-wrapper pb-2">
            <div class="sl-order-form__error">
                {{ error }}
            </div>
        </div>

        <!-- Splitter -->
        <slot name="splitter" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractGroupedField from './AbstractGroupedField.vue';

export default
{
    name: 'GroupedCheckboxField',

    extends: AbstractGroupedField,

    emits: ['update:modelValue'],

    computed:
    {
        tempValue:
        {
            get()
            {
                return this.modelValue;
            },

            set(value)
            {
                this.$emit('update:modelValue', value);
            },
        },

        helper()
        {
            return null;
        },
    },

    methods:
    {
        toggleValue()
        {
            if(this.parameter.is_enabled)
            {
                this.tempValue = !this.tempValue;
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line
{
    @apply pb-0;
}

.sl-order-form__field-wrapper:focus
{
    @apply outline-none;
}

.sl-checkbox__check-icon
{
    @apply text-gray-500;

    height: 22px;
    width: 22px;
}

.sl-checkbox--checked
{
    @apply text-primary-400;
}

.sl-order-form__line--disabled
{
    @apply cursor-not-allowed;

    background-image: linear-gradient(135deg, #ffffff 33.33%, #edf2f7 33.33%, #edf2f7 50%, #ffffff 50%, #ffffff 83.33%, #edf2f7 83.33%, #edf2f7 100%);
    background-size: 42.43px 42.43px;

    .sl-checkbox__check-icon
    {
        @apply text-gray-400;
    }
}

.sl-order-form__line:not(.sl-order-form__line--disabled) .sl-order-form__field-wrapper:focus-visible .sl-checkbox__check-icon
{
    @apply ring-1 ring-primary-400;
}
</style>

export default class SetAdaptationValuesByBasketLine
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        let lensOrderUrlManager = this.v.lensOrderUrlManager;

        // If there is a basket line id in URL.
        if(lensOrderUrlManager.hasBasketLineId(this.side))
        {
            let basketLineId = lensOrderUrlManager.getBasketLineId(this.side);
            let basketLine = this.v.$store.getters['basket/getById'](basketLineId);
            let adaptation = this.v.$store.state.lensOrder.adaptations[this.side];

            adaptation.basket_line_id = basketLine.id;
        }

        return true;
    }
}

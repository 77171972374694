<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-table__cell">
        {{ formatValue(data, fields) }}
        <slot />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'LsnTableCell',

    props:
    {
        data:
        {
            type:    Object,
            default: null,
        },
        fields:
        {
            type:    [String, Array],
            default: '',
        },
        format:
        {
            type:    Function,
            default: function(...values)
            {
                return values.join(' ');
            },
        },
    },

    methods:
    {
        formatValue(data, fields)
        {
            // console.log('Formatting', data, fields);

            if(typeof this.format === 'function')
            {
                let values = [];

                if(typeof fields === 'string' && fields.length > 0)
                {
                    values.push(data[fields]);
                }
                else if(Array.isArray(fields) && fields.length > 0)
                {
                    fields.forEach(column =>
                    {
                        // console.log('Pushing value', column, data[column]);
                        values.push(data[column]);
                    });
                }
                else
                {
                    return '';
                }

                // console.log('Values:', values);

                return this.format(...values);
            }
            else
            {
                return '';
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.lsn-table__cell
{
    @apply px-2;
}

.lsn-table > .lsn-table__header,
.lsn-table > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    > .lsn-table__checkbox,
    > .lsn-table__chevron
    {
        @apply px-0;
    }
}
</style>

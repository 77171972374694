<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="bg-primary-10 px-6 pb-6 pt-4">
        <div class="text-gray-500 pb-4">
            {{ $t('order.new_order') }}
        </div>

        <!-- Tag Filters -->
        <div v-if="availableFilters1.length" class="sl-prototypes-list__tag-filters">
            <div
                v-for="filter in availableFilters1"
                :key="filter"
                class="sl-prototypes-list__tag-filter sl-prototypes-list__tag-filter--group-2"
                :class="{ 'sl-prototypes-list__tag-filter--active': filter2 === filter }"
                @click="setFilter2(filter)"
            >
                {{ getTagLabel(filter) }}
            </div>

            <span v-if="availableFilters2.length" class="ml-1 mr-2 text-gray-400 select-none">|</span>

            <div
                v-for="filter in availableFilters2"
                :key="filter"
                class="sl-prototypes-list__tag-filter sl-prototypes-list__tag-filter--group-3"
                :class="{ 'sl-prototypes-list__tag-filter--active': filter3 === filter }"
                @click="setFilter3(filter)"
            >
                {{ getTagLabel(filter) }}
            </div>
        </div>

        <!-- Text Search -->
        <div class="sl-prototypes-list__search-box">
            <mdi-icon icon="mdiMagnify" />

            <input v-model="searchText" type="search" class="sl-prototypes-list__search-field">
        </div>

        <!-- Prototypes List -->
        <perfect-scrollbar ref="ps" class="sl-prototypes-list ps--visible">
            <!-- Prototype -->
            <template v-if="filteredPrototypes.length">
                <div
                    v-for="cPrototype in filteredPrototypes"
                    :key="cPrototype.code"
                    class="sl-prototypes-list__item"
                    :class="{ 'sl-prototypes-list__item--active': cPrototype.model_code == prototype?.model_code }"
                    @click="setPrototype(cPrototype)"
                    @mouseenter="hoveredPrototype = cPrototype"
                    @mouseleave="hoveredPrototype = null"
                >
                    <!-- Label -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div class="sl-prototype__name" v-html="cPrototype.model_label"></div>

                    <!-- Tags (if any) -->
                    <div class="sl-prototype__tags">
                        <span v-for="tag in cPrototype.tags" :key="tag.code" class="sl-prototype__tag">
                            {{ tag.label }}
                        </span>

                        <!-- Highlight prototype with a star -->
                        <template v-if="highlightPrototypes.includes(cPrototype.model_code)">
                            <div class="grow"></div>

                            <mdi-icon
                                v-tippy
                                :content="$t('order.lenses.sections.prototypes_list.new_design')"
                                :icon="cPrototype.model_code == hoveredPrototype?.model_code ? 'mdiCreation' : 'mdiCreationOutline'"
                                class="sl-prototype__highlight"
                            />
                        </template>
                    </div>
                </div>
            </template>

            <!-- No Prototype -->
            <div v-else class="p-4 text-center text-gray-400">
                {{ $t('order.lenses.sections.prototypes_list.no_prototypes') }}
            </div>
        </perfect-scrollbar>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
// todo: Scroll to selected model on unfold

import Fuse      from 'fuse.js';
import constants from '@/constants/constants';

export default
{
    name: 'PrototypesList',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
        prototypeCode:
        {
            type:    String,
            default: null,
        },
    },

    emits: ['prototype-code:set'],

    data()
    {
        return {
            typeFilter:       '',
            filter2:          '',
            filter3:          '',
            searchText:       '',
            hoveredPrototype: null,
        };
    },

    computed:
    {
        prototypes()
        {
            return this.$store.getters['prototypes/allOneByModel']
                .filter(p => p.status === constants.articles.status.ACTIVE) // filter out "INACTIVE" and "REORDER_ONLY"
                .sort((a, b) => a.sort_order - b.sort_order);
        },

        prototype()
        {
            if(this.prototypeCode)
            {
                return this.$store.getters['prototypes/getByCode'](this.prototypeCode);
            }

            return null;
        },

        fuse()
        {
            return new Fuse(this.prototypes, {
                shouldSort:     true,
                threshold:      0.1,
                ignoreLocation: true,
                keys:           ['label'],
            });
        },

        textFilteredPrototypes()
        {
            // Minimum 1 character to perform a search
            if(this.searchText.length < 1)
            {
                return this.prototypes;
            }

            return this.fuse.search(this.searchText).map(r => r.item);
        },

        filteredPrototypes()
        {
            return this.textFilteredPrototypes.filter(p =>
            {
                let out = true;
                const pTags = p.tags.map(t => t.code);

                if(this.typeFilter)
                {
                    out = (out && pTags.includes(this.typeFilter));
                }

                if(this.filter2)
                {
                    out = (out && pTags.includes(this.filter2));
                }

                if(this.filter3)
                {
                    out = (out && pTags.includes(this.filter3));
                }

                return out;
            });
        },

        availableFilters1()
        {
            switch(this.typeFilter)
            {
                case '':
                case 'SOFT':
                case 'RGP':
                case 'SCLERAL':
                case 'HYBRID':
                case 'MYOPIA_CONTROL':
                case 'LUNELLE':
                case 'COLORED':
                case 'CRISTALENS':
                case 'ORTHOK':
                    return [
                        'SPHERICAL',
                        'TORIC',
                    ];

                case 'IRREGULAR_CORNEA':
                    return [
                        'UNIFOCAL',
                        'MULTIFOCAL',
                    ];
            }

            return [];
        },

        availableFilters2()
        {
            switch(this.typeFilter)
            {
                case '':
                case 'SOFT':
                case 'RGP':
                case 'SCLERAL':
                case 'HYBRID':
                case 'LUNELLE':
                case 'COLORED':
                case 'CRISTALENS':
                    return [
                        'UNIFOCAL',
                        'MULTIFOCAL',
                    ];
            }

            return [];
        },

        highlightPrototypes()
        {
            return [
                'FLEXCONE_EVO_V1',
                'FLEXCONE_EVO_P_V1',
                'ORBIFLEX_EVO',
                'BORIFLEX_EVO',
                'RELAXFLEX_EVO',
            ];
        },
    },

    watch:
    {
        '$route.query.type'(type)
        {
            // Reset filters
            this.setTypeFilter(type);
            this.setFilter2('');
            this.setFilter3('');
        },
    },

    created()
    {
        this.setTypeFilter();
    },

    methods:
    {
        setTypeFilter(filter)
        {
            if(!filter)
            {
                filter = this.$route.query.type || '';
            }

            this.typeFilter = filter;
        },

        setFilter2(filter)
        {
            if(this.filter2 === filter)
            {
                this.filter2 = '';
            }
            else
            {
                this.filter2 = filter;
            }
        },

        setFilter3(filter)
        {
            if(this.filter3 === filter)
            {
                this.filter3 = '';
            }
            else
            {
                this.filter3 = filter;
            }
        },

        getTagLabel(tagCode, defaultValue = '')
        {
            return this.$store.getters['prototypes/allTags'].find(t => t.code === tagCode)?.label || defaultValue;
        },

        setPrototype(prototype)
        {
            const defaultPrototypeVariantCode = this.$store.getters['prototypes/getDefaultByModel'](prototype.model_code).code;

            // If current line is already selected => deselect it.
            if(defaultPrototypeVariantCode == this.prototypeCode)
            {
                this.$emit('prototype-code:set', this.side, null);
            }
            else
            {
                this.$emit('prototype-code:set', this.side, defaultPrototypeVariantCode);
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-prototypes-list__tag-filters
{
    @apply flex justify-center items-center mb-3 text-sm;
}

.sl-prototypes-list__tag-filter
{
    @apply cursor-pointer mr-1 border border-gray-300 rounded px-2 py-1 bg-white text-gray-400 transition;

    &:hover,
    &.sl-prototypes-list__tag-filter--active
    {
        @apply border-primary-500 bg-primary-500 text-white;
    }
}

.sl-prototypes-list__search-box
{
    @apply relative;
}

.sl-prototypes-list__search-box > .mdi-icon
{
    @apply absolute top-1.5 left-2.5 w-[22px] h-[22px] text-gray-400 pointer-events-none;
}

.sl-prototypes-list__search-field
{
    height: 32px;
    padding-left: 34px;
    @apply w-full mb-3 rounded-full border border-gray-300 text-primary-500 outline-none focus:border-primary-200;
}

.sl-prototypes-list
{
    height: 470px;
    @apply rounded-b-sm bg-gray-50;
}

.sl-prototypes-list__item
{
    @apply px-4 py-2 border-b border-gray-300 cursor-pointer bg-white;
}

.sl-prototypes-list__item:hover,
.sl-prototypes-list__item--active
{
    background-color: theme('colors.primary.100');
}

.sl-prototype__name
{
    @apply mb-1 text-primary-500 text-sm tracking-wide;
}

.sl-prototypes-list__item--active .sl-prototype__name
{
    @apply text-primary-600;
}

.sl-prototype__tags
{
    @apply flex justify-start items-center text-gray-400;
}

.sl-prototype__tag
{
    @apply border rounded-sm text-xs mr-1 px-2 py-0.5 bg-white;
}

.sl-prototype__highlight
{
    @apply ml-2 w-5 h-5 text-yellow-400;

    animation-name: highlightColor;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

.sl-prototypes-list__item:hover,
.sl-prototypes-list__item--active
{
    .sl-prototype__tag
    {
        @apply border-primary-200;
    }

    .sl-prototype__highlight
    {
        @apply text-yellow-500;
    }
}

// Custom Highlight Animation
@keyframes highlightColor {
  0% {
      @apply opacity-0;
  }

  100% {
      @apply opacity-100;
  }
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table :data="returns" class="sl-returns">
        <template #subtable-caption>
            {{ $t("invoicing.returns.table.title") }}
        </template>

        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-return__reference">
                {{ $t("invoicing.table.headers.reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return__serial_number">
                {{ $t("invoicing.table.headers.serial_number") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return__label">
                {{ $t("invoicing.table.headers.label") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return__price">
                {{ $t("invoicing.table.headers.amount") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return__currency">
                {{ $t("invoicing.table.headers.currency") }}
            </lsn-table-cell>
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <return-line-summary :o-return="item" />
        </template>
    </lsn-table>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import ReturnLineSummary from '@/components/return/ReturnLineSummary.vue';

export default
{
    name: 'ReturnTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        ReturnLineSummary,
    },

    props:
    {
        returns:
        {
            type:     Array,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="postcss">
.sl-returns
{
    --column-width: 100px;
}

.lsn-table:not(.lsn-table--openable).sl-returns > .lsn-table__header,
.lsn-table:not(.lsn-table--openable).sl-returns > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns:
        repeat(2, var(--column-width))
        calc(100% - (4 * var(--column-width)))
        repeat(2, var(--column-width));
}

.sl-return__price,
.sl-return__actions
{
    @apply text-right;
}

.sl-return__currency
{
    @apply text-center;
}
</style>
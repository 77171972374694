export default class ResetErrors
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        this.v.sides.right.error = null;
        this.v.sides.left.error = null;

        return true;
    }
}

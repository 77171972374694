<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <span class="sl-lens-side" :class="{ 'sl-lens-side--left-padded': isLeftPadded }" :title="title">
        {{ sideLetter }}
    </span>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'SlLensSide',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
    },

    computed:
    {
        sideLetter()
        {
            if(!this.side)
            {
                return '';
            }

            let side = this.side.toString().toLowerCase();

            if(side == 'left' || side == 'l')
            {
                return this.$t('common.left_letter');
            }
            else if(side == 'right' || side == 'r')
            {
                return this.$t('common.right_letter');
            }
            else
            {
                return this.$t('common.unknown_letter');
            }
        },

        isLeftPadded()
        {
            // Fix display (letter is not centered)
            return ['D'].indexOf(this.sideLetter) != -1;
        },

        title()
        {
            if(!this.side)
            {
                return '';
            }

            let side = this.side.toString().toLowerCase();

            if(side == 'left' || side == 'l')
            {
                return this.$t('common.left');
            }
            else if(side == 'right' || side == 'r')
            {
                return this.$t('common.right');
            }
            else
            {
                return this.$t('common.unknown');
            }
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.sl-lens-side
{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    @apply flex items-center justify-center border border-gray-400 text-gray-400 text-sm;
}

.sl-lens-side--left-padded
{
    @apply pl-px;
}

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-eyes-header">
        <!-- Right Header -->
        <div class="w-1/2 text-center text-gray-600 flex items-center justify-center border-r border-primary-200">
            <div>
                {{ $t('common.right-eye') }}
            </div>
            <!-- Right Eye -->
            <mdi-icon v-if="!isRightActive && atLeastOnePrototype" class="sl-eye ml-2 mr-2" icon="mdiEyeOff" />
            <mdi-icon v-else class="sl-eye ml-2 mr-2" icon="mdiEye" />

            <!-- Left Eye -->
            <mdi-icon v-if="!isLeftActive && atLeastOnePrototype" class="sl-eye sl-eye--other-side" icon="mdiEyeOff" />
            <mdi-icon v-else class="sl-eye sl-eye--other-side" icon="mdiEye" />
        </div>

        <!-- Left Header -->
        <div class="w-1/2 text-center text-gray-600 flex items-center justify-center">
            <!-- Right Eye -->
            <mdi-icon v-if="!isRightActive && atLeastOnePrototype" class="sl-eye mr-2 sl-eye--other-side" icon="mdiEyeOff" />
            <mdi-icon v-else class="sl-eye mr-2 sl-eye--other-side" icon="mdiEye" />

            <!-- Left Eye -->
            <mdi-icon v-if="!isLeftActive && atLeastOnePrototype" class="sl-eye mr-2" icon="mdiEyeOff" />
            <mdi-icon v-else class="sl-eye mr-2" icon="mdiEye" />

            <div>
                {{ $t('common.left-eye') }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'EyesHeader',

    computed:
    {
        isRightActive()
        {
            return !!this.$store.state.lensOrder.adaptations.right;
        },

        isLeftActive()
        {
            return !!this.$store.state.lensOrder.adaptations.left;
        },

        atLeastOnePrototype()
        {
            return this.isRightActive || this.isLeftActive;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-eyes-header
{
    height: 40px;
    top: 0px;
    @apply sticky w-full flex flex-row z-20 items-center border-b border-primary-200 bg-white;
}

.sl-eye
{
    @apply inline-block w-6 h-6 text-primary-500;
}

.sl-eye.sl-eye--other-side
{
    @apply text-gray-300;
}
</style>

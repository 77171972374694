<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="w-full mt-4 px-4 py-3 text-center text-red-600 bg-red-200 border border-red-300">

        {{ $t('common.errors.embarassed') }}
        <br>
        <br>
        {{ msg }}
        <br>
        <br>
        {{ $t('common.please_contact_swisslens') }} :
        <br>

        <div class="sl-phone__line mt-4">
            <div class="sl-phone__label">
                Schweiz
            </div>
            <div class="sl-phone__value">
                +41 (0)21 620 06 68
            </div>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Suisse
            </div>
            <div class="sl-phone__value">
                +41 (0)21 620 06 67
            </div>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Deutschland
            </div>
            <div class="sl-phone__value">
                +49 6221 4355232
            </div>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                France
            </div>
            <div class="sl-phone__value">
                +33 4 88 60 36 39
            </div>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Italia
            </div>
            <div class="sl-phone__value">
                +39 347 6000535
            </div>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Other countries
            </div>
            <div class="sl-phone__value">
                +41 (0)21 620 06 66
            </div>
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'ErrorView',

    props:
    {
        msg:
        {
            type:     String,
            required: true,
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

/*--- ERROR ---*/

.sl-phone__line
{
    @apply flex mb-2;
}

.sl-phone__label
{
    @apply mr-1 w-1/2 text-right;
}

.sl-phone__value
{
    @apply ml-1 w-1/2 text-left;
}

</style>

export default class ValidateLenses
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const requests        = [];
        const options         = {};
        const eid             = this.v.$store.state.account.cEntity.id;
        const rightAdaptation = this.v.$store.state.lensOrder.adaptations.right;
        const leftAdaptation  = this.v.$store.state.lensOrder.adaptations.left;

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.validating'));

        // Validate right side (if exists)
        if(rightAdaptation)
        {
            console.log('Validate right side');

            requests.push(rightAdaptation.getPrototype().validate(eid, options));
        }

        // Validate left side (if exists)
        if(leftAdaptation)
        {
            console.log('Validate left side');

            requests.push(leftAdaptation.getPrototype().validate(eid, options));
        }

        return Promise.all(requests)
            .then(response =>
            {
                console.log('Validation OK !');

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Lens validation error:', error);

                return Promise.reject(error);
            });
    }
}

/**
 * Custom Vue directive to hide an element based on the current user's permissions.
 */
export default(el, binding) =>
{
    // Retrieve directive parameters
    let permission = "";
    let params = {};
    if(typeof binding.value === "object")
    {
        permission = binding.value.permission;
        delete binding.value.permission;
        params = { ...binding.value };
    }
    else if(typeof binding.value === "string")
    {
        permission = binding.value;
    }
    else
    {
        throw new Error("Invalid format for v-can directive.");
        // Valid formats are:
        //   - <div v-can="'permission'">
        //   - <div v-can="{ permission: 'permission', params: {...} }">
    }

    // Check if the user has the permission to view the element
    const permissions = binding.instance.$store.state.account.cUser.permissions;

    if(permission && permissions.includes(permission) && !binding.modifiers.not)
    {
        // Element is shown. Do nothing :-)
    }
    else
    {
        // Remove element from DOM
        el.remove();
    }
};

<!-- eslint-disable vue/no-v-html -->
<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Field Error wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <input
                    v-model="patientReference1"
                    class="sl-order-form__input"
                    type="text"
                    :name="name"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    :disabled="cPrototype.isReOrder"
                    @focus="onFocus"
                    @blur="onBlur"
                />

                <!-- Patients Auto-Complete -->
                <div
                    v-if="isFocused && patientReference1.length >= 2 && filteredPatients.length"
                    class="sl-order-form__autocomplete"
                >
                    <div
                        v-for="patient in filteredPatients"
                        :key="patient"
                        class="sl-order-form__option"
                        @mousedown="selectPatient(patient)"
                    >
                        {{ patient }}
                    </div>
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>

        <!-- Reorder Confirmation Modal -->
        <teleport to="body">
            <lsn-modal :is-open="reorderConfirmationModalOpen">
                <div class="sl-prompt sl-prompt--info text-center">
                    <div class="sl-prompt__contents">
                        <div class="sl-prompt__title">
                            {{ t('order.reorder_confirmation.title') }}
                        </div>

                        <div class="sl-prompt__text">
                            <div
                                class="mb-2"
                                v-html="t('order.reorder_confirmation.texts.line1').replace(':patient', `<span class='text-primary-500'>${patientReference1}</span>`)"
                            >
                            </div>

                            <div>
                                {{ t('order.reorder_confirmation.texts.line2') }}
                            </div>
                        </div>
                    </div>

                    <div class="sl-prompt__actions">
                        <button class="sl-prompt__button sl-prompt__button--white" @click="reorderConfirmationModal_OnCancel">
                            {{ t('common.no') }}
                        </button>

                        <button class="sl-prompt__button sl-prompt__button--primary" @click="reorderConfirmationModal_OnValidate">
                            {{ t('orders.reorder_btn.label') }}
                        </button>
                    </div>
                </div>
            </lsn-modal>
        </teleport>

        <!-- Protoype Selection Modal -->
        <teleport to="body">
            <lsn-modal :is-open="prototypeSelectionModalOpen">
                <div class="sl-prompt sl-prompt--info sl-prototype-select">
                    <div class="sl-prompt__contents">
                        <div class="sl-prompt__title">
                            {{ t('order.prototype_selection.title') }}
                        </div>

                        <div class="sl-prompt__text mt-8">
                            <div
                                class="mb-2"
                                v-html="t('order.prototype_selection.texts.line1').replace(':patient', `<span class='text-primary-500'>${patientReference1}</span>`)"
                            >
                            </div>

                            <div class="mx-auto mb-8 text-center">
                                <div class="sl-prototype-select__label">
                                    {{ previousPrototype.label }}
                                </div>
                            </div>

                            <div class="mb-2">
                                {{ t('order.prototype_selection.texts.line2') }}
                            </div>

                            <div class="mx-auto mb-8 text-center">
                                <div class="sl-prototype-select__label">
                                    {{ cPrototype.label }}
                                </div>
                            </div>

                            <div>
                                {{ t('order.prototype_selection.texts.line3') }}
                            </div>
                        </div>

                        <div class="mt-4">
                            <div class="mx-auto w-2/3">
                                <div
                                    v-for="proto in [previousPrototype, cPrototype]"
                                    :key="proto.code"
                                    class="sl-prototype-select__option"
                                    :class="{ 'sl-prototype-select__option--selected': selectedPrototype?.code === proto.code }"
                                    @click="selectedPrototypes[side] = proto"
                                    @dblclick="prototypeSelectionModal_OnValidate"
                                >
                                    {{ proto.label }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sl-prompt__actions">
                        <button class="sl-prompt__button sl-prompt__button--white" @click="prototypeSelectionModal_OnCancel">
                            {{ t('common.cancel') }}
                        </button>

                        <button
                            class="sl-prompt__button"
                            :class="{
                                'sl-prompt__button--primary': selectedPrototype,
                                'sl-prompt__button--disabled': !selectedPrototype,
                            }"
                            @click="prototypeSelectionModal_OnValidate"
                        >
                            {{ t('common.continue') }}
                        </button>
                    </div>
                </div>
            </lsn-modal>
        </teleport>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { ref, computed, toValue }    from 'vue';
import { useI18n }                   from 'vue-i18n';
import usePatientReferenceComposable from '@/composables/PatientReferenceComposable';


// ------------------------------------------------------------ PROPS

const props = defineProps({
    index:
    {
        type:     Number,
        required: true,
    },

    side:
    {
        type:     String,
        required: true,
    },

    parameter:
    {
        type: Object,
        default()
        {
            return {};
        },
    },

    name:
    {
        type:     String,
        required: true,
    },

    error:
    {
        type:    String,
        default: null,
    },

    modelValue:
    {
        type:    [String, Number, Object, Boolean, Array],
        default: null,
    },
});


// ------------------------------------------------------------ DATA

const patientReference1  = ref('');
const selectedPrototypes = ref({});
const isTouched          = ref(false);
const isFocused          = ref(false);

const data = {
    patientReference1,
    selectedPrototypes,
    isTouched,
    isFocused,
};


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });

// PatientReferenceComposable
const {
    // Data
    reorderConfirmationModalData,
    reorderConfirmationModalOpen,
    prototypeSelectionModalOpen,
    lensOrders,

    // Computed
    tabIndex,
    cPrototype,
    previousPrototype,
    filteredPatients,

    // Methods
    initialize,
    onFocus,
    onBlur,
    selectPatient,
    reorder,
    reorderConfirmationModal_OnCancel,
    reorderConfirmationModal_OnValidate,
} = usePatientReferenceComposable(props, data);


// ------------------------------------------------------------ COMPUTED

const selectedPrototype = computed(() =>
{
    return toValue(selectedPrototypes)[toValue(props.side)] ?? null;
});


// ------------------------------------------------------------ METHODS

function prototypeSelectionModal_OnCancel()
{
    prototypeSelectionModalOpen.value = false;
    selectedPrototypes.value = {};
}

function prototypeSelectionModal_OnValidate()
{
    const sPrototype = toValue(selectedPrototype);
    if(!sPrototype)
    {
        return;
    }

    // Reorder the previous lens, forcing the selected prototypes
    const cSide = toValue(props.side);
    const lensOrder = toValue(lensOrders).find(lensOrder => lensOrder.article_data.LENS_SIDE === cSide);
    const lens = {
        reference: lensOrder.reference,
        article_data:
        {
            LENS_SIDE:      cSide,
            PROTOTYPE_CODE: sPrototype.code,
        },
    };

    reorder([lens]);

    prototypeSelectionModalOpen.value = false;
}


// ------------------------------------------------------------ INITIALIZE DATA

initialize();
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
// Field Wrapper
.sl-order-form__field-wrapper
{
    @apply relative w-64;
}

// Input
.sl-order-form__input:focus
{
    @apply outline-none border-primary-400;
}
</style>

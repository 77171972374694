export default class ValidatePreviousOrdersPatientsConsistency
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        // If there is both previous order (meaning: re-order).
        if(this.v.sides.right.previousOrder && this.v.sides.left.previousOrder)
        {
            const rightPatientReference = this.v.sides.right.previousOrder.article_data?.PATIENT_REFERENCE1;
            const leftPatientReference  = this.v.sides.left.previousOrder.article_data?.PATIENT_REFERENCE1;

            // If left previous order has a different patient from right previous order.
            if(rightPatientReference != leftPatientReference)
            {
                console.log(`Both orders have different patient references => show patient selector modal`);

                this.v.showChoosePatientModal = true;

                return Promise.reject(false);
            }
        }

        return true;
    }
}

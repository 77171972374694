function typeCheck(array, variableName = 'array')
{
    if(!Array.isArray(array))
    {
        throw new TypeError(`${variableName} must be an array`);
    }

    return true;
}

export default{
    /**
     * Suffle the items in an array.
     *
     * @param {Array} array
     */
    shuffle(array)
    {
        typeCheck(array);

        const random = array.map(Math.random);
        array.sort((a, b) => random[array.indexOf(a)] - random[array.indexOf(b)]);
    },

    unique(array)
    {
        typeCheck(array);

        const valuesAsKeys = {};
        for(const value of array)
        {
            if(!valuesAsKeys.hasOwnProperty(value))
            {
                valuesAsKeys[value] = true;
            }
        }

        return Object.keys(valuesAsKeys);
    },

    combine(keys, values)
    {
        typeCheck(keys, 'keys');
        typeCheck(values, 'values');

        if(values.length < keys.length)
        {
            throw new Error(`Not enough values (k=${keys.length}, v=${values.length})`);
        }

        return keys.reduce((o, k, i) => ({ ...o, [k]: values[i] }), {});
    },

    removeIf(array, callback = item => !item)
    {
        typeCheck(array);

        if(typeof callback !== 'function')
        {
            throw new TypeError(`callback must be a function`);
        }

        const out = [];
        for(let i = array.length - 1; i >= 0; i--)
        {
            if(callback(array[i], i))
            {
                out.push(...array.splice(i, 1));
            }
        }

        return out.reverse();
    },

    toggle(array, value)
    {
        typeCheck(array);

        const index = array.indexOf(value);
        if(index === -1)
        {
            return [...array, value];
        }

        return array.slice().splice(index, 1);
    },
};

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line sl-order-form__line-splitted sl-order-form__line-section sl-order-form__line--unpadded">
        <!-- Right Parameter -->
        <div
            v-if="line.rightParameter.length"
            class="sl-order-form__line-side sl-order-form__line-side-right sl-order-form__section"
        >
            <component
                :is="componentFactory(param)"
                v-for="(param, columnIndex) in line.rightParameter"
                :key="param.code"
                side="right"
                :index="index"
                :parameter="param"
                :name="'right-' + param.code"
                :error="param.error"
                :column-index="columnIndex"
                :model-value="param.getValue()"
                @update:model-value="updateValue(param, 'right', $event)"
            />
        </div>

        <no-field v-else side="right" />

        <!-- Label -->
        <div class="sl-order-form__label-wrapper text-center">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <label v-if="sectionLabel !== 'NO_LABEL'" class="sl-order-form__label text-center block leading-5" v-html="sectionLabel"></label>
        </div>

        <!-- Left Parameter -->
        <div
            v-if="line.leftParameter.length"
            class="sl-order-form__line-side sl-order-form__line-side-left sl-order-form__section"
        >
            <component
                :is="componentFactory(param)"
                v-for="(param, columnIndex) in line.leftParameter"
                :key="param.code"
                side="left"
                :index="index"
                :parameter="param"
                :name="'left-' + param.code"
                :error="param.error"
                :column-index="columnIndex"
                :model-value="param.getValue()"
                @update:model-value="updateValue(param, 'left', $event)"
            />
        </div>

        <no-field v-else side="left" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import NoField                 from '@/views/order/lenses/fields/NoField.vue';
import SectionInputNumberField from '@/views/order/lenses/fields/SectionInputNumberField.vue';
import SectionInfoField        from '@/views/order/lenses/fields/SectionInfoField.vue';
import SectionSelectorField    from '@/views/order/lenses/fields/SectionSelectorField.vue';

export default
{
    name: 'SectionFormLine',

    components:
    {
        NoField,
    },

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        line:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        parameters()
        {
            return this.line.rightParameter.length
                ? this.line.rightParameter
                : this.line.leftParameter;
        },

        sectionLabel()
        {
            const tkey = `parameters.sections.${this.line.section}.label`;
            const label = this.$t(tkey);

            return (label !== tkey)
                ? label
                : this.line.section;
        },
    },

    methods:
    {
        componentFactory(parameter)
        {
            const display = parameter.splitted_form_display || parameter.form_display;
            switch(display)
            {
                case 'NUMBER_INPUT':
                    return SectionInputNumberField;

                case 'INFO':
                    return SectionInfoField;

                case 'SELECTOR':
                    return SectionSelectorField;
            }

            return NoField;
        },

        updateValue(parameter, side, value)
        {
            // Do not template if value has not changed.
            if(value !== null && value === parameter.getValue())
            {
                console.log('NO VALUE CHANGE', parameter.code, value);
                return;
            }

            console.log('SECTION VALUE SET', parameter.code, value);

            this.$store.commit('lensOrder/setPrototypeValue', {
                paramCode:  parameter.code,
                paramValue: value,
                side,
            });

            this.$store.dispatch('lensOrder/templateAndValidate', { options: { only_present: true, no_required: true } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

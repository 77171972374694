<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-login">
        <!-- Left Side -->
        <div class="lsn-login-side">
            <!-- Logo & Title -->
            <div class="lsn-login-side__title">
                <img class="inline" width="220" src="/img/logo-swisslens-white.svg">
            </div>

            <div class="lsn-login-side__subtitle">
                {{ $t('login.professional') }}
            </div>

            <!-- Footer -->
            <div class="lsn-login-side__footer">
                SwissLens SA<br>
                &copy; {{ year }} - All rights reserved
            </div>
        </div>

        <!-- Right Side (Image) -->
        <div class="lsn-login__image"></div>

        <!-- Login Box -->
        <div class="lsn-login__form">
            <div class="lsn-login__form-content">
                <!-- Username -->
                <div class="lsn-form__wrapper">
                    <label class="lsn-form__label" for="username">
                        {{ $t('login.form.label.username') }}
                    </label>

                    <input
                        id="username"
                        v-model="credentials.username"
                        type="text"
                        class="lsn-form__input w-full"
                        name="username"
                        autocapitalize="off"
                        autocomplete="off"
                        spellcheck="false"
                        autofocus
                        @keyup.enter="authenticate()"
                    >
                </div>

                <!-- Password -->
                <div class="lsn-form__wrapper">
                    <label class="lsn-form__label" for="password">
                        {{ $t('login.form.label.password') }}
                    </label>

                    <input
                        id="password"
                        v-model="credentials.password"
                        type="password"
                        class="lsn-form__input w-full"
                        name="password"
                        @keyup.enter="authenticate()"
                    >
                </div>

                <!-- Error -->
                <div v-if="authError" class="bg-red-100 text-red-500 text-center text-sm py-2 mt-3">
                    {{ authError }}
                </div>

                <!-- Button & Spinner -->
                <div class="lsn-form__wrapper text-center mt-3">
                    <button v-if="!authenticating" class="lsn-btn lsn-btn--primary w-32 mx-auto" @click="authenticate()">
                        {{ $t('login.form.label.sign_in') }}
                    </button>

                    <div v-else class="lsn-btn lsn-btn--primary w-32 mx-auto">
                        <img class="block m-auto" src="/img/loader_white.gif">
                    </div>
                </div>

                <!-- Sign Up / Call to Action -->
                <div class="lsn-form__wrapper text-center text-gray-600 text-sm">
                    {{ $t('login.form.label.sign_up.no_account') }}
                    <br>
                    <a href="https://registration.swisslens.ch/" class="text-primary-600 hover:text-primary-400" :title="$t('login.form.label.sign_up.registration')">
                        {{ $t('login.form.label.sign_up.click_here') }}
                    </a>
                </div>
            </div>
        </div>

        <!-- Server Error -->
        <div v-if="hasServerError" class="fixed inset-0 flex justify-center bg-primary-200/90">
            <div class="w-1/3" style="margin-top: 20%;">
                <div class="mb-2 text-4xl text-primary-500">
                    Oops
                </div>

                <div class="mb-6 text-2xl text-primary-500">
                    Well, this is unexpected…
                </div>

                <div class="text-gray-600">
                    <p class="mb-4">
                        An error has occurred, we're working to fix the problem. Thank you for your patience!
                    </p>

                    <p class="mb-4">
                        In the meantime, you can try reloading the page.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'Login',

    emits: ['initialize'],

    data()
    {
        return {
            authenticating: false,
            authErrorCode:  null,
            hasServerError: false,
            credentials:
            {
                username: null,
                password: null,
            },
            year: new Date().getFullYear(),
        };
    },

    computed:
    {
        authError()
        {
            switch(this.authErrorCode)
            {
                case 102:
                    return this.$t('login.error.user.not_found');

                case 103:
                    return this.$t('login.error.user.wrong_password');

                case 104:
                    return this.$t('login.error.user.inactive');

                case 105:
                    return this.$t('login.error.user.outdated');

                case 108:
                    return this.$t('login.error.user.permission_connect_denied');

                case 109:
                    return this.$t('login.error.user.permission_take_over_denied');
            }

            return null;
        },
    },

    methods:
    {
        authenticate()
        {
            this.authenticating = true;
            this.authErrorCode  = null;

            this.$store.dispatch('auth/authenticate', this.credentials)
                .then(() =>
                {
                    this.$emit('initialize');

                    this.$router.push({
                        name:   'initializing',
                        params: this.$route.params,
                        query:  this.$route.query,
                    });
                })
                .catch(error =>
                {
                    this.authenticating = false;

                    if(error.response && error.response.status != 500)
                    {
                        this.authErrorCode = parseInt(error.response.errors);
                    }
                    else
                    {
                        this.hasServerError = true;
                        this.$honeybadger.setContext({ username: this.credentials.username });
                        this.$honeybadger.notify(error);
                    }
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <div class="sl-order-form__line-band-wrapper">
            <!-- Helper -->
            <div
                v-if="helper"
                v-tippy="{ animation : 'fade' }"
                class="sl-order-form__info-help"
                :content="helper"
            >
                <mdi-icon icon="mdiInformationOutline" />
            </div>
        </div>

        <!-- Content Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Prefix -->
            <div v-if="parameter.prefix !== null" class="sl-order-form__prefix-wrapper">
                <div class="sl-order-form__prefix" v-html="parameter.prefix"></div>
            </div>

            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper flex-col">
                <!-- Field -->
                <div class="sl-order-form__info px-4" v-html="displayedValue"></div>

                <!-- Error -->
                <div v-if="error" class="mt-1 text-red-500 text-xs text-center">
                    {{ error }}
                </div>
            </div>

            <!-- Suffix -->
            <div v-if="parameter.suffix !== null" class="sl-order-form__suffix-wrapper">
                <div class="sl-order-form__suffix" v-html="parameter.suffix"></div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>
        </div>
    </div>
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'InfoField',

    extends: AbstractField,

    computed:
    {
        helper()
        {
            return this.parameter.help;
        },

        displayedValue()
        {
            if(this.parameter.defined_value_label !== null)
            {
                return this.parameter.defined_value_label;
            }

            if(this.parameter.defined_value !== null)
            {
                return this.parameter.defined_value;
            }

            const tkey = `parameters.${this.parameter.code}.values.${this.modelValue}.label`;
            const translation = this.$t(tkey);
            if(translation !== tkey)
            {
                return translation;
            }

            return this.formattedValue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

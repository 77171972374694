<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'OfgAbstractField',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },

        parameter:
        {
            type:    Object,
            default: () => ({}),
        },

        name:
        {
            type:     String,
            required: true,
        },

        error:
        {
            type:    String,
            default: null,
        },

        modelValue:
        {
            type:    [String, Number, Object, Boolean, Array],
            default: null,
        },
    },

    computed:
    {
        tabIndex()
        {
            return 2000 + this.parameter.sort_order;
        },

        hasNote()
        {
            return !!this.parameter.note;
        },

        hasDefaultValue()
        {
            return this.parameter.default_value !== null;
        },

        hasUnit()
        {
            return this.parameter.unit !== null;
        },

        helper()
        {
            const min  = this.format(this.parameter.min);
            const max  = this.format(this.parameter.max);
            const step = (this.format(this.parameter.step) + '').replace(/\+|-/g, '');

            if(isNaN(min) || isNaN(max) || isNaN(step))
            {
                return null;
            }

            return `${min} → ${max}<br>± ${step}`;
        },

        formattedValue()
        {
            return this.format(this.modelValue);
        },
    },

    methods:
    {
        max2decimals(num)
        {
            return parseFloat(num).toFixed(2).replace(/\.0{0,2}$/, '');
        },

        format(value)
        {
            return this.$pf(value, this.parameter.code, 'value');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
// ------------------------------------------------------------ WHOLE PARAMETER/FIELD

.sl-ofg__line
{
    @apply my-1 rounded-md p-2 text-gray-600 transition;

    &:hover
    {
        @apply bg-primary-10;
    }

    &:focus-within
    {
        @apply bg-primary-100;
    }

    &.sl-ofg__line--error
    {
        @apply bg-red-100;
    }
}


// ------------------------------------------------------------ FIELD AREA

.sl-ofg__field
{
    @apply grid gap-2 w-full items-center;

    grid-template-columns: 1fr 1fr 65px;
}


// ---------------------------------------- Label

.sl-ofg__label
{
    > label
    {
        @apply cursor-pointer inline-block align-middle;
    }
}


// ---------------------------------------- Input

.sl-ofg__input
{
    > input,
    > textarea,
    > select
    {
        @apply w-full;

        &:focus
        {
            @apply outline-none border-primary-400;
        }
    }
}

.sl-ofg__line--error
{
    .sl-ofg__input
    {
        > input,
        > textarea,
        > select
        {
            @apply border-red-500;
        }
    }
}


// ---------------------------------------- Helper (min/max/step)

.sl-ofg__helper
{
    @apply mt-1 text-center text-xs text-gray-500;
}


// ---------------------------------------- Note (Tooltip)

.sl-ofg__note
{
    @apply cursor-help inline-block align-middle;

    > .mdi-icon
    {
        @apply cursor-help;
    }
}


// ---------------------------------------- Note (Tooltip)

.sl-ofg__error
{
    @apply mt-1 text-center text-red-500;
}


// ------------------------------------------------------------ SPECIAL FIELDS

// ---------------------------------------- Radio Buttons

.sl-ofg__line--radio .sl-order-form__field-wrapper:focus
{
    @apply outline-none ring-1 ring-primary-400;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-sider class="sl-ein" :is-open="isOpen">
        <div class="sl-ein__title">
            {{ $t('entity.internal_note.panel_title') }}
            <span v-if="isDirty" class="text-red-500 text-lg align-text-top">*</span>
        </div>

        <!-- Editor -->
        <div class="sl-ein__editor-wrapper">
            <quill-editor
                ref="editor"
                v-model:content="internalNote"
                theme="snow"
                content-type="html"
                :enable="!saving"
            />
        </div>

        <!-- Action Buttons -->
        <div class="sl-ein__actions">
            <button class="lsn-btn lsn-btn--primary-outline" @click="cancel">
                {{ $t('common.cancel') }}
            </button>

            <button class="lsn-btn lsn-btn--primary" @click="save">
                {{ $t('common.save') }}
            </button>
        </div>
    </lsn-sider>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { useToast } from 'vue-toastification';

export default
{
    name: 'EntityInternalNotePanel',

    components:
    {
    },

    props: {
        isOpen:
        {
            type:     Boolean,
            required: true,
        },
    },

    emits: ['close'],

    data()
    {
        return {
            internalNote: null,
            saving:       false,
        };
    },

    computed:
    {
        isDirty()
        {
            return this.internalNote != this.$store.state.account.cEntity.internal_note;
        },
    },

    watch:
    {
        '$store.state.account.entityInternalNotePanelIsOpen'(isOpen)
        {
            if(isOpen)
            {
                // Prefill editor with current internal note
                this.internalNote = this.$store.state.account.cEntity.internal_note || null;

                // Automatically focus textarea
                this.$nextTick(() => this.$refs.editor?.focus());
            }
        },
    },

    methods:
    {
        cancel()
        {
            this.internalNote = this.$store.state.account.cEntity.internal_note;
            this.close();
        },

        save()
        {
            if(!this.isDirty)
            {
                return;
            }

            this.saving = true;

            this.$store.dispatch('account/saveEntityInternalNote', this.internalNote)
                .then(entity =>
                {
                    // Just close the panel
                    this.$nextTick(() => this.close());
                })
                .catch(error =>
                {
                    // Display the error
                    useToast().error(this.$t('entity.internal_note.save_error'));
                })
                .then(() =>
                {
                    this.saving = false;
                });
        },

        close()
        {
            this.$emit('close');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
// "ein" stands for "Entity Internal Note"
.sl-ein
{
    @apply w-[500px] p-4;
}

.sl-ein__title
{
    @apply mt-4 mb-8 text-3xl text-center text-primary-700 font-thin;
}

.sl-ein__editor-wrapper
{
    @apply mb-4;
}

.sl-ein :deep(.ql-editor)
{
    @apply text-gray-700;

    height: calc(
        100vh
        - 2 * theme("space.4")  /* panel padding */
        - (
            theme("space.4")    /* title margin-top */
            + theme("space.8")  /* title margin-botom */
            + 2.25rem           /* title line-height */
        )
        - theme("space.4")      /* editor wrapper margin-bottom */
        - 42px                  /* editor toolbar height */
        - 1px                   /* editor border */
        - 30px                  /* action buttons height */
    );
}

.sl-ein__actions
{
    @apply flex justify-end items-center space-x-2;
}
</style>

import { createI18n } from 'vue-i18n';
import moment from 'moment';
import axios from '@/axios';
import { getUserConfigurations } from './vue-configurations';

export const SUPPORTED_LOCALES = ['de', 'en', 'es', 'fr', 'it', 'pl', 'pt', 'nl', 'uk', 'ro'];

/** @var i18n The global Vue-i18n instance. */
let i18n = null;

export function setupI18n()
{
    if(i18n !== null)
    {
        throw new Error('The i18n object was already set up');
    }

    i18n = createI18n({
        globalInjection: true,
        legacy:          false,
        locale:          'en',
        fallbackLocale:  'en',
    });

    setI18nLanguage('en');

    return i18n;
};

export function setI18nLanguage(locale)
{
    // If given locale is not supported => switch to English.
    if(!SUPPORTED_LOCALES.includes(locale))
    {
        locale = 'en';
    }

    const i18n = getInstance();

    if(i18n.global)
    {
        if(i18n.mode === 'legacy')
        {
            i18n.global.locale = locale;
        }
        else
        {
            i18n.global.locale.value = locale;
        }
    }
    else
    {
        i18n.locale = locale;
    }

    getUserConfigurations().set('language', locale);
    axios.defaults.headers.common['Accept-Language'] = locale;
    moment.locale(locale);
    document.querySelector('html').setAttribute('lang', locale);
};

export async function loadMessages(locale)
{
    const i18n = getInstance();

    const headers = {
        'Cache-Control': 'no-cache',
        'Pragma':        'no-cache',
        'Expires':       '0',
    };

    // Load English translations (fallback)
    if(locale !== 'en')
    {
        const response_en = await axios.get('/translations/en.json', { headers });
        i18n.global.setLocaleMessage('en', response_en.data);
    }

    // Load translations for selected locale
    const response = await axios.get(`/translations/${locale}.json`, { headers });
    i18n.global.setLocaleMessage(locale, response.data);
};

/**
 * Get the global i18n instance.
 *
 * @returns {{}} The `i18n` object.
 * @throws {TypeError} If the i18n object hasn't been initialized with `setupI18n()`, yet.
 */
export function getInstance()
{
    if(i18n === null)
    {
        throw new TypeError('The i18n object has not been set up, yet');
    }

    return i18n;
};

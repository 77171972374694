<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__over-refraction-calculator">
                    <a
                        :tabindex="tabIndex"
                        @click.prevent="openOverRefractionCalculatorPanel"
                        @keypress.enter="openOverRefractionCalculatorPanel"
                    >
                        {{ $t('calculators.over_refraction.title') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from '../AbstractField.vue';

export default
{
    name: 'OverRefractionCalculator',

    extends: AbstractField,

    methods:
    {
        openOverRefractionCalculatorPanel()
        {
            this.$store.dispatch('calculators/closeOverRefractionPanel'); // close first to force reload values
            this.$nextTick(() =>
            {
                this.$store.dispatch('calculators/openOverRefractionPanel', this.side);
            });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__over-refraction-calculator a
{
    @apply cursor-pointer outline-none border border-transparent px-2 py-1 text-primary-500 text-sm select-none transition
        hover:underline
        focus-visible:no-underline focus-visible:ring-0 focus-visible:border-primary-500
        active:text-primary-700;
}
</style>

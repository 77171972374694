<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Field Wrapper -->
            <div
                class="sl-order-form__field-wrapper lsn-form__radio-item-wrapper"
                :tabindex="tabIndex"
                @keydown.up.prevent="selectPrevious()"
                @keydown.left.prevent="selectPrevious()"
                @keydown.right.prevent="selectNext()"
                @keydown.down.prevent="selectNext()"
            >
                <!-- Field -->
                <div
                    v-for="item in parameter.list_items"
                    :key="item.ovalue"
                    class="lsn-form__radio-item"
                    :class="{ 'lsn-form__radio-item--selected': item.ovalue == modelValue, 'lsn-form__radio-item--disabled': !item.is_enabled }"
                    @click="setValue(item)"
                >
                    <!-- Label -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="item.label"></div>

                    <!-- Help -->
                    <div
                        v-if="item.help"
                        v-tippy="{ animation : 'fade' }"
                        class="lsn-form__radio-item-help"
                        :content="item.help"
                    >
                        <mdi-icon icon="mdiInformationOutline" />
                    </div>
                </div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'RadioButtonField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
        };
    },

    computed:
    {
        helper()
        {
            return null;
        },

        cIndex()
        {
            return this.parameter.list_items.findIndex(i => i.ovalue == this.modelValue);
        },
    },

    methods:
    {
        setValue(item)
        {
            if(!item.is_enabled)
            {
                return;
            }

            this.isTouched = true;
            this.$emit('update:modelValue', item.ovalue);
        },

        selectPrevious()
        {
            let prevIndex = this.cIndex;
            for(let i = (this.cIndex === -1 ? this.parameter.list_items.length : this.cIndex) - 1; i >= 0; i--)
            {
                if(this.parameter.list_items[i].is_enabled)
                {
                    prevIndex = i;
                    break;
                }
            }

            if(prevIndex !== this.cIndex)
            {
                this.setValue(this.parameter.list_items[prevIndex]);
            }
        },

        selectNext()
        {
            let nextIndex = this.cIndex;
            for(let i = this.cIndex + 1; i < this.parameter.list_items.length; i++)
            {
                if(this.parameter.list_items[i].is_enabled)
                {
                    nextIndex = i;
                    break;
                }
            }

            if(nextIndex !== this.cIndex)
            {
                this.setValue(this.parameter.list_items[nextIndex]);
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line-side
{
    &.sl-order-form__line-side-right .lsn-form__radio-item-wrapper
    {
        @apply -mr-px;
    }

    &.sl-order-form__line-side-left .lsn-form__radio-item-wrapper
    {
        @apply -ml-px;
    }
}
</style>

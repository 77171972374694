/* ================
 * Overlay Module
 * ================
 */

export default
{
    namespaced: true,

    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            show: false,
            text: '',
        };
    },

    // ------------------------------------------------------------------------- MUTATIONS

    mutations:
    {
        setVisibility(state, isVisible)
        {
            state.show = isVisible;
        },

        setText(state, text)
        {
            state.text = text;
        },
    },
};

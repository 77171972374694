<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="card">
        <div class="card-header">
            <div class="card-header__icon-wrapper">
                <mdi-icon icon="mdiNewspaperVariantMultipleOutline" class="card-header__icon" />
            </div>

            <div class="card-header__title">
                {{ $t('home.widgets.news.title') }}
            </div>
        </div>

        <div v-if="hasNews" class="w-full text-center">
            <!-- <opti-munich-2024
                v-if="hasOptiTicket"
                format="stacked"
                class="m-4"
            /> -->

            <div v-for="(n, index) in news" :key="index" class="border-b border-gray-200 py-3 px-4 text-left text-gray-600 text-sm" v-html="n"></div>
        </div>

        <div v-else class="no-news">
            {{ $t('home.widgets.news.none') }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
// import constants from '@/constants/constants';
// import {
//     ARTICLE_CODE as OPTI_TICKET_ARTICLE_CODE,
//     default      as OptiMunich2024,
// } from  '@/components/promotions/OptiMunich2024.vue';

export default
{
    name: 'NewsWidget',

    components:
    {
        // OptiMunich2024, // current promotion (Q4 2023)
    },

    data()
    {
        return {
            news: [],
        };
    },

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        hasNews()
        {
            // return this.hasOptiTicket;
            return this.news.length;
        },

        // hasOptiTicket()
        // {
        //     const articleStatus = this.$store.getters['catalog/getByCode'](OPTI_TICKET_ARTICLE_CODE)?.status;
        //     if(!articleStatus)
        //     {
        //         return false;
        //     }

        //     return articleStatus !== constants.articles.status.INACTIVE;
        // },
    },

    created()
    {
        if(['AT', 'DE'].includes(this.cEntity.country))
        {
            this.news.push(this.$t('home.widgets.news.new_german_phone_number'));
        }
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.card
{
    min-height: 300px;
    @apply w-1/2 mr-8;
}

.news-item
{

}

.no-news
{
    @apply w-full mt-8 text-center text-gray-500;
}
</style>

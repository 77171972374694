/**********
 * Router *
 *********/

import { isEmpty }                        from 'lodash-es';
import { createWebHistory, createRouter } from 'vue-router';
import routes                             from './routes';
import {
    setI18nLanguage,
    loadMessages,
    getInstance as getI18n,
}  from '@/i18n';

let router = null;

export function setupRouter(store)
{
    // ------------------------------------------------------------ CONSTRUCTOR

    if(router !== null)
    {
        throw new Error('The router was already set up');
    }

    router = createRouter({
        history: createWebHistory(),
        base:    import.meta.env.VITE_API_LOCATION,
        routes,
    });

    const ROUTES_WITHOUT_ENTITY_ID = [
        'login',
        'initializing',
        'entities-switch',
    ];

    const i18n = getI18n();


    // ------------------------------------------------------------ GUARD 1: Auto-Set Params

    router.beforeEach((to, from) =>
    {
        console.groupCollapsed('Guard 1: Auto-Set Parameter');

        let routeUpdated = false;
        let params = to.params;

        // Language format
        let langRegex = new RegExp('[a-z]{2}');

        // If language is not set or has an incorrect format
        if(!params.lang || params.lang == 'undefined' || !langRegex.test(params.lang))
        {
            // Retrieve locale from Vue-i18n, if any
            if(i18n.global.locale.value)
            {
                console.log('Locale retrieved from i18n');

                params.lang = i18n.global.locale.value;

                routeUpdated = true;
            }
            else // Vue-i18n locale is not set => try to retrieve locale from browser
            {
                console.log('Locale set from browser');

                // Get language from browser or default to "en"
                let locale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language || 'en';
                params.lang = locale.trim().split(/-|_/)[0];

                routeUpdated = true;
            }
        }

        // Set current entity if it's not set (where applicable)
        if(!ROUTES_WITHOUT_ENTITY_ID.includes(to.name))
        {
            if((!params.entity_id || params.entity_id == 'undefined'))
            {
                // Use current entity from the store, if any
                if(store.state.account.cEntity)
                {
                    console.log('Entity set from store');

                    params.entity_id = store.state.account.cEntity.id;

                    routeUpdated = true;
                }
            }
        }

        console.groupEnd();

        if(routeUpdated)
        {
            return {
                name:   to.name,
                params: params,
                query:  to.query,
            };
        }
        else
        {
            return true;
        }
    });


    // ------------------------------------------------------------ GUARD 2: Language

    router.beforeEach(async(to, from) =>
    {
        console.groupCollapsed('Guard 2: Language');

        let out = true;

        const lang = to.params.lang;

        if(lang && lang !== 'undefined' && typeof lang !== 'undefined')
        {
            console.log('OK', to);

            if(lang !== i18n.global.locale.value)
            {
                console.log(`Locale set from URL: "${lang}"`);
                setI18nLanguage(lang);
            }

            const messages = i18n.global.getLocaleMessage(lang);

            // If there are no messages => load corresponding messages.
            if(isEmpty(messages))
            {
                console.log(`Load messages (${lang})`);
                await loadMessages(lang);
            }
        }
        else
        {
            const params = to.params;

            // Get language from browser
            const locale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language || 'en';
            params.lang = locale.trim().split(/-|_/)[0];

            console.log(`Locale set from browser (${params.lang})`);

            out = {
                name:  to.name,
                params,
                query: to.query,
            };
        }

        console.groupEnd();

        return out;
    });


    // ------------------------------------------------------------ GUARD 3: Authentication

    router.beforeEach((to, from) =>
    {
        console.groupCollapsed('Guard 3: Authentication');

        let out = true;

        const isAuthenticated = store.state.auth.authenticated;
        if(to.meta.auth && !isAuthenticated)
        {
            console.log('Redirect to login');

            out = {
                name:   'login',
                params: to.params,
                query:  to.query,
            };
        }
        else if(!to.meta.auth && isAuthenticated)
        {
            console.log('Redirect to home');

            out = {
                name:   'home',
                params: to.params,
                query:  to.query,
            };
        }
        else
        {
            console.log('OK', to);
        }

        console.groupEnd();

        return out;
    });


    // ------------------------------------------------------------ GUARD 4: Entity

    router.beforeEach((to, from) =>
    {
        console.groupCollapsed('Guard 4: Entity');

        let out = true;

        const isAuthenticated = store.state.auth.authenticated;
        const allowedRouteNames = [
            'entities-switch',
            'initializing',
        ];

        if(isAuthenticated && !to.params.entity_id && !allowedRouteNames.includes(to.name))
        {
            console.log('Go to entity switch');

            out = {
                name:   'entities-switch',
                params: to.params,
                query:  to.query,
            };
        }
        else
        {
            console.log('OK', to);
        }

        console.groupEnd();

        return out;
    });


    return router;
}

/**
 * Get the global router instance.
 *
 * @returns {{}} The `router` object.
 * @throws {TypeError} If the router hasn't been initialized with `setupRouter()`, yet.
 */
export function getInstance()
{
    if(router === null)
    {
        throw new TypeError('The router has not been set up, yet');
    }

    return router;
};

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
        </div>

        <!-- Line Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Field-Wrapper -->
            <div class="sl-order-form__field-wrapper w-16">
                <!-- Field -->
                <input
                    v-model.lazy="specialPrice"
                    class="sl-order-form__input"
                    type="number"
                    :name="name"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    @focus="onFocus"
                    @blur="onBlur"
                >
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from '../AbstractField.vue';

export default
{
    name: 'SpecialPrice',

    extends: AbstractField,

    data()
    {
        return {
            specialPrice: null,
            isTouched:    false,
            isFocused:    false,
        };
    },

    computed:
    {
        adaptation()
        {
            return this.$store.state.lensOrder.adaptations[this.side];
        },
    },

    watch:
    {
        specialPrice(specialPrice)
        {
            this.adaptation.special_price = specialPrice;
        },
    },

    mounted()
    {
        this.specialPrice = this.adaptation.special_price;
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__input:focus
{
    @apply outline-none border-primary-400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number]
{
    -moz-appearance: textfield;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <div id="sl-cleaning-product">
                <!-- Page Header -->
                <div class="sl-page__header">
                    <div class="sl-page__title">
                        {{ $t('topographers.page_title') }}
                    </div>
                </div>

                <!-- Page Content -->
                <div class="sl-page__content text-gray-500">
                    <articles-grid
                        :articles="articles"
                        :badges-callback="getBadgesCallback()"
                        hide-unit-price
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { getBadgesCallback } from '@/shared/ArticlesGridSharedObjects';
import ArticlesGrid from '@/components/articles-grid/ArticlesGrid.vue';

export default
{
    name: 'TopographersList',

    components:
    {
        ArticlesGrid,
    },

    props:
    {
    },

    data()
    {
        return {};
    },

    computed:
    {
        articles()
        {
            return this.$store.state.catalog.articles.filter(a => a.tags.some(t => t.code === 'TOPOGRAPHER'));
        },
    },

    created()
    {
    },

    methods:
    {
        getBadgesCallback,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <component
        :is="componentFactory(parameter)"
        :key="parameter.code"
        v-model="pValue"
        :side="side"
        :name="name"
        :parameter="parameter"
        :error="error"
    />
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OfgHiddenField      from './fields/OfgHiddenField.vue';
import OfgInfoField        from './fields/OfgInfoField.vue';
import OfgInputNumberField from './fields/OfgInputNumberField.vue';
import OfgInputTextField   from './fields/OfgInputTextField.vue';
import OfgRadioButtonField from './fields/OfgRadioButtonField.vue';
import OfgSelectorField    from './fields/OfgSelectorField.vue';
import OfgTextareaField    from './fields/OfgTextareaField.vue';

export default
{
    name: 'OfgFieldFactory',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
        parameter:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        form()
        {
            return this.$store.getters['lensOrder/getOfg'](this.side);
        },

        error()
        {
            return this.parameter.error;
        },

        name()
        {
            return `OFG_${this.side}_${this.parameter.code}`;
        },

        pValue:
        {
            get()
            {
                return this.parameter.getValue();
            },

            set(value)
            {
                // Do not template if value has not changed.
                if(value !== null && value === this.parameter.getValue())
                {
                    console.log('NO VALUE CHANGE', this.parameter.code, value, this.parameter.getValue());

                    return;
                }

                console.log('SINGLE VALUE SET', this.side, this.parameter.code, value);

                this.$store.commit('lensOrder/setOfgValue', {
                    side:       this.side,
                    paramCode:  this.parameter.code,
                    paramValue: value,
                });

                this.$store.dispatch('lensOrder/template', { side: this.side, target: 'ofg' });
            },
        },
    },

    methods:
    {
        componentFactory()
        {
            const display = this.parameter.form_splitted_display || this.parameter.form_display;
            switch(display)
            {
                case 'NUMBER_INPUT':
                    return OfgInputNumberField;

                case 'TEXT_INPUT':
                    return OfgInputTextField;

                case 'LONG_TEXT_INPUT':
                    return OfgTextareaField;

                case 'SELECTOR':
                    return OfgSelectorField;

                case 'OPEN_SELECTOR':
                    return OfgSelectorField; // todo: OfgOpenSelectorField

                case 'RADIO_BUTTON':
                    return OfgRadioButtonField;

                case 'CHECKBOX':
                    return OfgInputTextField; // todo: OfgCheckboxField

                case 'INFO':
                    return OfgInfoField;

                case 'HIDDEN':
                    return OfgHiddenField;

                    // case 'CUSTOM':
                    //     return custom[
                    //         upperFirst(camelCase(this.parameter.code))
                    //     ];

                default:
                    console.log('PARAMETER', this.parameter);

                    throw new Error(`Lens parameter form display "${this.parameter.display}" does not exist for parameter with code "${this.parameter.code}" on the "${this.side}" side.`);
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

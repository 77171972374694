import { merge } from 'lodash-es';

export default class SetPrototypePreviousValuesByPreviousOrder
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const previousOrder = this.v.sides[this.side].previousOrder;

        // If there is a previous order.
        if(previousOrder)
        {
            const adaptation = this.v.$store.state.lensOrder.adaptations[this.side];

            // If adaptation has not already previous values.
            if(!adaptation.getPrototype().hasPreviousValues())
            {
                const previousValues = merge({},
                    previousOrder.article_data,
                    previousOrder.logistics_data,
                    previousOrder.return_data,
                    previousOrder.extra
                );

                adaptation.getPrototype().setPreviousValues(previousValues);

                console.log(`Previous values on ${this.side} side have been set`, previousValues);
            }
            else
            {
                console.log(`Previous values on ${this.side} side have already been set in last dispatch => no change`);
            }
        }

        return true;
    }
}

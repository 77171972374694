/* =================
 * Prototype Module
 * =================
 */

import { sortBy, filter } from 'lodash-es';
import axios              from '@/axios';
import constants          from '@/constants/constants';

export function setupPrototypesModule()
{
    return {
        namespaced: true,

        // ------------------------------------------------------------------------- STATE

        state:
        {
            prototypes: [],
            forms:      [],
        },

        // ------------------------------------------------------------------------- GETTERS

        getters:
        {
            allOneByModel: state =>
            {
                const rPrototypes = [];
                const eModelCodes = [];

                state.prototypes.forEach(prototype =>
                {
                    if(prototype.status !== constants.articles.status.INACTIVE && !eModelCodes.includes(prototype.model_code))
                    {
                        rPrototypes.push(prototype);
                        eModelCodes.push(prototype.model_code);
                    }
                });

                return sortBy(rPrototypes, 'sort_order');
            },

            allByModelCode: state => modelCode =>
            {
                return state.prototypes.filter(p => p.model_code === modelCode && p.status !== constants.articles.status.INACTIVE);
            },

            /**
             * @returns All prototype tags (no duplicates).
             */
            allTags: state =>
            {
                const existingTags = {};

                return state.prototypes.filter(p => p.status !== constants.articles.status.INACTIVE)
                    .flatMap(p => p.tags)
                    .filter(t =>
                    {
                        if(existingTags.hasOwnProperty(t.code))
                        {
                            return false;
                        }

                        existingTags[t.code] = t;

                        return true;
                    });
            },

            getFirstByModel: state => modelCode => state.prototypes.filter(p => p.status !== constants.articles.status.INACTIVE).find(p => p.model_code === modelCode),

            /**
             * Get the default prototype for a given model.
             *
             * This is usually the first variant found, unless it has a `default_variant`.
             *
             * @param {*} state
             * @param {*} getters
             * @returns {{}}
             */
            getDefaultByModel: (state, getters) => modelCode =>
            {
                const firstPrototypeVariant = getters.getFirstByModel(modelCode);
                const defaultVariantCode = firstPrototypeVariant.default_variant;
                if(!defaultVariantCode)
                {
                    return firstPrototypeVariant;
                }

                const defaultPrototypeVariant = getters.getByCode(`${modelCode}_${defaultVariantCode}`);
                if(defaultPrototypeVariant?.status !== constants.articles.status.INACTIVE)
                {
                    return firstPrototypeVariant;
                }

                return defaultPrototypeVariant;
            },

            getByCode: state => code => state.prototypes.find(p => p.code === code),

            hasActivePrototypesByTag: state => tags => state.prototypes.some(p =>
            {
                if(p.status !== constants.articles.status.ACTIVE)
                {
                    return false;
                }

                if(!Array.isArray(tags))
                {
                    tags = [tags];
                }

                const pTags = p.tags.map(t => t.code);

                return tags.every(t => pTags.includes(t));
            }),
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            setPrototypes(state, prototypes)
            {
                state.prototypes = prototypes;
            },
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            fetchAll({ commit, rootState })
            {
                const eid = rootState.account.cEntity.id;

                return axios.get(`/api/prototypes/entity/${eid}`)
                    .then(response =>
                    {
                        commit('setPrototypes', response.data);
                    });
            },
        },
    };
};

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table
        v-model:selected="selectedBasketLineIds"
        :data="basketLines"
        class="sl-basket-table"
        selectable
        openable
    >
        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-basket-line-cell__date">
                {{ $t('order_table.headers.date') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-basket-line-cell__article">
                {{ $t('order_table.headers.article') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-basket-line-cell__ext-ref">
                {{ $t('order_table.headers.ext_ref') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-basket-line-cell__quantity">
                {{ $t('order_table.headers.quantity_abbreviated') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-basket-line-cell__price">
                {{ $t('order_table.headers.base_discount_price') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-basket-line-cell__actions" />
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <component
                :is="getLineSummaryComponent(item)"
                :basket-line="item"
                @edit="edit"
                @remove="remove"
            />
        </template>

        <template #details="{ item }">
            <component :is="getLineDetailsComponent(item)" :basket-line="item" />
        </template>
    </lsn-table>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable                 from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell             from '@/components/LsnTable/LsnTableCell.vue';
import LensBasketLineSummary    from '@/components/basket/LensBasketLineSummary.vue';
import LensBasketLineDetails    from '@/components/basket/LensBasketLineDetails.vue';
import DefaultBasketLineSummary from '@/components/basket/DefaultBasketLineSummary.vue';

export default
{
    name: 'BasketTable',

    components:
    {
        LsnTable,
        LsnTableCell,
    },

    props:
    {
        basketLines:
        {
            type:     Array,
            required: true,
        },

        selectedIds:
        {
            type: Array,
            default()
            {
                return selectedIds;
            },
        },
    },

    emits: ['edit', 'remove', 'update:selectedIds'],

    data()
    {
        return {
            selectedBasketLineIds: this.selectedIds,
        };
    },

    watch:
    {
        selectedBasketLineIds:
        {
            deep: true,
            handler(ids)
            {
                this.$emit('update:selectedIds', ids);
            },
        },
    },

    created()
    {
    },

    methods:
    {
        getLineSummaryComponent(basketLine)
        {
            if(basketLine.article.tags.some(a => a.code === 'LENS'))
            {
                return LensBasketLineSummary;
            }

            return DefaultBasketLineSummary;
        },

        getLineDetailsComponent(basketLine)
        {
            if(basketLine.article.tags.some(a => a.code === 'LENS'))
            {
                return LensBasketLineDetails;
            }

            // return DefaultBasketLineDetails;
        },

        edit(basketLine)
        {
            this.$emit('edit', basketLine);
        },

        remove(basketLine)
        {
            this.$emit('remove', basketLine);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-basket-table.lsn-table
{
    // Column width definitions
    --column-width-checkbox:      40px;
    --column-width-chevron:       40px;
    --column-width-date:          90px;
    --column-width-article:        1fr;
    --column-width-ext_ref:      110px;
    --column-width-quantity:      60px;
    --column-width-base_price:    70px;
    --column-width-discount:      65px;
    --column-width-price_basket:  88px;
    --column-width-actions:      104px;
}

.sl-basket-table.lsn-table :deep(.lsn-table__line-summary)
{
    @apply py-1;
}

.sl-basket-table.lsn-table:not(.lsn-table--selectable) :deep(.lsn-table__header)
{
    grid-template-columns:
        var(--column-width-chevron)
        var(--column-width-date)
        var(--column-width-article)
        var(--column-width-ext_ref)
        var(--column-width-quantity)
        calc(
            var(--column-width-base_price) +
            var(--column-width-discount) +
            var(--column-width-price_basket)
        )
        var(--column-width-actions);
}

.sl-basket-table.lsn-table:not(.lsn-table--selectable) :deep(.lsn-table__line-summary)
{
    grid-template-columns:
        var(--column-width-chevron)
        var(--column-width-date)
        var(--column-width-article)
        var(--column-width-ext_ref)
        var(--column-width-quantity)
        var(--column-width-base_price)
        var(--column-width-discount)
        var(--column-width-price_basket)
        var(--column-width-actions);
}

.sl-basket-table.lsn-table.lsn-table--selectable :deep(.lsn-table__header)
{
    grid-template-columns:
        var(--column-width-checkbox)
        var(--column-width-chevron)
        var(--column-width-date)
        var(--column-width-article)
        var(--column-width-ext_ref)
        var(--column-width-quantity)
        calc(
            var(--column-width-base_price) +
            var(--column-width-discount) +
            var(--column-width-price_basket)
        )
        var(--column-width-actions);
}

.sl-basket-table.lsn-table.lsn-table--selectable :deep(.lsn-table__line-summary)
{
    grid-template-columns:
        var(--column-width-checkbox)
        var(--column-width-chevron)
        var(--column-width-date)
        var(--column-width-article)
        var(--column-width-ext_ref)
        var(--column-width-quantity)
        var(--column-width-base_price)
        var(--column-width-discount)
        var(--column-width-price_basket)
        var(--column-width-actions);
}

.sl-basket-table.lsn-table :deep(.sl-basket-line-cell__ext-ref)
{
    @apply text-center;
}

.sl-basket-table.lsn-table :deep(.sl-basket-line-cell__quantity),
.sl-basket-table.lsn-table :deep(.sl-basket-line-cell__quantity input)
{
    @apply text-center;
}

.sl-basket-table.lsn-table :deep(.sl-basket-line-cell__discount)
{
    @apply text-center;
}

.sl-basket-table.lsn-table :deep(.sl-basket-line-cell__price)
{
    @apply text-center;
}

// Hide quantity/discount/price input ▲/▼ buttons
.sl-basket-table :deep(.sl-basket-line-cell__quantity input::-webkit-outer-spin-button),
.sl-basket-table :deep(.sl-basket-line-cell__quantity input::-webkit-inner-spin-button),
.sl-basket-table :deep(.sl-basket-line-cell__discount input::-webkit-outer-spin-button),
.sl-basket-table :deep(.sl-basket-line-cell__discount input::-webkit-inner-spin-button),
.sl-basket-table :deep(.sl-basket-line-cell__price    input::-webkit-outer-spin-button),
.sl-basket-table :deep(.sl-basket-line-cell__price    input::-webkit-inner-spin-button)
{
    // Chrome, Safari, Edge, Opera
    -webkit-appearance: none;
}

.sl-basket-table :deep(.sl-basket-line-cell__quantity input[type=number]),
.sl-basket-table :deep(.sl-basket-line-cell__discount input[type=number]),
.sl-basket-table :deep(.sl-basket-line-cell__price    input[type=number])
{
    // Firefox
    -moz-appearance: textfield;
}

.sl-basket-table :deep(.sl-basket-line-cell__quantity input[disabled]),
.sl-basket-table :deep(.sl-basket-line-cell__discount input[disabled]),
.sl-basket-table :deep(.sl-basket-line-cell__price    input[disabled])
{
    @apply cursor-not-allowed border-gray-300 bg-gray-100 text-gray-300;

    user-select: none;
}

.sl-basket-table :deep(.sl-basket-line-cell__price input)
{
    @apply text-right;
}

.sl-basket-table :deep(.sl-basket-line-cell__discount input)
{
    @apply pl-1 px-4 text-right;
}

.sl-basket-table :deep(.sl-basket-line-cell__discount--editable)
{
    @apply relative;

    &::after
    {
        content: "%";

        @apply absolute top-0 right-3 flex items-center h-full pointer-events-none;
    }
}

.sl-basket-table :deep(.lsn-table__header .sl-basket-line-cell__price)
{
    @apply block text-center;
}

.sl-basket-table:not(.sl-basket-table) :deep(.sl-basket-line-cell__price)
{
    @apply flex justify-between items-center;
}

.sl-basket-table :deep(.sl-basket-line-cell__actions)
{
    @apply text-right;
}

.lsn-table-btn__spacer
{
    width: 34px;
}
</style>

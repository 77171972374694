<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="card">
        <div class="card-header">
            <div class="card-header__icon-wrapper">
                <mdi-icon icon="mdiDolly" class="card-header__icon" />
            </div>

            <div class="card-header__title">
                {{ $t('home.widgets.orders-follow-up.title') }}
            </div>
        </div>

        <!-- Loading Spinner -->
        <div v-if="loading" class="loader-wrapper">
            <img class="block" src="/img/loader-5.gif">
        </div>

        <!-- Ongoing Orders -->
        <perfect-scrollbar v-else-if="orders.length" ref="ps" class="sl-orders">
            <div v-for="order in orders" :key="order.id" class="sl-order">
                <component
                    :is="followUpLineBuilder(order)"
                    :order="order"
                    class="sl-article-details w-9/12 xl:w-10/12"
                />

                <div
                    v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                    class="sl-order__status w-3/12 xl:w-2/12"
                    :content="formatDateTime(status(order).at)"
                >
                    {{ $t(`order_table.status.${status(order).code}.label`) }}
                </div>
            </div>
        </perfect-scrollbar>

        <!-- No Ongoing Orders -->
        <div v-else class="no-orders">
            {{ $t('home.widgets.orders-follow-up.none') }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import axios        from '@/axios';
import moment       from 'moment';
import LensLine     from './LensLine.vue';
import StandardLine from './StandardLine.vue';

export default
{
    name: 'FollowUpWidget',

    data()
    {
        return {
            orders:  [],
            loading: true,
        };
    },

    created()
    {
        const eid = this.$store.state.account.cEntity.id;

        return axios.get(`/api/order/entity/${eid}/ongoing`)
            .then(response =>
            {
                this.orders = response.data;
                this.loading = false;
            })
            .catch(error =>
            {
                console.log(error);
            });
    },

    methods:
    {
        followUpLineBuilder(order)
        {
            if(order.article.tags.some(a => a.code === 'LENS'))
            {
                return LensLine;
            }
            else
            {
                return StandardLine;
            }
        },

        status(order)
        {
            return order.status[order.status.length - 1];
        },

        formatDateTime(date)
        {
            return moment(date).format('DD.MM.YYYY HH:mm:ss');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.card
{
    min-height: 300px;
    @apply w-1/2 mb-8;
}

.loader-wrapper
{
    height: 260px;
    @apply flex justify-center items-center;
}

.sl-orders
{
    height: 260px;
    @apply w-full overflow-y-hidden;
}

.sl-order
{
    @apply flex items-center justify-between py-2 pr-2 pl-4 border-b border-gray-200;
}

.sl-article-details
{
    @apply flex;
}

.sl-order__status
{
    width: 100px;
    @apply px-3 py-2 rounded-full truncate text-center text-xs bg-primary-10 text-primary-300;
}

.no-orders
{
    @apply w-full mt-8 text-center text-gray-400;
}
</style>

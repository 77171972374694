export default class RefreshWaitingForReviewOrdersList
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        this.v.$store.dispatch('orders/fetchWaitingForReview');

        return true;
    }
}

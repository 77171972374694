<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table :data="sales" class="sl-sales">
        <template #subtable-caption>
            {{ $t("invoicing.sales.table.title") }}
        </template>

        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-sale__reference">
                {{ $t("invoicing.table.headers.reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-sale__serial_number">
                {{ $t("invoicing.table.headers.serial_number") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-sale__label">
                {{ $t("invoicing.table.headers.label") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-sale__price">
                {{ $t("invoicing.table.headers.amount") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-sale__currency">
                {{ $t("invoicing.table.headers.currency") }}
            </lsn-table-cell>
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <sale-line-summary :sale="item" />
        </template>
    </lsn-table>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import SaleLineSummary from '@/components/sale/SaleLineSummary.vue';

export default
{
    name: 'SaleTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        SaleLineSummary,
    },

    props:
    {
        sales:
        {
            type:     Array,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="postcss">
.sl-sales
{
    --column-width: 100px;
}

.lsn-table:not(.lsn-table--openable).sl-sales > .lsn-table__header,
.lsn-table:not(.lsn-table--openable).sl-sales > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns:
        repeat(2, var(--column-width))
        calc(100% - (4 * var(--column-width)))
        repeat(2, var(--column-width));
}

.sl-sale__price,
.sl-sale__actions
{
    @apply text-right;
}

.sl-sale__currency
{
    @apply text-center;
}
</style>
export default class SetPrototypesSide
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        let rightAdaptation = this.v.$store.state.lensOrder.adaptations.right;

        if(rightAdaptation)
        {
            // Set lens side value.
            rightAdaptation.getPrototype().setValue('LENS_SIDE', 'right');

            console.log(`Right prototype side has been set ("LENS_SIDE" parameter)`);
        }

        let leftAdaptation = this.v.$store.state.lensOrder.adaptations.left;

        if(leftAdaptation)
        {
            // Set lens side value.
            leftAdaptation.getPrototype().setValue('LENS_SIDE', 'left');

            console.log(`Left prototype side has been set ("LENS_SIDE" parameter)`);
        }

        return true;
    }
}

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-footer">
        <staff-footer-contents />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import StaffFooterContents from '@/components/footer/StaffFooterContents.vue';

export default
{
    name: 'Footer',

    components:
    {
        StaffFooterContents,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

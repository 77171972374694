<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-sale-table__column sl-sale__reference">
        {{ sale.id_commande }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-sale-table__column sl-sale__serial-number">
        {{ sale.numero_sn }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-sale-table__column sl-sale__label">
        {{ sale.label }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-sale-table__column sl-sale__price">
        {{ formattedPrice }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-sale-table__column sl-sale__currency">
        {{ sale.fk_reference_monnaie }}
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'SaleLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        sale:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        formattedPrice()
        {
            return this.$f(parseFloat(this.sale.display_price).toFixed(2), 'thousand');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__over-refraction-calculator">
                    <div class="lsn-btn lsn-btn--danger" @click="showDeletionConfirmationModal = true">
                        {{ $t('common.remove') }}
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- Delete Order Confirmation Modal -->
    <teleport to="body">
        <delete-order-confirmation-modal
            :is-open="showDeletionConfirmationModal"
            @cancel="showDeletionConfirmationModal = false"
            @remove="remove()"
        />
    </teleport>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField                from '../AbstractField.vue';
import axios                        from '@/axios';
import LensOrderUrlManager          from '../../LensOrderUrlManager';
import DeleteOrderConfirmationModal from '@/views/order/DeleteOrderConfirmationModal.vue';

export default
{
    name:       'DeleteButton',
    components: { DeleteOrderConfirmationModal },
    extends:    AbstractField,
    data()
    {
        return {
            showDeletionConfirmationModal: false,
        };
    },
    methods: {
        remove()
        {
            this.$store.commit('overlay/setVisibility', true);
            this.$store.commit('overlay/setText', this.$t('overlay.deleting_order'));
            this.showDeletionConfirmationModal = false;
            const reference = this.$store.state.lensOrder.adaptations[this.side].reference;
            axios.delete(`/api/order/reference/${reference}`)
                .then(response =>
                {
                    // Force refresh the list of orders waiting for review
                    this.$store.dispatch('orders/fetchWaitingForReview');
                    const lensOrderUrlManager = new LensOrderUrlManager(this.$route);
                    lensOrderUrlManager.reset(this.side);
                    this.$router.replace({ path: lensOrderUrlManager.getPath() });
                })
                .catch(error =>
                {
                    console.log(error);
                })
                .finally(() =>
                {
                    this.$store.commit('overlay/setVisibility', false);
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

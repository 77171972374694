<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="py-4 pl-10">
        <sale-table :sales="deliveryNote.sales" embedded />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import SaleTable from '@/components/sale/SaleTable.vue';

export default
{
    name: 'DeliveryNoteLineDetails',

    components:
    {
        SaleTable,
    },

    props:
    {
        deliveryNote:
        {
            type:     Object,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="postcss">

</style>

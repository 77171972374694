<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table :data="invoices" class="sl-open-invoice-table">
        <!-- Embedded Table Header Caption -->
        <template #subtable-caption>
            <slot name="subtable-caption" />
        </template>

        <!-- Table Header -->
        <template #header>
            <lsn-table-cell class="sl-invoice__date">
                {{ $t('invoicing.table.headers.opening_date') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__reference">
                {{ $t('invoicing.table.headers.reference') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__amount">
                {{ $t('invoicing.table.headers.amount') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__currency">
                {{ $t('invoicing.table.headers.currency') }}
            </lsn-table-cell>
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <open-invoice-line-summary :invoice="item" />
        </template>

        <template #details="{ item }">
            <invoice-line-details :invoice="item" notes-date-field="ouverture" />
        </template>
    </lsn-table>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable               from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell           from '@/components/LsnTable/LsnTableCell.vue';
import OpenInvoiceLineSummary from '@/components/invoice/OpenInvoiceLineSummary.vue';
import InvoiceLineDetails     from '@/components/invoice/InvoiceLineDetails.vue';

export default
{
    name: 'OpenInvoiceTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        OpenInvoiceLineSummary,
        InvoiceLineDetails,
    },

    props:
    {
        invoices:
        {
            type:     Object,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.sl-open-invoice-table
{
    --column-width:       140px;
    --small-column-width: 100px;
}

.lsn-table.lsn-table--openable.sl-open-invoice-table > .lsn-table__header,
.lsn-table.lsn-table--openable.sl-open-invoice-table > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns:
        var(--open-toggle-width)  // chevron >
        var(--column-width)       // date
        var(--column-width)       // reference
        var(--small-column-width) // amount
        var(--small-column-width) // currency
        calc(100% - (2 * var(--column-width) - (2 * var(--small-column-width)))); // empty space
}

.lsn-table:not(.lsn-table--openable).sl-open-invoice-table > .lsn-table__header,
.lsn-table:not(.lsn-table--openable).sl-open-invoice-table > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns:
        var(--column-width)       // date
        var(--column-width)       // reference
        var(--small-column-width) // amount
        var(--small-column-width) // currency
        calc(100% - (2 * var(--column-width) - (2 * var(--small-column-width)))); // empty space
}

.sl-open-invoice-table .sl-invoice__amount
{
    text-align: right;
}
</style>

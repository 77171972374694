<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in" appear>
            <component :is="Component" @initialize="initialize()" />
        </transition>
    </router-view>

    <!-- Mobile Alert Modal -->
    <teleport to="body">
        <mobile-alert
            :is-open="mobileAlertIsOpen"
            @close="mobileAlertIsOpen = false"
        />
    </teleport>

    <!-- Global Ajax Error Messages Modal -->
    <teleport to="body">
        <lsn-modal :is-open="isAjaxErrorModalOpen">
            <div class="sl-prompt sl-prompt--danger">
                <div class="sl-prompt__contents">
                    <div class="sl-prompt__title">
                        {{ $t('common.errors.error') }}
                    </div>

                    <div class="sl-prompt__text">
                        {{ ajaxErrorMessage }}
                    </div>
                </div>

                <div class="sl-prompt__actions">
                    <button class="sl-prompt__button sl-prompt__button--white" @click.stop="$store.dispatch('closeAjaxErrorModal')">
                        {{ $t('common.close') }}
                    </button>
                </div>
            </div>
        </lsn-modal>
    </teleport>

    <!-- Cookie Banner -->
    <div v-if="!$store.state.isCookiesUsageAcknowledged" id="sl-cookies-banner">
        <div>{{ $t('common.cookies_usage_notice') }}</div>

        <div class="lsn-btn lsn-btn--primary-outline" @click="acknowledgeCookiesUsage">
            {{ $t('common.ok') }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { clone, merge }    from 'lodash-es';
import axios               from '@/axios';
import { setI18nLanguage } from '@/i18n';
import MobileAlert         from '@/components/alerts/MobileAlert.vue';

export default
{
    name: 'Sl6Application',

    components:
    {
        MobileAlert,
    },

    data()
    {
        return {
            mobileAlertIsOpen: true, // only visible when screen >= 768px
        };
    },

    computed:
    {
        ajaxErrorMessage()
        {
            return this.$store.state.ajaxErrorMessage;
        },

        isAjaxErrorModalOpen()
        {
            return this.$store.state.ajaxErrorModalOpen;
        },
    },

    created()
    {
        this.setupAxios();

        if(this.$store.state.auth.authenticated)
        {
            this.initialize();
        }
    },

    methods:
    {
        initialize()
        {
            this.$store.dispatch('preInitialize')
                .then(() =>
                {
                    let query = this.$route.query;
                    let params = clone(this.$route.params);
                    let redirectRoute = null;

                    // Set language from connected user (must be set before app initialization)
                    const userLocale = this.$uconfig.get('language');
                    params.lang = userLocale;
                    setI18nLanguage(userLocale);

                    this.selectedEntityId()
                        .then(entityId =>
                        {
                            // A single entity was found
                            this.$store.dispatch('initialize', entityId)
                                .then(() =>
                                {
                                    // Default route after initialization (if no other is given - see below)
                                    let routeName = 'home';

                                    params.entity_id = entityId;

                                    if(query.redirect)
                                    {
                                        redirectRoute = this.$router.resolve(query.redirect);
                                        if(redirectRoute.name === 'entities-switch')
                                        {
                                            // If there's only one entity for the current user,
                                            // don't redirect to the "entities-switch" route.
                                            redirectRoute = null;
                                        }

                                        delete query.redirect;
                                    }

                                    // If a route was found (corresponding to redirection route) and this
                                    // route is not "initializing" (avoid redirection loop).
                                    if(redirectRoute && redirectRoute.name !== 'initializing')
                                    {
                                        routeName = redirectRoute.name;
                                        params = redirectRoute.params;
                                        query = merge({}, query, redirectRoute.query);
                                    }
                                    else if(query.rtename) // If a route was given in the query parameters.
                                    {
                                        routeName = query.rtename;
                                        delete query.rtename;
                                    }

                                    // Navigate to final route.
                                    this.$router.replace(
                                        {
                                            name: routeName,
                                            params,
                                            query,
                                        }
                                    );
                                });
                        })
                        .catch(error =>
                        {
                            if(error instanceof Error)
                            {
                                console.log('Error fetching single entity ID', error);
                            }

                            // No current entity was found => go to entity switch
                            this.$router.replace({
                                name: 'entities-switch',
                                params,
                            });
                        });
                });
        },

        /**
         * Automatically determine which entity should be selected.
         */
        async selectedEntityId()
        {
            let entityId = 0;
            const query = this.$route.query;
            let redirectRoute = null;

            // If a redirect route was given in URL (check if it contains an entity id).
            if(query.redirect)
            {
                redirectRoute = this.$router.resolve(query.redirect);
            }

            if(this.$store.state.account.entitiesCount === 1)
            {
                // There is only one entity => select it by fetching its ID from the API
                const response = await axios.get('/api/entities');
                entityId = response.data[0].id;
            }
            else if(redirectRoute?.params?.entity_id)
            {
                // An entity was given in the redirect route
                entityId = redirectRoute.params.entity_id;
            }
            else if(query.rteentity)
            {
                // An entity was given in the query parameters
                entityId = query.rteentity;
                delete query.rteentity;
            }

            if(entityId)
            {
                return entityId;
            }
            else
            {
                return Promise.reject('No entity selected');
            }
        },

        /**
         * Set up global interceptors for axios.
         */
        setupAxios()
        {
            // Register global ajax error handler
            axios.interceptors.response.use(
                response => response,
                error =>
                {
                    this.handleAjaxError(error);

                    return Promise.reject(error);
                }
            );
        },

        /**
         * Handle ajax errors.
         */
        handleAjaxError(error)
        {
            // Determine the appropriate error message
            if(error.response)
            {
                if(error.response.status)
                {
                    switch(error.response.status)
                    {
                        // Client errors
                        case 400: this.setAjaxErrorMessage(this.$t('common.errors.http.bad_request'));           break;
                        case 401: this.setAjaxErrorMessage(this.$t('common.errors.http.unauthorized'));          break;
                        case 403: this.setAjaxErrorMessage(this.$t('common.errors.http.forbidden'));             break;

                        // Server errors
                        case 500: this.setAjaxErrorMessage(this.$t('common.errors.http.internal_server_error')); break;
                        case 502: this.setAjaxErrorMessage(this.$t('common.errors.http.bad_gateway'));           break;
                        case 504: this.setAjaxErrorMessage(this.$t('common.errors.http.gateway_timeout'));       break;
                    }
                }
                else if(error.response.errors)
                {
                    this.setAjaxErrorMessage(error.response.errors);
                }
                else
                {
                    this.setAjaxErrorMessage(error.message);
                }

                // Log any response data & metadata
                console.log('Ajax error message', error.response.errors);
                console.log('Ajax error meta', error.response.meta);
            }
            else if(error.request)
            {
                if(error.message)
                {
                    this.setAjaxErrorMessage(error.message);
                }
                else
                {
                    // Network error: the user may be offline
                    this.setAjaxErrorMessage(this.$t('common.errors.network_error'));
                }

                // Log any error details
                console.log('Ajax error', JSON.parse(JSON.stringify(error)));
                console.log('Ajax error request', error.request);
            }
            else if(error.message === 'canceled')
            {
                // The axios request was canceled.
                // This is actually not an error, so do nothing.
            }
            else
            {
                // Other unexpected error before the request was even sent
                console.error('Unexpected axios error', { error });
                this.setAjaxErrorMessage(error.message);
            }

            /*
                Commented out because all those popups don't really bring
                anything to the user experience. Quite the opposite, actually:
                they scare the user, even though it's usually nothing serious.
                Plus, we get notified anyway.
             */
            // // Open a modal with the error message
            // this.$store.dispatch('openAjaxErrorModal');
        },

        /**
         * Commit the ajax error message to the global store.
         */
        setAjaxErrorMessage(errorMessage)
        {
            this.$store.dispatch('setAjaxErrorMessage', errorMessage);
        },

        acknowledgeCookiesUsage()
        {
            this.$store.dispatch('setCookiesUsageAcknowledged', true);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
#sl-cookies-banner
{
    @apply fixed bottom-0 flex justify-between items-center w-full p-8 bg-primary-900/60 text-white backdrop-filter backdrop-blur-sm;

    text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    z-index: 99999;

    .lsn-btn
    {
        text-shadow: none;
    }
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Folded Content -->
    <div class="sl-order-form__line sl-order-form__line--unpadded sl-order-form__line-splitted w-full border-t border-gray-200 -mt-px" @click="$emit('editPrototypeSelection')">
        <!-- Right Side -->
        <div class="sl-prototype-label hover:bg-primary-100">
            <!-- Right Prototype -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="rightPrototype" class="text-primary-500 select-none" v-html="rightPrototype.label"></div>

            <!-- No Right Prototype -->
            <div v-else class=" text-gray-500">
                {{ $t('order.lenses.sections.without-right-lens') }}
            </div>
        </div>

        <!-- Label -->
        <div class="w-1/5">
            <label class="sl-order-form__label text-center block">
                {{ $t('order.lenses.sections.prototypes.label') }}
            </label>
        </div>

        <!-- Left Side -->
        <div class="sl-prototype-label hover:bg-primary-100">
            <!-- Left Prototype -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="leftPrototype" class="text-primary-500 select-none" v-html="leftPrototype.label"></div>

            <!-- No Left Prototype -->
            <div v-else class=" text-gray-500">
                {{ $t('order.lenses.sections.without-left-lens') }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'SelectedPrototype',

    emits: ['editPrototypeSelection'],

    computed:
    {
        rightPrototype()
        {
            return this.$store.getters['lensOrder/getPrototype']('right');
        },

        leftPrototype()
        {
            return this.$store.getters['lensOrder/getPrototype']('left');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-prototype-label
{
    min-height: 46px;
    @apply flex items-center justify-center w-2/5 cursor-pointer;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__date">
        {{ monthlySummaryDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__total">
        {{ $f(monthlySummary.total_net, 'decimal:2|thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__currency">
        {{ currency }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__actions" />
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import moment from 'moment';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'OpenMonthlySummaryLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        monthlySummary:
        {
            type:     Object,
            required: true,
        },
    },

    data: () => ({}),

    computed:
    {
        /**
         * The formatted date of the monthly summary.
         * @return {string}
         */
        monthlySummaryDate()
        {
            return moment(this.monthlySummary.insertion).format('DD.MM.YYYY');
        },

        currency()
        {
            return this.$store.state.catalog.articles[0]?.currency_code;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <div id="sl-cleaning-product">
                <!-- Page Header -->
                <div class="sl-page__header">
                    <div class="sl-page__title">
                        {{ $t('cleaning_products.page_title') }}
                    </div>
                </div>

                <!-- Page Content -->
                <div class="sl-page__content text-gray-500">
                    <div class="flex items-center mb-2 text-primary-500 text-lg">
                        <span>
                            {{ $t('cleaning_products.filter_by_intended_use') }}
                        </span>

                        <a
                            v-if="(intendedUseFilters.length) > 0 || productTypeFilter"
                            href="#!"
                            class="cursor-pointer ml-3 text-xs text-blue-500 hover:underline hover:text-blue-600 focus:underline focus:text-blue-600 outline-none transition"
                            @click.stop="resetFilters()"
                        >
                            {{ $t('cleaning_products.clear_filters') }}
                        </a>
                    </div>

                    <!-- Intended Use Filters -->
                    <div class="mb-4 flex flex-wrap gap-3">
                        <a
                            v-for="(cssClass, tag) in intendedUseTags"
                            :key="tag"
                            href="#!"
                            class="sl-article-grid-iu-filter"
                            :class="getIntendedUseFilterClass(tag)"
                            @click.stop.prevent="toggleIntendedUseFilter(tag)"
                        >
                            {{ $store.getters['catalog/getTagLabelByCode'](tag) }}
                        </a>
                    </div>

                    <div class="flex">
                        <!-- Product Filters -->
                        <div class="w-1/6 shrink-0 mr-2">
                            <div class="mb-2 text-primary-500 text-lg">
                                {{ $t('cleaning_products.filter_by_product') }}
                            </div>

                            <div class="-ml-4">
                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.ALL_CLEAN }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.ALL_CLEAN)"
                                >
                                    All Clean
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.ALL_IN_ONE }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.ALL_IN_ONE)"
                                >
                                    All-in-One
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.CLEANFLEX }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.CLEANFLEX)"
                                >
                                    CleanFlex
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.ONE_STEP_T }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.ONE_STEP_T)"
                                >
                                    One Step-T
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.STOREFLEX }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.STOREFLEX)"
                                >
                                    StoreFlex
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.ENZYME }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.ENZYME)"
                                >
                                    Enzyme
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.FRESH }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.FRESH)"
                                >
                                    Fresh
                                </a>

                                <a
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === $const.tags.UNIFRESH }"
                                    @click.prevent.stop="setProductTypeFilter($const.tags.UNIFRESH)"
                                >
                                    Unifresh
                                </a>
                            </div>

                            <!-- Discount Rules Explanation -->
                            <div class="mt-4 mb-2 text-primary-500 text-lg">
                                {{ $t('article_list.discounts.title') }}
                            </div>

                            <p class="mr-6 text-sm">
                                {{ $t('article_list.discounts.intro') }}
                            </p>

                            <div
                                class="cursor-pointer flex items-center text-sm mt-2 transition select-none"
                                :class="{ 'text-primary-500': showOnlyPrepackaged }"
                                @click="showOnlyPrepackaged = !showOnlyPrepackaged"
                            >
                                <mdi-icon
                                    :icon="showOnlyPrepackaged ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                                    class="w-5 h-5 mr-1"
                                />

                                <span>
                                    {{ $t('article_list.discounts.show_boxes_only') }}
                                </span>
                            </div>

                            <div class="mt-2 mr-6 text-sm">
                                <div class="flex flex-wrap items-center border-b border-primary-100 w-full">
                                    <div class="pr-2 py-1 w-2/3 text-primary-500">
                                        {{ $t('article_list.discounts.table.condition') }}
                                    </div>

                                    <div class="pl-2 py-1 w-1/3 text-primary-500 text-right">
                                        {{ $t('article_list.discounts.table.discount') }}
                                    </div>
                                </div>

                                <div
                                    v-for="rule in discountRules"
                                    :key="rule.i18nNum"
                                    class="flex flex-wrap items-center border-b border-primary-100 w-full"
                                >
                                    <div class="pr-2 py-1 w-2/3">
                                        {{ $t('article_list.discounts.condition', { num: rule.num }, rule.i18nNum) }}
                                    </div>

                                    <div class="pl-2 py-1 w-1/3 text-right" :class="{ 'text-gray-400': rule.discount === $t('article_list.discounts.no_discount') }">
                                        {{ rule.discount }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Articles List -->
                        <articles-grid
                            :articles="filteredArticles"
                            :filter-badges-callback="getFilterBadgesCallback()"
                            :badges-callback="getBadgesCallback()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import {
    intendedUseTags,
    getFilterBadgesCallback,
    getBadgesCallback,
} from '@/shared/ArticlesGridSharedObjects';
import constants    from '@/constants/constants';
import ArticlesGrid from '@/components/articles-grid/ArticlesGrid.vue';

export default
{
    name: 'CleaningProductsList',

    components:
    {
        ArticlesGrid,
    },

    data()
    {
        return {
            intendedUseFilters:  [],
            productTypeFilter:   '',
            showOnlyPrepackaged: false,
        };
    },

    computed:
    {
        articles()
        {
            return this.$store.state.catalog.articles
                .filter(a => a.status !== constants.articles.status.INACTIVE && a.tags.some(t => t.code === 'CLEANING_PRODUCT'))
                .sort((a, b) => (a.extra?.sort_order || 0) - (b.extra?.sort_order || 0));
        },

        filteredArticles()
        {
            let articles = this.articles;

            if(this.intendedUseFilters.length)
            {
                articles = articles.filter(a => this.intendedUseFilters.every(iufCode => a.tags.some(t => t.code === iufCode)));
            }

            if(this.productTypeFilter)
            {
                articles = articles.filter(a => a.tags.some(t => t.code === this.productTypeFilter));
            }

            if(this.showOnlyPrepackaged)
            {
                articles = articles.filter(a => a.tags.some(t => t.code === this.$const.tags.PREPACKAGED));
            }

            return articles;
        },

        /**
         * Tags and classes used for "intended use" filters.
         */
        intendedUseTags()
        {
            return intendedUseTags;
        },

        discountRules()
        {
            return [
                {
                    i18nNum:  1,
                    num:      '1',
                    discount: this.$t('article_list.discounts.no_discount'),
                },
                {
                    i18nNum:  2,
                    num:      '2',
                    discount: '2.5%',
                },
                {
                    i18nNum:  3,
                    num:      '3',
                    discount: '5%',
                },
                {
                    i18nNum:  5,
                    num:      '4-5',
                    discount: '7.5%',
                },
                {
                    i18nNum:  7,
                    num:      '6-7',
                    discount: '10%',
                },
                {
                    i18nNum:  9,
                    num:      '8-9',
                    discount: '12.5%',
                },
                {
                    i18nNum:  10,
                    num:      '≥10',
                    discount: '15%',
                },
            ];
        },
    },

    methods:
    {
        getIntendedUseFilterClass(tag)
        {
            return {
                'sl-article-grid-iu-filter--active':                 this.intendedUseFilters.includes(tag),
                ['sl-article-grid-iu-filter--' + tag.toLowerCase()]: true, // e.g. "sl-article-grid-iu-filter--multi_pack"
            };
        },

        getFilterBadgesCallback,
        getBadgesCallback,

        toggleIntendedUseFilter(tag)
        {
            const index = this.intendedUseFilters.indexOf(tag);
            if(index === -1)
            {
                // Add tag to filters
                this.intendedUseFilters.push(tag);
            }
            else
            {
                // Remove tag from filters
                this.intendedUseFilters.splice(index, 1);
            }
        },

        setProductTypeFilter(tag)
        {
            if(tag === this.productTypeFilter)
            {
                this.productTypeFilter = '';
            }
            else
            {
                this.productTypeFilter = tag;
            }
        },

        resetFilters()
        {
            this.intendedUseFilters = [];
            this.productTypeFilter = '';
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

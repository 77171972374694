<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="`sl-order-form__line-side-${side}`">
        <div class="sl-dimmer"></div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'NoField',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-dimmer
{
    @apply absolute inset-y-0 w-2/5 bg-gray-100;
}
</style>

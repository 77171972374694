<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-widget sl-calculator sl-epc" @keydown.esc="$emit('close')">
        <div class="sl-widget__header">
            <div class="sl-widget__title">
                {{ $t('calculators.eyeglass_power_conversion.title') }}
            </div>
        </div>

        <div class="sl-widget__body">
            <div class="grid grid-cols-3 gap-2 justify-center items-center text-center">
                <!-- ---------------------------------------- Calculator Header -->

                <div></div>

                <div>
                    {{ $t('calculators.common.glasses') }}
                </div>

                <div>
                    {{ $t('calculators.common.contact_lens') }}
                </div>


                <!-- ---------------------------------------- Calculator Line 1: Sphere -->

                <div>
                    {{ $t('parameters.LENS_SPHERE.label') }}
                </div>

                <div>
                    <lsn-input
                        ref="autofocus"
                        v-model="glasses.sphere"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-epc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="result.sphere"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-epc__input"
                        disabled
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 2: Cylinder -->

                <div>
                    {{ $t('parameters.LENS_CYLINDER.label') }}
                </div>

                <div>
                    <lsn-input
                        v-model="glasses.cylinder"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-epc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="result.cylinder"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-epc__input"
                        disabled
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 3: Vertex Power -->

                <div>
                    {{ $t('calculators.common.vertex_power') }}
                </div>

                <div>
                    <lsn-input
                        v-model="glasses.vertexPower"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-epc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div></div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="sl-widget__actions">
            <div
                v-if="rightAdaptation"
                class="sl-widget__button"
                :class="attributeButtonClass"
                tabindex="0"
                @click="attribute('right')"
                @keypress.enter="attribute('right')"
            >
                {{ $t('calculators.common.attribute_right') }}
            </div>

            <div
                v-if="leftAdaptation"
                class="sl-widget__button"
                :class="attributeButtonClass"
                tabindex="0"
                @click="attribute('left')"
                @keypress.enter="attribute('left')"
            >
                {{ $t('calculators.common.attribute_left') }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'EyeglassPowerConversion',

    emits: ['close'],

    data()
    {
        return {
            attributing: false,
            glasses:
            {
                sphere:      0.00,
                cylinder:    0.00,
                vertexPower: 14.00,
            },
            result:
            {
                sphere:   0.00,
                cylinder: 0.00,
            },
        };
    },

    computed:
    {
        attributeButtonClass()
        {
            return this.attributing
                ? 'sl-widget__button--disabled'
                : 'sl-widget__button--primary';
        },

        rightAdaptation()
        {
            return this.$store.state.lensOrder.adaptations.right;
        },

        leftAdaptation()
        {
            return this.$store.state.lensOrder.adaptations.left;
        },
    },

    mounted()
    {
        this.$refs.autofocus?.focus();
    },

    methods:
    {
        calculate()
        {
            // Replace commas with periods
            this.glasses.sphere      = `${this.glasses.sphere}`.replace(/,/g, '.');
            this.glasses.cylinder    = `${this.glasses.cylinder}`.replace(/,/g, '.');
            this.glasses.vertexPower = `${this.glasses.vertexPower}`.replace(/,/g, '.');

            // Force negative cylinder values
            this.glasses.cylinder = -Math.abs(parseFloat(this.glasses.cylinder)) || 0.00;

            const cylLun = parseFloat(this.glasses.cylinder) || -0.01;
            const dvo = parseFloat(this.glasses.vertexPower) || 0.00;

            const f1Lun = parseFloat(this.glasses.sphere);
            const f2Lun = f1Lun + cylLun;
            const d = parseInt(dvo) / 1000;

            if(isNaN(f1Lun) || isNaN(f2Lun) || isNaN(d))
            {
                this.result.sphere   = 0;
                this.result.cylinder = 0;

                return;
            }

            let f1Lent;
            let f2Lent;

            if(f1Lun == 0)
            {
                f1Lent = 0;
            }
            else
            {
                const d1Lun = 1 / f1Lun;
                const d1Lent = d1Lun - d;
                f1Lent = 1 / d1Lent;
            }

            if(f2Lun == 0)
            {
                f2Lent = 0;
            }
            else
            {
                const d2Lun = 1 / f2Lun;
                const d2Lent = d2Lun - d;
                f2Lent = 1 / d2Lent;
            }

            this.result.sphere   = f1Lent.toFixed(2);
            this.result.cylinder = (f2Lent - f1Lent).toFixed(2);
        },

        attribute(side)
        {
            if(this.attributing)
            {
                return;
            }

            if(!['right', 'left'].includes(side))
            {
                throw new RangeError(`Invalid value for side: "${side}"`);
            }

            this.attributing = true;

            let adaptation = this[`${side}Adaptation`];

            // Values will be set only if parameter exists.
            adaptation.getPrototype().setValue('LENS_SPHERE', parseFloat(this.result.sphere));
            adaptation.getPrototype().setValue('LENS_CYLINDER', parseFloat(this.result.cylinder));

            this.$store.dispatch('lensOrder/template', { side })
                .then(responses =>
                {
                    this.$emit('close');
                })
                .finally(() =>
                {
                    this.attributing = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-epc :deep(.sl-epc__input input)
{
    @apply text-center;
}
</style>

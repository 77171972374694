/*******************
 * Main Vuex Store *
 ******************/

import axios           from '@/axios';
import constants       from '@/constants/constants';
import Cookie          from '@/utils/Cookie';
import { createStore } from 'vuex';
import AjaxStack       from 'ajaxstack';
import { getInstance as getRouter } from '@/router/router';
import { getApplicationConfigurations } from '@/vue-configurations';

// Modules
import { setupAccountModule       } from './account';
import { setupAuthModule          } from './auth';
import { setupBasketModule        } from './basket';
import setupCalculatorsModule       from './calculators';
import { setupCatalogModule       } from './catalog';
import forms                        from './forms';
import { setupLensOrderModule     } from './lensOrder';
import { setupNotificationsModule } from './notifications';
import { setupOrdersModule        } from './orders';
import { setupParametersModule    } from './parameters';
import { setupPrototypesModule    } from './prototypes';
import overlay                      from './overlay';
import patients                     from './patients';

// Actions called during entity initialization (on entity select/change).
const entityInitializationActions = [
    { action: 'catalog/fetchCatalog' },
    { action: 'forms/fetchAllOfg' },
    { action: 'prototypes/fetchAll', after: ['catalog/fetchCatalog'] },
    { action: 'parameters/fetchGlobalProperties', after: ['prototypes/fetchAll'] },
    { action: 'basket/load', after: ['catalog/fetchCatalog'] },
    { action: 'parameters/fetchAttributes', after: ['prototypes/fetchAll'] },
    { action: 'parameters/fetchTranslations' },
];

const $aconfig = getApplicationConfigurations();

/** @var store The global Vuex store instance. */
let store = null;

export const DEFAULT_MAIN_MENU_ITEM = 'new_order';

export function setupStore(i18n, VueHoneybadger)
{
    if(store !== null)
    {
        throw new Error('The store was already set up');
    }

    store = createStore({

        /**
         * Assign the modules to the store.
         */
        modules:
        {
            account:       setupAccountModule(VueHoneybadger),
            auth:          setupAuthModule(),
            basket:        setupBasketModule(i18n),
            calculators:   setupCalculatorsModule(i18n),
            catalog:       setupCatalogModule(VueHoneybadger),
            forms,
            lensOrder:     setupLensOrderModule(i18n, VueHoneybadger),
            notifications: setupNotificationsModule(i18n),
            orders:        setupOrdersModule(),
            overlay,
            parameters:    setupParametersModule(i18n),
            patients,
            prototypes:    setupPrototypesModule(),
        },


        // ------------------------------------------------------------ STATE

        state()
        {
            // Retrieve persistent state from cookies
            const isCookiesUsageAcknowledged = Cookie.getItem('cookiesUsageAcknowledged') === '1';

            return {
                ajaxErrorMessage:   '',
                ajaxErrorModalOpen: false,
                isCookiesUsageAcknowledged,
                cMainMenuElement:   null,
            };
        },


        // ------------------------------------------------------------ MUTATIONS

        mutations:
        {
            /* ---------- Application configurations ---------- */

            /**
             * Set global application configurations (from app_config table).
             *
             * @param {Object} state
             * @param {Object} configurations
             */
            setApplicationConfigurations(state, configurations)
            {
                $aconfig.initialize(configurations);
            },

            setCookiesUsageAcknowledged(state, acknowledged)
            {
                state.isCookiesUsageAcknowledged = !!acknowledged;
            },

            /* ---------- Ajax Errors ---------- */

            /**
             * Set global ajax error message to be displayed in a modal.
             *
             * @param {Object} state
             * @param {String} ajaxErrorMessage
             */
            setAjaxErrorMessage(state, ajaxErrorMessage)
            {
                state.ajaxErrorMessage = ajaxErrorMessage;
            },

            /**
             * Open or close the ajax error modal message.
             *
             * @param {Object} state
             * @param {Boolean} status `true` to open, `false` to close.
             */
            setAjaxErrorModalStatus(state, status)
            {
                state.ajaxErrorModalOpen = !!status;
            },

            setCurrentMainMenuElement(state, element)
            {
                state.cMainMenuElement = null;
                state.cMainMenuElement = element;
            },
        },


        // ------------------------------------------------------------ ACTIONS

        actions:
        {
            preInitialize({ dispatch })
            {
                const promises = [
                    dispatch('account/fetchConnectedUser'),
                    dispatch('account/fetchUserEntitiesCount'),
                    dispatch('fetchApplicationConfigurations'),
                    // dispatch('auth/autoRefreshAccessToken'),
                ];

                return Promise.all(promises)
                    .then(([connectedUser, entitiesCount, appConfig]) =>
                    {
                        if(entitiesCount > 1 && entitiesCount <= constants.entities.SWITCHER_THRESHOLD)
                        {
                            dispatch('account/fetchUserEntities');
                        }

                        return dispatch('auth/checkIdle');
                    });
            },

            initialize({ dispatch }, eid)
            {
                return dispatch('account/fetchCurrentEntity', eid)
                    .then(entity =>
                    {
                        const initEntityAjaxStack = new AjaxStack(entityInitializationActions, dispatch, { concurrency: 3 });

                        return initEntityAjaxStack.run({});
                    })
                    .catch(error =>
                    {
                        if(error.toJSON?.().status === 403)
                        {
                            // Forbidden entity access => redirect to entity-switch
                            console.log('Forbidden entity access', eid);
                            getRouter().push({ name: 'entities-switch' });
                        }
                        else
                        {
                            VueHoneybadger.notify(error);
                        }
                    });
            },

            onBeforeEntitySwitch({ dispatch, rootState })
            {
                // Clear patients store
                dispatch('patients/clearAll');

                // Remove notifications
                [...rootState.notifications.band, ...rootState.notifications.tray].forEach(notification =>
                {
                    if(notification.keepOnEntitySwitch)
                    {
                        return;
                    }

                    dispatch('notifications/removeBandNotification', notification.id);
                });
            },

            setCookiesUsageAcknowledged({ commit }, acknowledged)
            {
                return new Promise((resolve, reject) =>
                {
                    const expiration = new Date();
                    expiration.setFullYear(expiration.getFullYear() + 1); // cookie expires in 1 year

                    Cookie.setItem('cookiesUsageAcknowledged', '1', expiration, '/');

                    commit('setCookiesUsageAcknowledged', acknowledged);
                    resolve(acknowledged);
                });
            },

            /* ---------- application configurations ---------- */

            fetchApplicationConfigurations({ commit })
            {
                return axios.get('/api/application-configurations')
                    .then(response =>
                    {
                        commit('setApplicationConfigurations', response.data);
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },

            setAjaxErrorMessage({ commit }, message)
            {
                commit('setAjaxErrorMessage', message);
            },

            openAjaxErrorModal({ commit })
            {
                commit('setAjaxErrorModalStatus', true);
            },

            closeAjaxErrorModal({ commit })
            {
                commit('setAjaxErrorModalStatus', false);
            },

            setCurrentMainMenuElement({ commit }, element)
            {
                if(element !== null && !element instanceof HTMLElement)
                {
                    return Promise.reject('Invalid main menu element');
                }

                commit('setCurrentMainMenuElement', element);

                return Promise.resolve(element);
            },
        },
    });

    return store;
};

/**
 * Get the global store instance.
 *
 * @returns {{}} The `store` object.
 * @throws {TypeError} If the store hasn't been initialized with `setupStore()`, yet.
 */
export function getInstance()
{
    if(store === null)
    {
        throw new TypeError('The store has not been set up, yet');
    }

    return store;
};

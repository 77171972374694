<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__lines">
        <transition-group name="list" tag="div">
            <component
                :is="line.component"
                v-for="(line, index) in lines"
                :key="line.code"
                :code="line.code"
                :line="line"
                :index="index + 1"
                @ungroup="ungroup"
            />
        </transition-group>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { sortBy, uniqBy } from 'lodash-es';
import GroupedFormLine    from './GroupedFormLine.vue';
import SplittedFormLine   from './SplittedFormLine.vue';
import HeadingFormLine    from './HeadingFormLine.vue';
import SectionFormLine    from './SectionFormLine.vue';
import Parameter          from '@/models/Parameter';
import Arrays             from '@/utils/Arrays';

const OVER_REFRACTION_CALCULATOR_PCODE = 'OVER_REFRACTION_CALCULATOR';
const CUSTOMER_PRICE = 'CUSTOMER_PRICE';
const SPECIAL_PRICE = 'SPECIAL_PRICE';
const DELETE_BUTTON = 'DELETE_BUTTON';

// hack: Temporarily hide the calculator, until GuC tells us the appropriate changes to make it fit
export const PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR = [
    // ToriFlex
    'TORIFLEX_SA2_BITORIC',
    'TORIFLEX_SA2_FRONTTORIC',

    // ToreliFlex
    'TORELIFLEX_SA2_BITORIC',
    'TORELIFLEX_SA2_FRONTTORIC',

    // ToriFlex Relax
    'TORIFLEXRELAX_SA2_BITORIC',
    'TORIFLEXRELAX_SA2_FRONTTORIC',

    // NightFlex
    'NIGHTFLEX_SYMMETRICAL_MYOPIA',
    'NIGHTFLEX_SYMMETRICAL_HIGH_MYOPIA',
    'NIGHTFLEX_SYMMETRICAL_HYPEROPIA',
    'NIGHTFLEX_SYMMETRICAL_PRESBYOPIA',
    'NIGHTFLEX_SYMMETRICAL_RELAX',
    'NIGHTFLEX_ASYMMETRICAL_MYOPIA',
    'NIGHTFLEX_ASYMMETRICAL_HIGH_MYOPIA',
    'NIGHTFLEX_ASYMMETRICAL_HYPEROPIA',
    'NIGHTFLEX_ASYMMETRICAL_PRESBYOPIA',
    'NIGHTFLEX_ASYMMETRICAL_RELAX',
];

export default
{
    name: 'LensOrderForm',

    data()
    {
        return {
            splitFieldCodes: [], // Fields (codes) that must be split (asked by user)
        };
    },

    computed:
    {
        lines()
        {
            let lines = [];

            const rightPrototype    = this.$store.getters['lensOrder/getPrototype']('right');
            const leftPrototype     = this.$store.getters['lensOrder/getPrototype']('left');
            const allParameters     = []; // all parameters from left & right prototypes (*without* section ones)
            const sectionParameters = []; // all *section* parameters from left & right prototypes

            if(rightPrototype)
            {
                allParameters.push(...rightPrototype.getParametersAsArray());
            }

            if(leftPrototype)
            {
                allParameters.push(...leftPrototype.getParametersAsArray());
            }

            // Keep only not "HIDDEN" parameters, unique by code and sort by parameter's sort order
            const allVisibleParameters = sortBy(
                uniqBy(
                    allParameters.filter(
                        parameter => parameter.form_display !== 'HIDDEN'
                    ),
                    parameter => parameter.code
                ),
                parameter => parameter.sort_order
            );

            // Move all "section" parameters to handle them separately
            sectionParameters.push(...Arrays.removeIf(allVisibleParameters, p => p.section_code !== null));

            // Build "splitted" and "grouped" form lines
            for(const parameter of allVisibleParameters)
            {
                const rightParameter = rightPrototype ? rightPrototype.getParameterByCode(parameter.code) : null;
                const leftParameter  = leftPrototype  ? leftPrototype.getParameterByCode(parameter.code)  : null;
                let component = SplittedFormLine;

                if((rightParameter || leftParameter)?.form_display === 'HEADING')
                {
                    // The current parameter is a heading.

                    component = HeadingFormLine;
                }
                else if(rightParameter && leftParameter && !this.splitFieldCodes.includes(parameter.code))
                {
                    // The current parameter exists on both sides and isn't explicitly split.

                    // Are both sides grouped? (Y=Yes or F=Forced)
                    if((['Y', 'F']).includes(rightParameter.is_grouped) && (['Y', 'F']).includes(leftParameter.is_grouped))
                    {
                        // Group parameters if both the grouping keys and the values are identical
                        const groupingKeysMatch = rightParameter.grouping_key === leftParameter.grouping_key;
                        const valuesMatch = rightParameter.getValue() === leftParameter.getValue();
                        if(groupingKeysMatch && valuesMatch)
                        {
                            component = GroupedFormLine;
                        }
                    }
                }

                lines.push({
                    code:    parameter.code,
                    rightParameter,
                    leftParameter,
                    section: null,
                    component,
                });
            };

            // Build "section" form lines
            let lineSection = null;
            const rightParameters = [];
            const leftParameters  = [];

            const addLine = () => lines.push({
                code:           lineSection,
                rightParameter: rightParameters.slice(),
                leftParameter:  leftParameters.slice(),
                section:        lineSection,
                component:      SectionFormLine,
            });

            for(let i = 0; i < sectionParameters.length; i++)
            {
                const parameter = sectionParameters[i];
                const rightParameter = rightPrototype ? rightPrototype.getParameterByCode(parameter.code) : null;
                const leftParameter  = leftPrototype  ? leftPrototype.getParameterByCode(parameter.code)  : null;

                // Retrieve the current section.
                // Different sections on both sides for the same parameter aren't supported.
                let currentSection = rightParameter?.section_code || leftParameter?.section_code;

                // Ensure the section for the current line is set
                if(lineSection === null)
                {
                    lineSection = currentSection;
                }

                // Add the line, because the section changed
                if(currentSection != lineSection)
                {
                    addLine();

                    // Reset section form line
                    lineSection = currentSection;
                    rightParameters.length = 0;
                    leftParameters.length  = 0;
                }

                // Add the parameters to the current line
                if(rightParameter)
                {
                    rightParameters.push(rightParameter);
                }

                if(leftParameter)
                {
                    leftParameters.push(leftParameter);
                }

                // Add the line, because the last section parameter was added
                if(i === (sectionParameters.length - 1))
                {
                    addLine();
                }
            }

            // Add new line (specific to SL6)
            lines = this.addOverRefractionLine(lines, rightPrototype, leftPrototype);
            lines = this.addPriceLine(lines, rightPrototype, leftPrototype);
            lines = this.addSpecialPriceLine(lines, rightPrototype, leftPrototype);
            lines = this.addDeleteButtonLine(lines, rightPrototype, leftPrototype);

            // Sort all lines
            lines.sort((a, b) => this.getSortOrder(a) - this.getSortOrder(b));

            return lines;
        },

    },

    methods:
    {
        ungroup(code)
        {
            this.splitFieldCodes.push(code);
        },

        getSortOrder(line)
        {
            const rightParameter = Array.isArray(line.rightParameter)
                ? line.rightParameter[0]
                : line.rightParameter;

            const leftParameter = Array.isArray(line.leftParameter)
                ? line.leftParameter[0]
                : line.leftParameter;

            return (rightParameter || leftParameter).sort_order;
        },

        addOverRefractionLine(lines, rightPrototype, leftPrototype)
        {
            // Add a link to the over-refraction calculator as the last form line
            if([rightPrototype?.code, leftPrototype?.code].some(proto => !PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR.includes(proto)))
            {
                const overRefractionParameter = new Parameter({
                    code:                OVER_REFRACTION_CALCULATOR_PCODE,
                    force_defined_value: true,
                    defined_value:       this.$t('calculators.over_refraction.title'),
                    form_display:        'CUSTOM',
                    form_label:          this.$t('calculators.calculators'),
                    has_access:          true,
                    is_enabled:          true,
                    sort_order:          9990,
                    value_type:          null,
                });

                const o = {
                    code:      OVER_REFRACTION_CALCULATOR_PCODE,
                    component: SplittedFormLine,
                };

                if(rightPrototype && !PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR.includes(rightPrototype.code))
                {
                    o.rightParameter = overRefractionParameter;
                }

                if(leftPrototype && !PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR.includes(leftPrototype.code))
                {
                    o.leftParameter = overRefractionParameter;
                }

                if(o.rightParameter || o.leftParameter)
                {
                    lines.push(o);
                }
            }

            return lines;
        },

        addPriceLine(lines, rightPrototype, leftPrototype)
        {
            // Add "price" info when order edition (review)
            if(this.$route.path.includes('edit'))
            {
                const customerPriceParameter = new Parameter({
                    code:                CUSTOMER_PRICE,
                    force_defined_value: false,
                    defined_value:       null,
                    form_display:        'CUSTOM',
                    form_label:          this.$t('parameters.CUSTOMER_PRICE.label'),
                    has_access:          true,
                    is_enabled:          true,
                    sort_order:          9992,
                    value_type:          "float",
                });

                const o = {
                    code:      CUSTOMER_PRICE,
                    component: SplittedFormLine,
                };

                if(rightPrototype)
                {
                    o.rightParameter = customerPriceParameter;
                }

                if(leftPrototype)
                {
                    o.leftParameter = customerPriceParameter;
                }

                if(o.rightParameter || o.leftParameter)
                {
                    lines.push(o);
                }
            }

            return lines;
        },

        addSpecialPriceLine(lines, rightPrototype, leftPrototype)
        {
            // Add "special price" field when order edition (review)
            if(this.$route.path.includes('edit'))
            {
                const specialPriceParameter = new Parameter({
                    code:                SPECIAL_PRICE,
                    force_defined_value: false,
                    defined_value:       null,
                    form_display:        'CUSTOM',
                    form_label:          this.$t('parameters.SPECIAL_PRICE.label'),
                    has_access:          true,
                    is_enabled:          true,
                    sort_order:          9994,
                    value_type:          "float",
                });

                const o = {
                    code:      SPECIAL_PRICE,
                    component: SplittedFormLine,
                };

                if(rightPrototype)
                {
                    o.rightParameter = specialPriceParameter;
                }

                if(leftPrototype)
                {
                    o.leftParameter = specialPriceParameter;
                }

                if(o.rightParameter || o.leftParameter)
                {
                    lines.push(o);
                }
            }

            return lines;
        },

        addDeleteButtonLine(lines, rightPrototype, leftPrototype)
        {
            // Add "special price" field when order edition (review)
            if(this.$route.path.includes('edit'))
            {
                const deleteButtonParameter = new Parameter({
                    code:                DELETE_BUTTON,
                    force_defined_value: false,
                    defined_value:       null,
                    form_display:        'CUSTOM',
                    form_label:          null,
                    has_access:          true,
                    is_enabled:          true,
                    sort_order:          9996,
                    value_type:          null,
                });

                const o = {
                    code:      DELETE_BUTTON,
                    component: SplittedFormLine,
                };

                if(rightPrototype)
                {
                    o.rightParameter = deleteButtonParameter;
                }

                if(leftPrototype)
                {
                    o.leftParameter = deleteButtonParameter;
                }

                if(o.rightParameter || o.leftParameter)
                {
                    lines.push(o);
                }
            }

            return lines;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.list-enter-active, .list-leave-active
{
    transition: all 0.5s ease;
}

.list-enter-to, .list-leave-from
{
    @apply max-h-[1000px] opacity-100;
}

.list-enter-from, .list-leave-to
{
    @apply max-h-0 opacity-0;
}
</style>

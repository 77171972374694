export default class SetLensPairId
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        let uid = Math.floor(Date.now() / 1000) + '' + Math.floor(Math.random() * 10);
        let lensPairId = uid.toString();
        let rightAdaptation = this.v.$store.state.lensOrder.adaptations.right;
        let leftAdaptation = this.v.$store.state.lensOrder.adaptations.left;

        if(rightAdaptation && leftAdaptation)
        {
            console.log(`Lens pair ID ${lensPairId} has been set on both sides`);

            // We add "A" and "B" to lens pair id to distinguish side (necessary when quantity is more than 1)
            rightAdaptation.getPrototype().setValue('LENS_PAIR_ID', lensPairId + 'A');
            leftAdaptation.getPrototype().setValue('LENS_PAIR_ID', lensPairId + 'B');
        }
        else if(rightAdaptation)
        {
            console.log(`Lens pair ID has been reset on right side`);

            rightAdaptation.getPrototype().setValue('LENS_PAIR_ID', null);
        }
        else if(leftAdaptation)
        {
            console.log(`Lens pair ID has been reset on left side`);

            leftAdaptation.getPrototype().setValue('LENS_PAIR_ID', null);
        }

        return true;
    }
}

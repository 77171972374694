<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-modal :is-open="isOpen" class="sl-mobile-alert">
        <div class="sl-prompt sl-prompt--info">
            <div class="sl-prompt__contents">
                <div class="sl-prompt__title text-center">
                    {{ $t('mobile_use.notice.title') }}
                </div>

                <div class="sl-prompt__text text-center">
                    {{ $t('mobile_use.notice.text') }}
                </div>
            </div>

            <div class="sl-prompt__actions">
                <button class="sl-prompt__button sl-prompt__button--white" @click.stop="$emit('close')">
                    {{ $t('common.continue_anyway') }}
                </button>
            </div>
        </div>
    </lsn-modal>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'MobileAlert',

    props:
    {
        isOpen:
        {
            type:     Boolean,
            required: true,
        },
    },

    emits: ['close'],

    data()
    {
        return {};
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.sl-mobile-alert .sl-prompt
{
    width: 300px;
}

// On large screens, hide the component altogether.
@media (min-width: 768px)
{
    .sl-mobile-alert
    {
        display: none;
    }
}
</style>

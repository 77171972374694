<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="card">

        <div class="card-header">
            <div class="card-header__icon-wrapper">
                <mdi-icon icon="mdiBellOutline" class="card-header__icon" />
            </div>
            <div class="card-header__title">
                {{ $t('home.widgets.notifications.title') }}
            </div>
        </div>

        <div v-if="notifications.lentgh" class="w-full text-center">
            <!-- to do -->
        </div>

        <div v-else class="no-notifications">
            {{ $t('home.widgets.notifications.none') }}
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'NotificationsWidget',

    computed:
    {
        notifications()
        {
            return [];
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.card
{
    min-height: 300px;
    @apply w-1/2;
}

.notifications-item
{

}

.no-notifications
{
    @apply w-full mt-8 text-center text-gray-500;
}

</style>

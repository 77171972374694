<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <main-layout>
        <div id="sl-invoicing" class="sl-page w-4/5">

            <!-- Page Header -->
            <div class="sl-page__header">
                <div class="sl-page__title">
                    {{ $t('invoicing.page_title') }}
                </div>
            </div>

            <!-- Page Content -->
            <div class="sl-page__content text-gray-500">

                <!-- Badges -->
                <div v-if="!entityManagedBy" class="sl-page__badges">

                    <!-- Virtual stock / Contingent -->
                    <lsn-badge
                        :caption="(virtualStockType != 1) ? $t('invoicing.badges.virtual_stock.label') : $t('invoicing.badges.contingent.label')"
                        :theme="virtualStockBadgeTheme"
                        thick
                    >
                        <!-- Loader... -->
                        <span v-if="virtualStockLoading" class="block w-full text-center">
                            <img src="/img/loader.gif" alt="..." class="inline h-4">
                        </span>

                        <!-- type 1 = Contingent (old system) | type 2 = Virtual Stock (new system) -->
                        <span v-else-if="virtualStockType > 0">
                            {{ formattedVirtualStock }}
                        </span>

                        <!-- No Virtual Stock -->
                        <span v-else class="capitalize">
                            {{ $t('common.no') }}
                        </span>

                    </lsn-badge>

                    <!-- Payment method -->
                    <template v-if="entityPaymentMethodRaw">
                        <lsn-badge v-if="entityPaymentMethodRaw" :caption="$t('invoicing.badges.payment_method.label')" theme="success" thick>
                            {{ entityPaymentMethod }}
                        </lsn-badge>

                        <!-- Direct debit: Yes/No -->
                        <lsn-badge :caption="$t('invoicing.badges.direct_debit.label')" :theme="directDebitBadgeTheme" class="mr-2 capitalize" thick>
                            {{ entityPaymentMethodRaw === 'DIRECT_DEBIT' ? $t('common.yes') : $t('common.no') }}
                        </lsn-badge>
                    </template>

                    <!-- Amount due -->
                    <lsn-badge v-if="entityPaymentMethodRaw" :caption="$t('invoicing.badges.amount_due.label')" :theme="amountDueBadgeTheme" thick>
                        {{ amountDue }}
                    </lsn-badge>

                    <!-- Paid by / Invoiced to / Who pays -->
                    <lsn-badge v-if="entityPaymentMethodRaw || entityMemberOf" :caption="$t('invoicing.badges.payable_by.label')" theme="warn" thick>
                        <template v-if="entityPaymentMethodRaw">
                            {{ $t('invoicing.badges.payable_by.yourself') }}
                        </template>

                        <template v-else>
                            {{ entityMemberOf.name }}
                        </template>
                    </lsn-badge>

                    <!-- Conflict / Dispute -->
                    <lsn-badge v-if="dispute" :caption="$t('invoicing.badges.dispute.label')" theme="danger" thick />

                </div>

                <!-- ------------------------------------------------------------ SEARCH FIELD -->

                <div v-if="!entityManagedBy" class="sl-search-input__wrapper">
                    <mdi-icon icon="mdiMagnify" />

                    <input
                        ref="searchInput"
                        v-model="searchText"
                        type="text"
                        class="sl-search-input"
                        :placeholder="$t('invoicing.search.placeholder')"
                        autofocus
                        @input="onSearchInput"
                    >

                    <div class="sl-search-input__nb-results">
                        {{ searchResultsCount }}
                    </div>
                </div>

                <!-- ------------------------------------------------------------ INVOICES -->

                <div v-if="!invoicesLoading" class="overflow-hidden">
                    <template v-if="hasInvoices && !entityManagedBy">

                        <!-- ---------------------------------------- SECTION 1: Open Invoices -->

                        <div>
                            <div class="my-4 text-primary-500">
                                {{ $t('invoicing.open_invoices.title') }}
                            </div>

                            <!-- Intro paragraph -->
                            <p v-if="openInvoices.length > 1" class="mb-4 text-sm">
                                {{ $t('invoicing.open_invoices.intro_text_plural') }}
                            </p>
                            <p v-else-if="openInvoices.length === 1" class="mb-4 text-sm">
                                {{ $t('invoicing.open_invoices.intro_text_singular') }}
                            </p>

                            <!-- List of invoices -->
                            <div class="mb-8">
                                <open-invoice-table
                                    v-if="openInvoices.length"
                                    :invoices="openInvoices"
                                    class="sl-invoicing--gray"
                                    openable
                                />

                                <p v-else class="text-sm">
                                    {{ $t('invoicing.open_invoices.intro_text_none') }}
                                </p>
                            </div>
                        </div>

                        <!-- ---------------------------------------- SECTION 2: Due Invoices -->

                        <div>
                            <div class="my-4 text-primary-500">
                                {{ $t('invoicing.due_invoices.title') }}
                            </div>

                            <!-- Intro paragraph -->
                            <p v-if="dueInvoices.length > 1" class="mb-4 text-sm">
                                {{ $t('invoicing.due_invoices.intro_text_plural') }}
                            </p>
                            <p v-else-if="dueInvoices.length === 1" class="mb-4 text-sm">
                                {{ $t('invoicing.due_invoices.intro_text_singular') }}
                            </p>

                            <div class="mb-8">
                                <due-invoice-table
                                    v-if="dueInvoices.length"
                                    :invoices="dueInvoices"
                                    class="sl-invoicing--gray"
                                    openable
                                />

                                <div v-else class="text-sm">
                                    {{ $t('invoicing.due_invoices.intro_text_none') }}
                                </div>
                            </div>
                        </div>

                        <!-- ---------------------------------------- SECTION 3: Virtual Stock Proposals -->

                        <div>
                            <div class="my-4 text-primary-500">
                                {{ $t('invoicing.virtual_stock_proposals.title') }}
                            </div>

                            <!-- Intro paragraph -->
                            <p v-if="virtualStockProposals.length >= 1" class="mb-4 text-sm">
                                {{ $t('invoicing.virtual_stock_proposals.intro_text') }}
                            </p>

                            <div class="mb-8">
                                <virtual-stock-proposal-table
                                    v-if="virtualStockProposals.length"
                                    :virtual-stock-proposals="virtualStockProposals"
                                    class="sl-invoicing--gray"
                                />

                                <div v-else class="text-sm">
                                    {{ $t('invoicing.virtual_stock_proposals.intro_text_none') }}
                                </div>
                            </div>
                        </div>

                        <!-- ---------------------------------------- SECTION 4: Paid Invoices -->

                        <div>
                            <div class="my-4 text-primary-500">
                                {{ $t('invoicing.paid_invoices.title') }}
                            </div>

                            <!-- Intro paragraph -->
                            <p v-if="paidInvoices.length > 1" class="mb-4 text-sm">
                                {{ $t('invoicing.paid_invoices.intro_text_plural') }}
                            </p>
                            <p v-else-if="paidInvoices.length === 1" class="mb-4 text-sm">
                                {{ $t('invoicing.paid_invoices.intro_text_singular') }}
                            </p>

                            <div class="mb-8">
                                <paid-invoice-table
                                    v-if="paidInvoices.length"
                                    :invoices="paidInvoices"
                                    class="sl-invoicing--gray"
                                    openable
                                />

                                <div v-else class="text-sm">
                                    {{ $t('invoicing.paid_invoices.intro_text_none') }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div> <!-- /if !invoicesLoading -->

                <div v-else class="my-4 text-center">
                    <img class="inline" src="/img/loader-5.gif">
                </div>

                <!-- ------------------------------------------------------------ MONTHLY SUMMARIES -->

                <div v-if="!monthlySummariesLoading" class="overflow-hidden">
                    <template v-if="hasMonthlySummaries && !entityManagedBy">
                        <div class="my-4 text-primary-500 text-lg">
                            {{ $t('invoicing.monthly_summaries.title') }}
                        </div>

                        <!-- Intro paragraph -->
                        <p class="mb-8 text-sm">
                            <template v-if="filteredMonthlySummaries.length > 1">
                                {{ $t('invoicing.monthly_summaries.intro_text_plural') }}
                            </template>
                            <template v-else>
                                {{ $t('invoicing.monthly_summaries.intro_text_singular') }}
                            </template>
                        </p>

                        <!-- ---------------------------------------- SECTION 1: "Open" Monthly Summaries -->

                        <template v-if="openMonthlySummaries.length">
                            <div class="my-4 text-primary-400">
                                {{ $t('invoicing.monthly_summaries.open.title') }}
                            </div>

                            <div class="mb-8">
                                <open-monthly-summary-table
                                    :monthly-summaries="openMonthlySummaries"
                                    class="sl-invoicing--gray"
                                    openable
                                />
                            </div>
                        </template>

                        <!-- ---------------------------------------- SECTION 2: "Closed" Monthly Summaries -->

                        <template v-if="closedMonthlySummaries">
                            <div class="my-4 text-primary-400">
                                {{ $t('invoicing.monthly_summaries.closed.title') }}
                            </div>

                            <div class="mb-8">
                                <monthly-summary-table
                                    :monthly-summaries="closedMonthlySummaries"
                                    class="sl-invoicing--gray"
                                    openable
                                />
                            </div>
                        </template>
                    </template>
                </div> <!-- /if !monthlySummariesLoading -->

                <div v-else-if="!invoicesLoading" class="my-4 text-center">
                    <img class="inline" src="/img/loader-5.gif">
                </div>

                <!-- ------------------------------------------------------------ INVOICING MANAGED BY YOUR GROUP -->

                <div v-if="entityManagedBy">
                    <p class="mb-4">
                        {{ $t('invoicing.managed_by.intro_text') }}
                    </p>

                    <p class="mb-4 font-bold">
                        {{ entityManagedBy.name }}
                    </p>

                    <p class="mb-4">
                        {{ $t('invoicing.managed_by.outro_text') }}
                    </p>
                </div>

                <!-- ------------------------------------------------------------ NO INVOICES OR MONTHLY SUMMARIES RETURNED -->

                <div v-else-if="!invoicesLoading && !hasInvoices && !monthlySummariesLoading && !hasMonthlySummaries">
                    <div class="my-4 text-primary-500">
                        {{ $t('invoicing.no_contents.title') }}
                    </div>

                    <p class="mb-4 text-sm">
                        {{ $t('invoicing.no_contents.paragraph') }}
                    </p>
                </div>
            </div> <!-- /.sl-page__content -->
        </div>
    </main-layout>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { debounce }                 from 'lodash-es';
import Fuse                         from 'fuse.js';
import axios                        from '@/axios';
import MainLayout                   from '@/layouts/MainLayout.vue';
import LsnBadge                     from '@/components/LsnBadge/LsnBadge.vue';
import OpenInvoiceTable             from '@/components/invoice/OpenInvoiceTable.vue';
import DueInvoiceTable              from '@/components/invoice/DueInvoiceTable.vue';
import PaidInvoiceTable             from '@/components/invoice/PaidInvoiceTable.vue';
import OpenMonthlySummaryTable      from '@/components/monthly-summary/OpenMonthlySummaryTable.vue';
import MonthlySummaryTable          from '@/components/monthly-summary/MonthlySummaryTable.vue';
import VirtualStockProposalTable   from '@/components/virtual-stock-proposal/VirtualStockProposalTable.vue';

export default
{
    name: 'InvoiceList',

    components:
    {
        MainLayout,
        LsnBadge,
        OpenInvoiceTable,
        DueInvoiceTable,
        PaidInvoiceTable,
        OpenMonthlySummaryTable,
        MonthlySummaryTable,
        VirtualStockProposalTable,
    },

    data()
    {
        return {
            // Invoices
            invoicesLoading:  true,
            invoices:         [],
            filteredInvoices: [],
            invoicesFuse:     null,

            // Virtual Stock Proposals
            virtualStockProposalsLoading:  true,
            virtualStockProposals:         [],
            filteredVirtualStockProposals: [],
            virtualStockProposalsFuse:     null,

            // Monthly summaries
            monthlySummariesLoading:  true,
            monthlySummaries:         [],
            filteredMonthlySummaries: [],
            monthlySummariesFuse:     null,

            // Virtual stock (balance)
            virtualStockLoading: false,
            virtualStockType:    0,
            virtualStockBalance: 0.00,
            searchText:          '',
        };
    },

    computed:
    {
        hasInvoices()
        {
            return this.invoices.length > 0;
        },

        hasMonthlySummaries()
        {
            return this.monthlySummaries.length > 0;
        },

        /**
         * Invoices of the first list on the page.
         * These have not yet been sent to the client and are not yet payable.
         *
         * @return {[]}
         */
        openInvoices()
        {
            return this.filteredInvoices.filter(i => !i.fermeture);
        },

        /**
         * Invoices of the second list on the page.
         * These are already "closed" (finalized) but are still unpaid (or not fully).
         *
         * @return {[]}
         */
        dueInvoices()
        {
            return this.filteredInvoices.filter(i => i.fermeture && !i.acquittement);
        },

        /**
         * Invoices of the third list on the page.
         * These are fully paid.
         *
         * @return {[]}
         */
        paidInvoices()
        {
            return this.filteredInvoices.filter(i => i.acquittement);
        },

        openMonthlySummaries()
        {
            // The `is_open` property is added by CS Invoices.
            // In fact, there's only one monthly summary with this property,
            // because it's artificially created. There's no such thing as an
            // "open" monthly summary, but there *are* delivery & credit notes
            // attached to an open invoice (for a "centrale").
            // The API just returns those, filtering out those that don't
            // belong to the current entity.
            return this.filteredMonthlySummaries.filter(ms => ms.is_open);
        },

        closedMonthlySummaries()
        {
            // Cf. `openMonthlySummaries()`
            return this.filteredMonthlySummaries.filter(ms => !ms.is_open);
        },

        /**
         * The entity that manages the current entity.
         *
         * @return {{}}
         */
        entityManagedBy()
        {
            return this.$store.state.account.cEntity.managed_by;
        },

        /**
         * The parent of the current entity.
         *
         * @return {{}}
         */
        entityMemberOf()
        {
            return this.$store.state.account.cEntity.member_of;
        },

        /**
         * Get the localized payment method of the current entity, or an empty string if it's unknown.
         *
         * @return {String}
         */
        entityPaymentMethod()
        {
            const paymentMethod = this.entityPaymentMethodRaw;

            switch(paymentMethod)
            {
                case 'QR_INVOICE_CH':
                    return this.$t('invoicing.payment_methods.QR_INVOICE_CH.label');

                case 'BANK_TRANSFERT':
                    return this.$t('invoicing.payment_methods.BANK_TRANSFERT.label');

                case 'DIRECT_DEBIT':
                    return this.$t('invoicing.payment_methods.DIRECT_DEBIT.label');

                case 'CONTRASSEGNO':
                    return this.$t('invoicing.payment_methods.CONTRASSEGNO.label');

                case 'CASH':
                    return this.$t('invoicing.payment_methods.CASH.label');

                case 'RD_30_GG_FM':
                    return this.$t('invoicing.payment_methods.RD_30_GG_FM.label');

                case 'RIBA_30_GG_FM':
                    return this.$t('invoicing.payment_methods.RIBA_30_GG_FM.label');

                default:
                    // Return the empty string by default
                    return '';
            }
        },

        /**
         * The raw payment method.
         *
         * @return {String}
         */
        entityPaymentMethodRaw()
        {
            return this.$store.state.account.cEntity.payment_method || '';
        },

        /**
         * Should we display the virtual stock badge in green, blue, red or gray?
         *
         * @return {"success" | ""}
         */
        virtualStockBadgeTheme()
        {
            if(this.virtualStockType === 0)
            {
                return 'disabled';
            }
            else if(this.virtualStockBalance > 0)
            {
                return 'success';
            }
            else if(this.virtualStockBalance < 0)
            {
                return 'danger';
            }
            else
            {
                return ''; // virtual stock balance === 0
            }
        },

        /**
         * Retrieve and format the virtual stock of the current entity.
         *
         * @return {String}
         */
        formattedVirtualStock()
        {
            const currency = this.$store.state.catalog.articles[0]?.currency_code;

            return (currency ? currency + ' ' : '') + this.$f(this.virtualStockBalance, 'decimal:2|thousand');
        },

        /**
         * Should we display the direct debit badge in blue or gray?
         *
         * @return {"" | "disabled"}
         */
        directDebitBadgeTheme()
        {
            return this.entityPaymentMethodRaw === 'DIRECT_DEBIT' ? '' : 'disabled';
        },

        /**
         * Is the current entity in a dispute (`BLOCKED_ORDER`) status?
         *
         * @return {Boolean}
         */
        dispute()
        {
            return this.$store.state.account.cEntity.status === 'BLOCKED_ORDER';
        },

        /**
         * How many invoices and monthly summaries are being displayed.
         *
         * @return {Number}
         */
        searchResultsCount()
        {
            return this.filteredInvoices.length + this.filteredMonthlySummaries.length;
        },

        /**
         * Should we display the "Amount Due" badge in red or gray?
         *
         * @return {"danger" | "disabled"}
         */
        amountDueBadgeTheme()
        {
            return this.dispute ? 'danger' : 'disabled';
        },

        /**
         * Calculate the amount due, based on the sum of open and due invoices.
         *
         * @return {Number}
         */
        amountDue()
        {
            const currency = this.$store.state.catalog.articles[0]?.currency_code;

            // Sum up the balances of due invoices
            const sum = this.dueInvoices.reduce((carry, invoice) => carry + parseFloat(invoice.solde_pre_acquitte), 0);

            return (currency ? currency + ' ' : '') + this.$f(sum, 'decimal:2|thousand');
        },
    },

    created()
    {
        // Register custom ajax error handler
        this.setupAxios();

        // Debounce search functions
        this.searchInvoices         = debounce(this.searchInvoices, 500);
        this.searchMonthlySummaries = debounce(this.searchMonthlySummaries, 500);

        // If this entity isn't managed by another entity
        if(!this.entityManagedBy)
        {
            // Retrieve the lists of invoices, monthly summaries and virtual stock proposals
            this.fetchInvoices();
            this.fetchMonthlySummaries();
            this.fetchVirtualStock();
            this.fetchVirtualStockProposals();
        }
        else
        {
            this.invoicesLoading = false;
            this.monthlySummariesLoading = false;
        }
    },

    mounted()
    {
        // Force focus on the search input
        this.$refs.searchInput?.focus();
    },

    methods:
    {
        /**
         * Register custom ajax error handler.
         */
        setupAxios()
        {
            axios.interceptors.response.use(
                response => response,
                error =>
                {
                    this.handleAjaxError(error);

                    return Promise.reject(error);
                }
            );
        },

        /**
         * Custom ajax error handling.
         */
        handleAjaxError(error)
        {
            // Determine the appropriate error message
            switch(error?.response?.status)
            {
                case 404:
                    this.$store.dispatch('setAjaxErrorMessage', this.$t('common.errors.http.not_found'));
                    break;
            }
        },

        onSearchInput()
        {
            if(this.monthlySummaries.length)
            {
                this.searchMonthlySummaries();
            }
            else
            {
                this.searchInvoices();
            }
        },

        createFuse(collection)
        {
            return new Fuse(collection,
                {
                    shouldSort:     false,
                    findAllMatches: true,
                    keys:
                        [
                            // Invoice reference number
                            'reference',

                            // Delivery notes & Sales
                            'delivery_notes.reference',
                            // 'delivery_notes.sales.id_commande',
                            // 'delivery_notes.sales.prototype',
                            // 'delivery_notes.sales.modeVente',
                            // 'delivery_notes.sales.refPatient',
                            // 'delivery_notes.sales.refCommande',
                            // 'delivery_notes.sales.case1',

                            // Credit notes & Returns
                            'credit_notes.reference',
                            // 'credit_notes.returns.id_commande',
                        ],
                    threshold:      0.0,
                    ignoreLocation: true,
                }
            );
        },

        /**
         * Debounced in `created()`.
         */
        searchInvoices()
        {
            if(!this.searchText.length)
            {
                this.filteredInvoices = this.invoices;
            }
            else
            {
                // Perform the search
                const results = this.invoicesFuse.search(this.searchText);

                // Map results (only keep found items, no search metadata) and return them
                this.filteredInvoices = results.map(r => r.item);
            }
        },

        /**
         * Debounced in `created()`.
         */
        searchMonthlySummaries()
        {
            if(!this.searchText.length)
            {
                this.filteredMonthlySummaries = this.monthlySummaries;
            }
            else
            {
            // Perform the search
                const results = this.monthlySummariesFuse.search(this.searchText);

                // Map results (only keep found items, no search metadata) and return them
                this.filteredMonthlySummaries = results.map(r => r.item);
            }
        },

        /**
         * Retrieve the list of invoices.
         */
        fetchInvoices()
        {
            return new Promise((resolve, reject) =>
            {
                const eid = this.$store.state.account.cEntity.id;

                this.invoicesLoading = true;
                axios.get(`/api/invoice/${eid}`)
                    .then(({ data: invoices }) =>
                    {
                        this.invoices = invoices;
                        this.filteredInvoices = this.invoices;
                        this.invoicesFuse = this.createFuse(this.invoices);
                        resolve(invoices);
                    })
                    .catch(error =>
                    {
                        console.error(error);
                        reject(error);
                    })
                    .then(() =>
                    {
                        this.invoicesLoading = false;
                    });
            });
        },

        /**
         * Retrieve the list of monthly summaries.
         */
        fetchMonthlySummaries()
        {
            return new Promise((resolve, reject) =>
            {
                const eid = this.$store.state.account.cEntity.id;

                this.monthlySummariesLoading = true;
                axios.get(`/api/monthly-summary/${eid}`)
                    .then(({ data: monthlySummaries }) =>
                    {
                        this.monthlySummaries = monthlySummaries;
                        this.filteredMonthlySummaries = this.monthlySummaries;
                        this.monthlySummariesFuse = this.createFuse(this.monthlySummaries);
                        resolve(monthlySummaries);
                    })
                    .catch(error =>
                    {
                        console.error(error);
                        reject(error);
                    })
                    .then(() =>
                    {
                        this.monthlySummariesLoading = false;
                    });
            });
        },

        /**
         * Retrieve the virtual stock of the current entity.
         */
        fetchVirtualStock()
        {
            return new Promise((resolve, reject) =>
            {
                const eid = this.$store.state.account.cEntity.id;

                this.virtualStockLoading = true;
                axios.get(`/api/virtual-stock/${eid}`)
                    .then(({ data }) =>
                    {
                        if(data.type > 0)
                        {
                            this.virtualStockType = data.type;
                            this.virtualStockBalance = parseFloat(data.balance) || 0.00;
                        }
                        else
                        {
                            this.virtualStockType = 0;
                            this.virtualStockBalance = null;
                        }

                        resolve(data);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    })
                    .finally(() =>
                    {
                        this.virtualStockLoading = false;
                    });
            });
        },

        /**
         * Retrieve the list of virtual stock proposals.
         */
        fetchVirtualStockProposals()
        {
            return new Promise((resolve, reject) =>
            {
                const eid = this.$store.state.account.cEntity.id;

                this.virtualStockProposalsLoading = true;
                axios.get(`/api/virtual-stock-proposal/${eid}`)
                    .then(({ data: virtualStockProposals }) =>
                    {
                        this.virtualStockProposals = virtualStockProposals;

                        this.virtualStockProposals = virtualStockProposals;
                        this.filteredVirtualStockProposals = this.virtualStockProposals;
                        //this.virtualStockProposalsFuse = this.createFuse(this.virtualStockProposals);
                        resolve(virtualStockProposals);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    })
                    .finally(() =>
                    {
                        this.virtualStockProposalsLoading = false;
                    });
            });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
#sl-invoicing
{
    /* Badges */
    .sl-page__badges
    {
        .lsn-badge
        {
            @apply mr-4 mb-4;
        }
    }


    /* Search Field */
    .sl-search-input__wrapper
    {
        @apply grid items-center my-4 rounded-full border border-gray-300 text-center overflow-hidden;

        grid-template-columns: 2rem calc(100% - 2rem - 3rem) 3rem;

        > .mdi-icon
        {
            @apply h-4 w-8;

            > svg
            {
                @apply mx-auto w-4;
            }
        }
    }

    .sl-search-input
    {
        @apply pr-3 py-2 text-sm
            focus:outline-none;
    }

    .sl-search-input::placeholder
    {
        @apply text-gray-400 italic;
    }

    .sl-search-input__nb-results
    {
        @apply text-gray-400 text-center text-sm;
    }


    /* Tables */
    .sl-invoicing--gray .lsn-table__header
    {
        @apply text-gray-500;
    }

    .sl-invoicing--gray .lsn-subtable__header
    {
        @apply border-gray-200 bg-gray-100 text-gray-500;
    }

    .sl-invoicing--gray .lsn-table--embedded .lsn-subtable__lines-count
    {
        @apply bg-gray-200 text-gray-400;
    }

    .sl-invoicing--gray .lsn-table--embedded .lsn-table__header,
    .sl-invoicing--gray .lsn-table--embedded .lsn-table__line
    {
        @apply border-gray-200;
    }
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-article-grid__item">
        <!-- Product photo -->
        <img
            v-if="article.photos?.length"
            :src="article.photos[0]"
            alt=""
            class="sl-article-grid__illustration"
        />

        <div v-else class="sl-article-grid__illustration sl-article-grid__illustration--none">
            <img src="/img/camera-off.svg" alt="" />
        </div>

        <!-- Badges for filters -->
        <div v-if="filterBadges.length" class="sl-article-grid__filters">
            <div
                v-for="filterBadge in filterBadges"
                :key="filterBadge.tag"
                class="sl-article-grid__filter"
                :class="filterBadge.class"
            >
                {{ filterBadge.tag }}
            </div>
        </div>

        <!-- Badges for package quantity -->
        <div v-if="badges.length" class="sl-article-grid__badges">
            <div
                v-for="(badge, index) in badges"
                :key="index"
                class="sl-article-grid__badge"
            >
                {{ badge }}
            </div>
        </div>

        <!-- Availability Communication -->
        <div class="sl-article-grid__availability-communication">
            <template v-if="article.extra.availability_communication">
                {{ article.extra.availability_communication }}
            </template>
        </div>

        <!-- General Communication -->
        <div class="sl-article-grid__general-communication">
            <template v-if="article.extra.general_communication">
                {{ article.extra.general_communication }}
            </template>
        </div>

        <!-- Price -->
        <div class="flex justify-between items-center mb-2 w-full">
            <!-- Total Price -->
            <div class="sl-article-grid__total-price">
                {{ getFormattedTotalPrice(article) }}
            </div>

            <!-- Unit Price -->
            <div v-if="!hideUnitPrice" class="sl-article-grid__unit-price">
                {{ getFormattedUnitPrice(article) }}
            </div>
        </div>

        <!-- Footer -->
        <div class="sl-article-grid__footer">
            <div class="sl-article-grid__footer-1">
                <!-- Label -->
                <div class="sl-article-grid__label">
                    {{ article.label }}
                </div>

                <!-- "Add to Basket" button -->
                <div class="sl-article-grid__actions">
                    <div class="relative">
                        <div v-if="getBasketQuantity(article)" class="sl-article-grid__basket-quantity">
                            {{ getBasketQuantity(article) }}
                        </div>

                        <mdi-icon
                            v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                            :content="basketDisabled ? article.extra.availability_communication : $t('common.add_to_basket')"
                            icon="mdiBasket"
                            class="sl-article-grid__basket"
                            :class="{ 'sl-article-grid__basket--loading': addingToBasket, 'sl-article-grid__basket--disabled': basketDisabled }"
                            @click.stop="addToBasket(article)"
                        />
                    </div>
                </div>
            </div>

            <!-- Description (if any) -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="article.description" class="sl-article-grid__description" v-html="nl2br(article.description)"></div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { piecesTagRegex } from '@/shared/ArticlesGridSharedObjects';

export default
{
    name: 'ArticleGridItem',

    props:
    {
        article:
        {
            type:     Object,
            required: true,
        },

        filterBadges:
        {
            type:    Array,
            default: () => [],
        },

        badges:
        {
            type:    Array,
            default: () => [],
        },

        hideUnitPrice:
        {
            type:    Boolean,
            default: false,
        },
    },

    data()
    {
        return {
            addingToBasket: false,
        };
    },

    computed:
    {
        /**
         * Disable adding to basket if there's a special "availability communication"
         */
        basketDisabled()
        {
            // Never disable basket for SL staff
            if(this.$store.getters['account/isStaff'])
            {
                return false;
            }

            return !!this.article.extra.availability_communication;
        },
    },

    methods:
    {
        getPhotoSrc(article)
        {
            return article.photos?.length ? article.photos[0] : '/img/camera-off.svg';
        },

        getFormattedUnitPrice(article)
        {
            const units = this.getUnitsFromTags(article);
            const unitPrice = parseFloat(article.base_price / units).toFixed(2);
            const formattedPrice = article.currency_code + ' ' + this.$f(unitPrice, 'thousand');

            return this.$t('cleaning_products.price_per_unit', { price: formattedPrice });
        },

        getFormattedTotalPrice(article)
        {
            const price = parseFloat(article.base_price).toFixed(2);

            return article.currency_code + ' ' + this.$f(price, 'thousand');
        },

        /**
         * Retrieve how many units are sold as one article.
         */
        getUnitsFromTags(article)
        {
            const tag = article.tags.find(t => piecesTagRegex.test(t.code));

            return parseInt(tag?.code.replace(piecesTagRegex, '$1')) || 1;
        },

        getBasketQuantity(article)
        {
            const basketLines = this.$store.getters['basket/getLinesByArticleCode'](article.code);
            const initialValue = 0;

            return basketLines.reduce(
                (previousValue, line) => previousValue + line.quantity,
                initialValue
            );
        },

        /**
         * Replace new lines with `<br>` tags in a string
         *
         * @param {string} str
         * @returns {string}
         */
        nl2br(str)
        {
            return `${str}`.replace(/\r\n|\r|\n/g, '<br>');
        },

        addToBasket(article)
        {
            // Disallow adding to basket if there's a special "availability communication"
            if(this.basketDisabled)
            {
                return;
            }

            // Only add to basket one piece at a time
            if(this.addingToBasket)
            {
                return;
            }

            this.addingToBasket = true;

            const basketLine = {
                article_code: article.code,
                quantity:     1,
                data:         null,
            };

            this.$store.dispatch('basket/saveLines', basketLine)
                .then(lines =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // TODO: Display error
                })
                .then(() =>
                {
                    this.addingToBasket = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-ofg__tooltip">
        <div class="sl-ofg__tooltip-title">
            {{ parameter.form_label }}
        </div>

        <!-- Min / Max / Step -->
        <!-- i18n: labels -->
        <!-- <div v-if="[parameter.min, parameter.max, parameter.step].some(p => typeof p !== 'undefined' && p !== null)" class="sl-ofg__min-max-step">
            <template v-if="typeof parameter.min !== 'undefined' && parameter.min !== null">
                Min:

                <span class="sl-ofg__value">{{ parameter.min }}</span>
            </template>

            <template v-if="typeof parameter.max !== 'undefined' && parameter.max !== null">
                Max:

                <span class="sl-ofg__value">{{ parameter.max }}</span>
            </template>

            <template v-if="typeof parameter.step !== 'undefined' && parameter.step !== null">
                Step:

                <span class="sl-ofg__value">{{ parameter.step }}</span>
            </template>
        </div> -->

        <!-- Unit -->
        <div v-if="parameter.unit !== null" class="sl-ofg__unit">
            {{ $t('ofg.unit.label') }}

            <span class="sl-ofg__value">
                {{ parameter.unit }}
            </span>
        </div>

        <!-- Default Value -->
        <div v-if="parameter.default_value !== null" class="sl-ofg__default-value">
            {{ $t('ofg.default_value.label') }}

            <span class="sl-ofg__value">
                {{ getOptionTranslation(parameter.default_value) }}
            </span>
        </div>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="parameter.note" class="sl-ofg__tooltip-text" v-html="breakLines(parameter.note)"></div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'OfgFieldTooltipContent',

    props:
    {
        parameter:
        {
            type:     Object,
            required: true,
        },
    },

    methods:
    {
        breakLines(text)
        {
            return '<p>' + text.replace(/\r\n|\r|\n/g, '</p><p>') + '</p>';
        },

        getOptionTranslation(ovalue)
        {
            if(isNaN(ovalue)) // don't try to translate numbers
            {
                const tkey = `parameters.${this.parameter.code}.values.${ovalue}.label`;
                const translation = this.$t(tkey);
                if(translation !== tkey)
                {
                    return translation;
                }
            }

            return ovalue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-ofg__tooltip
{
    @apply px-2 py-3 text-gray-600 text-sm;
}

.sl-ofg__tooltip-title
{
    @apply text-primary-600 text-lg font-thin;
}

.sl-ofg__min-max-step
{
    @apply text-gray-500 text-xs;
}

.sl-ofg__unit
{
    @apply text-gray-500 text-xs;
}

.sl-ofg__default-value
{
    @apply text-gray-500 text-xs;
}

.sl-ofg__min-max-step + .sl-ofg__default-value
{
    @apply mt-1;
}

.sl-ofg__unit + .sl-ofg__default-value
{
    @apply mt-1;
}

span.sl-ofg__value
{
    @apply inline-block ml-0.5 mr-1 rounded border border-gray-300 px-1 text-primary-600;
}

.sl-ofg__tooltip-text
{
    @apply mt-4 space-y-1 leading-snug;
}
</style>

import axios from '@/axios';
import Order from '@/models/Order';

export default class FetchPreviousOrderByReference
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;

        // Only if there is an order reference in URL.
        if(lensOrderUrlManager.hasOrderReference(this.side))
        {
            console.log(`There is an order reference in URL for ${this.side} side`);

            const urlOrderReference = lensOrderUrlManager.getOrderReference(this.side);
            const previousOrder = this.v.sides[this.side].previousOrder;

            // Check that URL order reference is different from current previous order reference
            if(!previousOrder || urlOrderReference != previousOrder.reference)
            {
                console.log(`Order reference is set or has changed in URL for ${this.side} side`);

                return axios.get(`/api/order/reference/${urlOrderReference}`)
                    .then(response =>
                    {
                        let order = response.data;

                        // If an order is returned but there is no prototype code, order is not a lens.
                        if(!order.article_data.hasOwnProperty('PROTOTYPE_CODE'))
                        {
                            this.v.sides[this.side].error = this.v.$t('order.renewal_form.error.not_a_lens_order');

                            console.log(`Given order reference is not a lens order on ${this.side} side`);

                            return Promise.reject(`Given order reference is not a lens order on ${this.side} side`);
                        }

                        this.v.sides[this.side].previousOrder = new Order(response.data);

                        console.log(`Previous order on ${this.side} side has been set`);

                        return Promise.resolve(response);
                    })
                    .catch(error =>
                    {
                        this.v.sides[this.side].previousOrder = null;

                        return Promise.reject(error);
                    });
            }
            else
            {
                console.log(`Order reference in URL for ${this.side} side is the same as in last dispatch`);
            }
        }
        else
        {
            console.log(`No order reference in URL for ${this.side} side`);
        }

        return true;
    }
}

export const NOTIFICATION_THEMES = [
    'info',
    'warn',
    'danger',
    'success',
    'disabled',
];

export const NOTIFICATION_TARGETS = [
    'band',
    'tray',
];

/**
 * @var {Number} nextId The generated ID of the next notification.
 */
let nextId = Math.round(Math.random() * 9999 + 1000); // arbitrarily big first notification ID

export default class Notification
{
    id;
    theme;
    title;
    text;
    target;
    dismissible;
    keepOnEntitySwitch;

    constructor({ id, theme = '', title = '', text = '', target = 'band', dismissible = false, keepOnEntitySwitch = false })
    {
        // Give the notification a unique ID (if not specified)
        if(!id)
        {
            id = `${target}_${nextId++}`;
        }

        // Default theme for the notification
        if(!NOTIFICATION_THEMES.includes(theme))
        {
            theme = 'info';
        }

        this.id                 = id;
        this.theme              = theme;
        this.title              = (title + '').trim();
        this.text               = (text  + '').trim();
        this.target             = target;
        this.dismissible        = !!dismissible;
        this.keepOnEntitySwitch = !!keepOnEntitySwitch;
    }
};

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Field-Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Field-Wrapper -->
            <div class="sl-order-form__field-wrapper sl-order-form__field-wrapper--fixed-width">
                <!-- Field -->
                <input
                    v-model.lazy="tempValue"
                    class="sl-order-form__input"
                    type="text"
                    :name="name"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    @focus="onFocus"
                    @blur="onBlur"
                >
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'InputTextField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.modelValue;
                }
                else
                {
                    // @todo Must return formatted values.
                    return this.modelValue;
                }
            },
            set(value)
            {
                if(value === null || value === this.value)
                {
                    return;
                }

                this.$emit('update:modelValue', value);
            },
        },

        helper()
        {
            return null;
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__input:focus
{
    @apply outline-none border-primary-400;
}
</style>

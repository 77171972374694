<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">

        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">

            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__info px-4">
                    {{ displayedValue }}
                </div>
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import AbstractField from '../AbstractField.vue';

export default
{
    name: 'CustomerPrice',

    extends: AbstractField,

    computed:
    {
        adaptation()
        {
            return this.$store.state.lensOrder.adaptations[this.side];
        },

        displayedValue()
        {
            return this.$f(this.adaptation.customer_price, 'decimal:2') + ' ' + this.adaptation.currency_code;
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Content Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Field-Wrapper -->
            <div class="sl-order-form__field-wrapper relative">
                <!-- Number Input Field -->
                <input
                    v-model.lazy="tempValue"
                    class="sl-order-form__input"
                    type="text"
                    :name="name"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    @focus="onFocus"
                    @blur="onBlur"
                >

                <!-- Selector Field -->
                <div
                    ref="options"
                    class="sl-order-form__selector"
                    :class="{ 'sl-order-form__selector--visible': isFocused, 'right-0': side === 'right', 'left-0': side === 'left' }"
                >
                    <div
                        v-for="item in parameter.suggested_items"
                        :key="item.ovalue"
                        class="sl-order-form__option"
                        :class="{ 'pointer-events-none': item.disabled, 'sl-order-form__option--selected': isSelectedValue(item.ovalue) }"
                        @click="selectOption(item.ovalue)"
                    >
                        <!-- Help -->
                        <div
                            v-if="item.help"
                            v-tippy="{ animation : 'fade' }"
                            class="sl-order-form__option-helper"
                            :content="item.help"
                        >
                            <mdi-icon icon="mdiInformationOutline" class="mr-1 w-4 h-4 text-gray-500" />
                        </div>

                        {{ item.label }}
                    </div>
                </div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { toNumber } from 'lodash-es';
import AbstractField from './AbstractField.vue';

export default
{
    name: 'OpenSelectorField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isFocused: false,
            isTouched: false,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.modelValue;
                }
                else
                {
                    // @todo return formatted values (cf. InputNumberField.vue)
                    return this.modelValue;
                }
            },

            set(value)
            {
                if(value === null || value === this.value || value === '-' || value === '+')
                {
                    return;
                }

                if(typeof value === 'string')
                {
                    value = value
                        .replace(',', '.')
                        .replace(/[^0-9.-]/g, '');
                }

                value = (value !== '') ? toNumber(value) : null;

                this.$emit('update:modelValue', value);
            },
        },

        helper()
        {
            const { min, max, step } = this.parameter;
            if(min === null || max === null || step === null)
            {
                return null;
            }

            return this.max2decimals(min)
                + '&nbsp;&rarr;&nbsp;'
                + this.max2decimals(max)
                + '<br>&plusmn;&nbsp;'
                + this.max2decimals(step);
        },

        cIndex()
        {
            for(let i = 0; i < this.suggested_items.length; i++)
            {
                if(this.suggested_items[i].ovalue == this.modelValue)
                {
                    return i + 0.5;
                }
            }

            return 0.5;
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },

        selectOption(value)
        {
            this.$emit('update:modelValue', value);
        },

        isSelectedValue(value)
        {
            return this.modelValue == value;
        },

        selectPrevious()
        {
            // @todo
        },

        selectNext()
        {
            // @todo
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__input
{
    @apply w-16
        focus:outline-none focus:border-primary-400;
}

.sl-order-form__selector
{
    @apply absolute top-full z-0 mt-1 rounded-sm shadow-md p-1 bg-white text-sm leading-none text-gray-600 opacity-0 transition-all;
}

.sl-order-form__selector--visible
{
    @apply z-20 opacity-100;
}

.sl-order-form__option
{
    @apply cursor-pointer flex justify-between items-center p-2 rounded-sm text-right transition
        hover:bg-primary-100 hover:text-primary-600;
}

.sl-order-form__option--selected
{
    @apply border border-primary-300 bg-primary-10 text-primary-500
        hover:border-primary-400;
}
</style>

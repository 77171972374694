export default Object.freeze(
    {
        type: Object.freeze(
            {
                ARTICLE:    'ARTICLE',
                SUPPLEMENT: 'SUPPLEMENT',
                PACK:       'PACK',
            }
        ),

        /**
         * The status of an article.
         */
        status: Object.freeze(
            {
                ACTIVE:       'ACTIVE',
                REORDER_ONLY: 'REORDER_ONLY',
                INACTIVE:     'INACTIVE',
            }
        ),
    }
);

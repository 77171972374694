import Adaptation from "@/models/Adaptation";

export default class CreateAdaptationIfNotExists
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        let lensOrderUrlManager = this.v.lensOrderUrlManager;

        // If URL has nothing for given site (no reference, SN or prototype code) => reset side.
        if(lensOrderUrlManager.hasPrototypeCode(this.side))
        {
            let adaptation = this.v.$store.state.lensOrder.adaptations[this.side];

            // If there is no adaptation object for this side, create one.
            if(!adaptation)
            {
                // Mutation
                this.v.$store.commit('lensOrder/setAdaptation', {
                    side:       this.side,
                    adaptation: new Adaptation(),
                });

                console.log(`Adaptation instance was created on ${this.side} side.`);
            }
        }

        return true;
    }
}

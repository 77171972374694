<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Re-order: side inconsistency warning -->
    <lsn-modal :is-open="isOpen">
        <div class="sl-prompt sl-prompt--info sl-choose-patient-modal">
            <div class="sl-prompt__contents">
                <div class="sl-prompt__title">
                    {{ $t('order.choose_side_modal.title') }}
                </div>

                <div class="sl-prompt__text">
                    <div class="mb-4 leading-5 text-gray-500">
                        {{ $t('order.choose_side_modal.text') }}
                    </div>

                    <div class="flex mb-8">
                        <!-- Right side -->
                        <div v-if="sides.right.previousOrder" class="grow w-1/2 p-4 mr-4 border border-primary-200 rounded-xl">
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-xl text-primary-500">
                                    {{ $t('common.right-eye') }}
                                </div>

                                <div class="mb-3 text-sm">
                                    <span class="text-sm text-gray-500">{{ $t('common.sn_colon') }}</span>
                                    <span class="text-sm text-primary-500 tracking-wider">&nbsp;{{ rightLensSerialNumber }}</span>
                                </div>

                                <div class="mb-2 text-sm text-gray-500">
                                    <span class="text-sm text-gray-500">{{ $t('order.choose_side_modal.lens_pairing') }} : </span>
                                    <span class="text-sm text-primary-500 tracking-wider">{{ rightSideLensSide }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- Left side -->
                        <div v-if="sides.left.previousOrder" class="grow w-1/2 p-4 ml-4 border border-primary-200 rounded-xl">
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-xl text-primary-500">
                                    {{ $t('common.left-eye') }}
                                </div>

                                <div class="mb-3 text-sm text-primary-500">
                                    <span class="text-sm text-gray-500">{{ $t('common.sn_colon') }}</span>
                                    <span class="text-sm text-primary-500 tracking-wider">&nbsp;{{ leftLensSerialNumber }}</span>
                                </div>

                                <div class="mb-2 text-sm text-gray-500">
                                    <span class="text-sm text-gray-500">{{ $t('order.choose_side_modal.lens_pairing') }} : </span>
                                    <span class="text-sm text-primary-500 tracking-wider">{{ leftSideLensSide }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Action Buttons -->
                    <div class="flex justify-around">
                        <div class="lsn-btn lsn-btn--primary w-48" @click="next()">
                            {{ $t('common.continue') }}
                        </div>
                        <div class="lsn-btn lsn-btn--primary w-48" @click="invert()">
                            {{ $t('order.choose_side_modal.invert_btn') }}
                        </div>
                    </div>

                </div>
            </div>

            <!-- Modal Buttons -->
            <div class="sl-prompt__actions">
                <button class="sl-prompt__button sl-prompt__button--white" @click="close">
                    {{ $t('common.cancel') }}
                </button>
            </div>
        </div>
    </lsn-modal>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'ChooseSideModal',

    props:
    {
        isOpen:
        {
            type:    Boolean,
            default: false,
        },
        sides:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['close', 'next', 'invert'],

    computed:
    {
        rightLensSerialNumber()
        {
            return this.sides.right.previousOrder?.article_data?.LENS_SERIAL_NUMBER;
        },

        leftLensSerialNumber()
        {
            return this.sides.left.previousOrder?.article_data?.LENS_SERIAL_NUMBER;
        },

        rightSideLensSide()
        {
            let transKey = 'common.' + this.sides.right.previousOrder?.article_data?.LENS_SIDE;

            return this.$t(transKey);
        },

        leftSideLensSide()
        {
            let transKey = 'common.' + this.sides.left.previousOrder?.article_data?.LENS_SIDE;

            return this.$t(transKey);
        },
    },

    methods:
    {
        invert()
        {
            this.$emit('invert', 'SilentlySetPrototypeCodesInUrl');

            this.close();
        },

        next()
        {
            this.$emit('next', 'SilentlySetPrototypeCodesInUrl');

            this.close();
        },

        close()
        {
            this.$emit('close');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-choose-patient-modal
{
    width: 550px;
}
</style>

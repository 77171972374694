<template>
    <lsn-table :data="creditNotes" class="sl-credit-notes" openable>
        <template #subtable-caption>
            {{ $t("invoicing.credit_notes.table.title") }}
        </template>

        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-credit-note__date">
                {{ $t("invoicing.table.headers.date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-credit-note__reference">
                {{ $t("invoicing.table.headers.reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-credit-note__price">
                {{ $t("invoicing.table.headers.amount") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-credit-note__currency">
                {{ $t("invoicing.table.headers.currency") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-credit-note__actions" />
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <credit-note-line-summary :credit-note="item" :date-field="dateField" />
        </template>

        <template #details="{ item }">
            <credit-note-line-details :credit-note="item" />
        </template>
    </lsn-table>
</template>

<script>
import LsnTable from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import CreditNoteLineSummary from '@/components/credit-note/CreditNoteLineSummary.vue';
import CreditNoteLineDetails from '@/components/credit-note/CreditNoteLineDetails.vue';

export default{
    name: "CreditNoteTable",

    components:
    {
        LsnTable,
        LsnTableCell,
        CreditNoteLineSummary,
        CreditNoteLineDetails,
    },

    props:
    {
        creditNotes:
        {
            type:     Array,
            required: true,
        },

        /**
         * Which property of the credit notes to use for the `date` column.
         */
        dateField:
        {
            type:    String,
            default: "fermeture",
        },
    },
};
</script>

<style lang="postcss">
.sl-credit-notes
{
    --column-width: 100px;
}

.lsn-table.lsn-table--openable.sl-credit-notes > .lsn-table__header,
.lsn-table.lsn-table--openable.sl-credit-notes > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns: var(--open-toggle-width) repeat(4, var(--column-width)) calc(100% - var(--open-toggle-width) - (4 * var(--column-width)));
}

.lsn-table:not(.lsn-table--openable).sl-credit-notes > .lsn-table__header,
.lsn-table:not(.lsn-table--openable).sl-credit-notes > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns: repeat(4, var(--column-width)) calc(100% - (4 * var(--column-width)));
}

.sl-credit-note__price,
.sl-credit-note__actions
{
    @apply text-right;
}

.sl-credit-note__currency
{
    @apply text-center;
}
</style>

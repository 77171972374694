<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="hidden"></div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OfgAbstractField       from './OfgAbstractField.vue';

export default
{
    name: 'OfgHiddenField',

    extends: OfgAbstractField,
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

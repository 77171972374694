<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Re-order: not same entity modal warning -->
    <lsn-modal :is-open="isOpen">
        <div v-if="!loading" class="sl-prompt sl-prompt--info sl-choose-entity-modal" :class="{ 'sl-choose-entity-modal--wider': entities.length > 2 }">
            <!-- Content -->
            <div class="sl-prompt__contents">
                <div class="sl-prompt__title">
                    {{ $t('order.choose_entity_modal.title') }}
                </div>

                <div class="sl-prompt__text">
                    <div class="mb-4 leading-6 text-gray-500">
                        {{ $t('order.choose_entity_modal.text') }}
                    </div>

                    <!-- Entities -->
                    <div class="grid gap-4" :class="'grid-cols-' + entities.length">
                        <div
                            v-for="entity in entities"
                            :key="entity.id"
                            class="grow p-4 border border-primary-200 rounded-xl cursor-pointer transition hover:bg-primary-10 hover:border-primary-300"
                            @click="next(entity.id)"
                        >
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-sm text-primary-500">
                                    {{ $t('common.sn_colon') }}
                                    {{ sides.right.lensSn }}
                                </div>

                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <div class="mb-3 text-sm text-gray-500" v-html="$f(getDeliveryAddress(entity), 'address')" />

                                <div class="grow">
                                    <!-- Align customer number vertically -->
                                </div>

                                <div class="text-sm text-gray-500">
                                    {{ $t('order.choose_entity_modal.customer_number') }}
                                    {{ entity.ext_id }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Buttons -->
            <div class="sl-prompt__actions">
                <button class="sl-prompt__button sl-prompt__button--white" @click="close">
                    {{ $t('common.cancel') }}
                </button>
            </div>
        </div>
    </lsn-modal>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import axios from '@/axios';

export default
{
    name: 'ChooseEntityModal',

    props:
    {
        isOpen:
        {
            type:    Boolean,
            default: false,
        },
        sides:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['close', 'next'],

    data()
    {
        return {
            loading:  true,
            entities: [],
        };
    },

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },
    },

    watch:
    {
        isOpen(isOpen)
        {
            if(isOpen)
            {
                this.loading = true;

                const requests = [];

                this.entities.push(this.cEntity);

                if(this.sides.right.previousOrder && this.sides.right.previousOrder.order_by != this.cEntity.id)
                {
                    requests.push(axios.get('/api/entity/' + this.sides.right.previousOrder.order_by)
                        .then(response =>
                        {
                            this.entities.push(response.data);

                            return Promise.resolve(response);
                        }));
                }

                if(this.sides.left.previousOrder && this.sides.left.previousOrder.order_by != this.cEntity.id && this.sides.left.previousOrder.order_by != this.sides.right.previousOrder?.order_by)
                {
                    requests.push(axios.get('/api/entity/' + this.sides.left.previousOrder.order_by)
                        .then(response =>
                        {
                            this.entities.push(response.data);

                            return Promise.resolve(response);
                        }));
                }

                Promise.all(requests)
                    .then(() =>
                    {
                        this.loading = false;
                    });
            }
            else
            {
                this.entities = [];
            }
        },
    },

    methods:
    {
        fetchEntity(eid)
        {
            const url = '/api/entity/:eid'
                .replace(':eid', eid);

            return axios.get(url);
        },

        getDeliveryAddress(entity)
        {
            return entity.addresses.find(a => a.used_for === 'SHIPPING');
        },

        next(entityId)
        {
            const cEntity = this.$store.state.account.cEntity;

            if(cEntity.id == entityId)
            {
                this.$emit('next', 'ValidatePreviousOrdersPatientsConsistency');
            }
            else
            {
                const path = this.$route.path.replace(cEntity, entityId);

                this.$router.push({ name: 'initializing' });

                this.$store.dispatch('initialize', entityId)
                    .then(() =>
                    {
                        this.$router.replace({
                            path,
                            query: {
                                force_current_entity: 1,
                            },
                        });
                    });
            }

            this.close();
        },

        close()
        {
            this.$emit('close');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-choose-entity-modal
{
    width: 550px;
}

.sl-choose-entity-modal--wider
{
    width: 650px;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="py-4 pl-10">
        <return-table :returns="creditNote.returns" embedded />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ReturnTable from '@/components/return/ReturnTable.vue';

export default
{
    name: 'CreditNoteLineDetails',

    components:
    {
        ReturnTable,
    },

    props:
    {
        creditNote:
        {
            type:     Object,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

/**
 * Custom Vue directive to hide an element based on the current user's role (if he is staff).
 */
export default(el, binding) =>
{
    if(binding.instance.$store.getters['account/isStaff'])
    {
        // Element is shown. Do nothing :-)
    }
    else
    {
        // Remove element from DOM
        el.remove();
    }
};

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <!-- SL Header -->
        <div class="sl-header">
            <div class="sl-header__title">
                {{ cEntity.name }}
            </div>
        </div>

        <!-- Side Menu -->
        <div class="sl-side-menu hidden lg:block">
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'price-list', params: $route.params }" custom>
                <div class="sl-side-menu__item" :class=" { 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('side_menu.price_list') }}
                </div>
            </router-link>

            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'order-list', params: $route.params }" custom>
                <div class="sl-side-menu__item" :class=" { 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('side_menu.orders') }}
                </div>
            </router-link>

            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'invoice-list', params: $route.params }" custom>
                <div v-can="{ permission: 'invoice:view' }" class="sl-side-menu__item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t("side_menu.invoicing") }}
                </div>
            </router-link>

            <div class="sl-side-menu__separator"></div>

            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'my-account', params: $route.params }" custom>
                <div class="sl-side-menu__item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('my_account.page_title') }}
                </div>
            </router-link>

            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'contact-details', params: $route.params }" custom>
                <div v-can="{ permission: 'contact-details:edit' }" class="sl-side-menu__item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('side_menu.contact_details') }}
                </div>
            </router-link>

            <!--
            <div class="sl-side-menu__item">
                Retourner un article
            </div>

            <div class="sl-side-menu__item">
                Retours
            </div>

            <div class="sl-side-menu__item">
                Porteurs
            </div>

            <div class="sl-side-menu__separator"></div>
            -->
        </div>

        <!-- SL Page -->
        <slot />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'MainLayout',

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },
    },
};

</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-side-menu
{
    @apply w-1/5;
}

.sl-side-menu__item
{
    @apply text-gray-500 text-sm py-3 px-8 cursor-pointer;
}

.sl-side-menu__item:hover
{
    @apply bg-gray-200;
}

.sl-side-menu__item--active
{
    @apply bg-primary-100 text-primary-500;
}

.sl-side-menu__separator
{
    @apply border-b border-primary-200 mb-2 pt-2 mx-6;
}
</style>

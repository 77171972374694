<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="grow text-xs text-gray-500">
        <template v-if="order.quantity > 1">
            {{ order.quantity }}
            &times;
        </template>

        {{ order.article.label }}
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'StandardLineFollowUpWidget',

    props:
    {
        order:
        {
            type:     Object,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Field Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Field-Error Wrapper -->
            <div class="sl-order-form__field-wrapper w-full relative">
                <!-- Field (TextArea) -->
                <textarea
                    ref="field"
                    v-model.lazy="tempValue"
                    class="sl-order-form__textarea"
                    :name="name"
                    :tabindex="tabIndex"
                    rows="1"
                    :disabled="!parameter.is_enabled"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="checkIfHasScrollbar(); checkIfIsEmpty();"
                ></textarea>

                <!-- Clear Field Button -->
                <mdi-icon
                    v-if="!isEmpty"
                    v-tippy="{ content: $t('common.clear_field') }"
                    icon="mdiClose"
                    class="sl-order-form__clear-button"
                    :class="{ 'sl-order-form__clear-button--has-scrollbar': hasScrollbar }"
                    @click="clearField(true)"
                />
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'TextareaField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,

            hasScrollbar:   false,
            isEmpty:        true,
            resizeObserver: null,
        };
    },

    computed:
    {
        helper()
        {
            return null;
        },

        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.modelValue;
                }
                else
                {
                    // @todo Must return formatted values.
                    return this.modelValue;
                }
            },

            set(value)
            {
                this.$emit('update:modelValue', value);

                this.$nextTick(() =>
                {
                    this.checkIfHasScrollbar();
                    this.checkIfIsEmpty();
                });
            },
        },
    },

    mounted()
    {
        this.checkIfIsEmpty();
        this.checkIfHasScrollbar();

        // Track scrollbar status on field resize
        this.resizeObserver = new ResizeObserver(this.checkIfHasScrollbar);
        this.resizeObserver.observe(this.$refs.field);
    },

    beforeUnmount()
    {
        // Stop tracking scrollbar status on field resize
        this.resizeObserver?.disconnect();
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },

        clearField(focus = false)
        {
            // Empty the field
            this.tempValue = null;

            if(focus)
            {
                // Focus the field
                this.$nextTick(() =>
                {
                    this.$refs.field?.focus();
                });
            }
        },

        checkIfHasScrollbar()
        {
            const elem = this.$refs.field;
            if(elem)
            {
                this.hasScrollbar = (elem.clientHeight < elem.scrollHeight);
            }
            else
            {
                this.hasScrollbar = false;
            }

            return this.hasScrollbar;
        },

        checkIfIsEmpty()
        {
            const fieldValue = this.$refs.field?.value;
            this.isEmpty = (typeof fieldValue === 'undefined' || fieldValue === null || fieldValue === '');

            return this.isEmpty;
        },
    },
};

</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__textarea
{
    @apply pr-5;

    &:focus
    {
        @apply outline-none border-primary-400;
    }
}

.sl-order-form__clear-button
{
    @apply cursor-pointer absolute top-1.5 right-1.5 w-4 h-4 text-gray-600 transition
        hover:text-primary-500;

    &.sl-order-form__clear-button--has-scrollbar
    {
        @apply right-5;
    }
}
</style>

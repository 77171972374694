<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-ofg__line" :class="{ 'sl-ofg__line--error': error }">
        <div class="sl-ofg__field">
            <!-- Label -->
            <div class="sl-ofg__label">
                <label>
                    {{ parameter.form_label }}

                    <!-- Note -->
                    <tippy
                        v-if="hasNote || hasDefaultValue"
                        tag="div"
                        :placement="side"
                        class="sl-ofg__note"
                    >
                        <mdi-icon
                            icon="mdiInformationOutline"
                            class="w-4 h-4 text-gray-400"
                        />

                        <template #content>
                            <ofg-field-tooltip-content :parameter="parameter" />
                        </template>
                    </tippy>
                </label>
            </div>

            <!-- Input -->
            <div class="sl-ofg__input">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="text-center text-primary-500" v-html="displayedValue"></div>
            </div>

            <!-- Helper -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="helper" class="sl-ofg__helper" v-html="helper"></div>
        </div>

        <!-- Error -->
        <div v-if="error" class="sl-ofg__error">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OfgAbstractField       from './OfgAbstractField.vue';
import OfgFieldTooltipContent from '../OfgFieldTooltipContent.vue';

export default
{
    name: 'OfgInfoField',

    components:
    {
        OfgFieldTooltipContent,
    },

    extends: OfgAbstractField,

    emits: ['update:modelValue'],

    computed:
    {
        helper()
        {
            return this.parameter.help;
        },

        formattedValue()
        {
            if(this.parameter.defined_value_label !== null)
            {
                return this.parameter.defined_value_label;
            }

            if(this.parameter.defined_value !== null)
            {
                return this.parameter.defined_value;
            }

            const tkey = `parameters.${this.parameter.code}.values.${this.modelValue}.label`;
            const translation = this.$t(tkey);
            if(translation !== tkey)
            {
                return translation;
            }

            return this.modelValue;
        },

        hasSuffix()
        {
            return (this.parameter.formatters || '').includes('suffix:');
        },

        displayedValue()
        {
            if(this.parameter.unit !== null && !this.hasSuffix)
            {
                return this.formattedValue + ' ' + this.parameter.unit;
            }

            return this.formattedValue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

export default class CheckForSomethingOrStop
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /*
         * Check if there is anything that can
         * be processable to display lens form.
         */

        if(this.v.sides.right.previousOrder)
        {
            return true;
        }

        if(this.v.sides.left.previousOrder)
        {
            return true;
        }

        if(this.v.sides.right.prototypeCode)
        {
            return true;
        }

        if(this.v.sides.left.prototypeCode)
        {
            return true;
        }

        let lensOrderUrlManager = this.v.lensOrderUrlManager;

        if(lensOrderUrlManager.hasBasketLineId('right'))
        {
            return true;
        }

        if(lensOrderUrlManager.hasBasketLineId('left'))
        {
            return true;
        }

        this.v.loading = false;

        return false;
    }
}

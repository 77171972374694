export default class ResetSideAdaptationOnConditions
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;
        const previousOrder = this.v.sides[this.side].previousOrder;

        // If URL has nothing for given side (no reference, SN or prototype code) => reset side.
        if(!lensOrderUrlManager.has(this.side))
        {
            console.log(`Side "${this.side}" reseted : nothing on URL`);

            // Mutation
            this.v.$store.commit('lensOrder/resetAdaptation', this.side);

            this.v.sides[this.side].previousOrder = null;
        }
        else if(previousOrder && lensOrderUrlManager.hasLensSn(this.side)) // If there is a previous order and a lens SN in URL
        {
            const urlOrderLensSn = lensOrderUrlManager.getLensSn(this.side);
            const previousOrderLensSn = previousOrder?.article_data?.LENS_SERIAL_NUMBER || null;

            /*
             * If previous order lens SN is different from URL order lens SN => user
             * has changed the number in lens SN field => reset side.
             */
            if(urlOrderLensSn != previousOrderLensSn)
            {
                console.log(`Side "${this.side}" reseted : lens SN has changed in URL`);

                // Mutation
                this.v.$store.commit('lensOrder/resetAdaptation', this.side);
            }
            else
            {
                console.log(`No reset needed on ${this.side}: lens SN has not changed in URL`);
            }
        }
        else if(previousOrder && lensOrderUrlManager.hasOrderReference(this.side)) // If there is a previous order and an order reference in URL
        {
            let urlOrderReference = lensOrderUrlManager.getOrderReference(this.side);

            /*
             * If previous order reference is different from URL order reference => reset side.
             */
            if(urlOrderReference != previousOrder.reference)
            {
                console.log(`${this.side} reset : order reference has changed in URL`);

                // Mutation
                this.v.$store.commit('lensOrder/resetAdaptation', this.side);
            }
            else
            {
                console.log(`No reset needed on ${this.side}: order reference has not changed in URL`);
            }
        }
        else
        {
            console.log(`No reset needed on ${this.side}`);
        }

        return true;
    }
}

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'AbstractGroupedField',

    extends: AbstractField,

    computed:
    {
        supplement()
        {
            const rightParameter   = this.$store.state.lensOrder.adaptations.right.getPrototype().getParameterByCode(this.parameter.code);
            const leftParameter    = this.$store.state.lensOrder.adaptations.left.getPrototype().getParameterByCode(this.parameter.code);
            const rightArticleCode = rightParameter ? rightParameter.priced_article_code : null;
            const leftArticleCode  = leftParameter  ? leftParameter.priced_article_code  : null;

            let rightPrice;
            let leftPrice;
            let currencyCode;

            // Retrieve supplement price for each side
            if(rightArticleCode)
            {
                const article = this.$store.getters['catalog/getByCode'](rightArticleCode);
                if(article)
                {
                    rightPrice = this.max2decimals(article.price);
                    currencyCode = article.currency_code;
                }
            }

            if(leftArticleCode)
            {
                const article = this.$store.getters['catalog/getByCode'](leftArticleCode);
                if(article)
                {
                    leftPrice = this.max2decimals(article.price);
                    currencyCode = article.currency_code;
                }
            }

            // No supplement or zero price
            if(!rightPrice && !leftPrice)
            {
                return null;
            }

            // Both sides have the same price: "+ 6.50 EUR per lens"
            if(rightPrice == leftPrice)
            {
                return `+ ${leftPrice} ${currencyCode} ` + this.$t('order.lenses.per_lens');
            }

            // Each side has a different supplement: "+ 5.50 EUR | + 7.50 EUR"
            let supplement = '';
            if(rightPrice)
            {
                supplement += `+ ${rightPrice} ${currencyCode}`;
            }

            if(rightPrice && leftPrice)
            {
                supplement += ' | ';
            }

            if(leftPrice)
            {
                supplement += `+ ${leftPrice} ${currencyCode}`;
            }

            return supplement;
        },
    },
};
</script>

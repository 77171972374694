<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<template>
    <!-- sl-orc stands for "SwissLens - Over-Refraction Calculator" -->
    <div class="sl-widget sl-calculator sl-orc" @keydown.esc="$emit('close')">
        <div class="sl-widget__header">
            <div class="sl-widget__title">
                {{ $t('calculators.over_refraction.title') }}
            </div>
        </div>

        <div class="sl-widget__body">
            <div class="grid grid-cols-4 gap-2 justify-center items-center text-center">
                <!-- ---------------------------------------- Calculator Header -->

                <div></div>

                <div class="transform -rotate-45 my-3">
                    {{ $t('calculators.common.contact_lens') }}
                </div>

                <div class="transform -rotate-45 my-3">
                    {{ $t('calculators.over_refraction.over_refraction') }}
                </div>

                <div class="transform -rotate-45 my-3">
                    {{ $t('calculators.common.result') }}
                </div>


                <!-- ---------------------------------------- Calculator Line 1: Sphere -->

                <div>
                    {{ $t('parameters.LENS_SPHERE.label') }}
                </div>

                <div>
                    <lsn-input
                        v-model="lens.sphere"
                        :tabindex="1001"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        ref="autofocus"
                        v-model="overRefraction.sphere"
                        :tabindex="1002"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        :model-value="result.sphere"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        disabled
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 2: Cylinder -->

                <div>
                    {{ $t('parameters.LENS_CYLINDER.label') }}
                </div>

                <div>
                    <lsn-input
                        v-model="lens.cylinder"
                        :tabindex="1001"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="overRefraction.cylinder"
                        :tabindex="1002"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="result.cylinder"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        disabled
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 3: Axis -->

                <div>
                    {{ $t('parameters.LENS_CYLINDER_AXIS.label') }}
                </div>

                <div>
                    <lsn-input
                        v-model="lens.axis"
                        :tabindex="1001"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="overRefraction.axis"
                        :tabindex="1002"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="result.axis"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        disabled
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 4: Stabilization (TABO) -->

                <div>
                    {{ $t('calculators.over_refraction.stabil_tabo') }}
                </div>

                <div></div>

                <div>
                    <lsn-input
                        v-model="overRefraction.stabilization"
                        :tabindex="1002"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div></div>


                <!-- ---------------------------------------- Calculator Line 5: Addition -->

                <div>
                    {{ $t('parameters.LENS_ADDITION.label') }}
                </div>

                <div>
                    <lsn-input
                        v-model="lens.addition"
                        :tabindex="1001"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="overRefraction.addition"
                        :tabindex="1002"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div>
                    <lsn-input
                        v-model="result.addition"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        disabled
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 6: Vertex Power -->

                <div>
                    {{ $t('calculators.common.vertex_power') }}
                </div>

                <div></div>

                <div>
                    <lsn-input
                        v-model="overRefraction.vertexPower"
                        :tabindex="1002"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-orc__input"
                        @update:model-value="calculate"
                    />
                </div>

                <div></div>
            </div>

            <!-- Incompatible Lens Warning -->
            <div v-if="hasForbiddenLens" class="mt-4 rounded p-2 bg-yellow-100 text-yellow-700">
                <div class="mb-1 font-bold">
                    <mdi-icon icon="mdiAlert" class="inline-block align-middle w-4 h-4" />

                    <span class="inline-block align-middle ml-2 text-yellow-800">
                        {{ $t('common.caution') }}
                    </span>
                </div>

                <div class="ml-6">
                    {{ $t('calculators.over_refraction.toriflex_warning') }}
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="sl-widget__actions">
            <div
                class="sl-widget__button sl-widget__button--white"
                :tabindex="1003"
                @click="$emit('close')"
                @keypress.enter="$emit('close')"
            >
                {{ $t('common.cancel') }}
            </div>

            <div
                class="sl-widget__button"
                :class="attributeButtonClass"
                :tabindex="1004"
                @click="attribute"
                @keypress.enter="attribute"
            >
                {{ $t(`calculators.common.attribute_${side}`) }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR } from './form-lines/FormLines.vue';

export default
{
    name: 'OverRefractionCalculator',

    emits: ['close'],

    data()
    {
        return {
            attributing: false,
            lens:        {
                sphere:   0.00,
                cylinder: 0.00,
                axis:     0.00,
                addition: 0.00,
            },
            overRefraction: {
                sphere:        0.00,
                cylinder:      0.00,
                axis:          0.00,
                stabilization: 0.00,
                addition:      0.00,
                vertexPower:   14.00,
            },
            result: {
                sphere:   0.00,
                cylinder: 0.00,
                axis:     0.00,
                addition: 0.00,
            },
        };
    },

    computed:
    {
        side()
        {
            return this.$store.state.calculators.overRefraction.side;
        },

        rightAdaptation()
        {
            return this.$store.state.lensOrder.adaptations.right;
        },

        leftAdaptation()
        {
            return this.$store.state.lensOrder.adaptations.left;
        },

        attributeButtonClass()
        {
            const classes = [];

            if(this.attributing || this[`${this.side}IsForbidden`])
            {
                classes.push('sl-widget__button--disabled');
            }
            else
            {
                classes.push('sl-widget__button--primary');
            }

            return classes;
        },

        rightIsForbidden()
        {
            return PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR.some(
                proto => this.rightAdaptation?.prototype.code === proto
            );
        },

        leftIsForbidden()
        {
            return PROTOTYPES_WITHOUT_OVER_REFRACTION_CALCULATOR.some(
                proto => this.leftAdaptation?.prototype.code === proto
            );
        },

        hasForbiddenLens()
        {
            return this.rightIsForbidden || this.leftIsForbidden;
        },
    },

    mounted()
    {
        this.loadValues();
        this.calculate();

        this.$nextTick(() => this.$refs.autofocus?.focus());
    },

    methods:
    {
        loadValues()
        {
            const params = this[`${this.side}Adaptation`].prototype.parameters;

            this.lens.sphere   = params.get('LENS_SPHERE'       )?.value || 0.00;
            this.lens.cylinder = params.get('LENS_CYLINDER'     )?.value || 0.00;
            this.lens.axis     = params.get('LENS_CYLINDER_AXIS')?.value || 0.00;
            this.lens.addition = params.get('LENS_ADDITION'     )?.value || 0.00;
        },

        calculate()
        {
            // Replace commas with periods
            this.lens.sphere                  = `${this.lens.sphere}`.replace(/,/g, '.');
            this.lens.cylinder                = `${this.lens.cylinder}`.replace(/,/g, '.');
            this.lens.axis                    = `${this.lens.axis}`.replace(/,/g, '.');
            this.lens.addition                = `${this.lens.addition}`.replace(/,/g, '.');
            this.overRefraction.sphere        = `${this.overRefraction.sphere}`.replace(/,/g, '.');
            this.overRefraction.cylinder      = `${this.overRefraction.cylinder}`.replace(/,/g, '.');
            this.overRefraction.axis          = `${this.overRefraction.axis}`.replace(/,/g, '.');
            this.overRefraction.stabilization = `${this.overRefraction.stabilization}`.replace(/,/g, '.');
            this.overRefraction.addition      = `${this.overRefraction.addition}`.replace(/,/g, '.');
            this.overRefraction.vertexPower   = `${this.overRefraction.vertexPower}`.replace(/,/g, '.');

            // Force negative value for cylinder
            this.lens.cylinder           = -Math.abs(this.lens.cylinder) || 0.00;
            this.overRefraction.cylinder = -Math.abs(this.overRefraction.cylinder) || 0.00;

            // Lens values
            const sph1 = parseFloat(this.lens.sphere);
            const cyl1 = parseFloat(this.lens.cylinder);
            const axe1 = this.constrainTo90(parseFloat(this.lens.axis));
            const add1 = parseFloat(this.lens.addition);

            // Over-refraction values
            const sph2 = parseFloat(this.overRefraction.sphere);
            const cyl2 = parseFloat(this.overRefraction.cylinder) || 0.00001;
            const axe2 = this.constrainTo90(parseFloat(this.overRefraction.axis));
            const add2 = parseFloat(this.overRefraction.addition);
            const stab = this.constrainTo90(parseFloat(this.overRefraction.stabilization));

            // Calculation variables
            let G;
            let H;
            let J;

            if(sph2 === 0 && cyl2 === 0)
            {
                G = sph1;
                H = cyl1;
                J = (axe1 - stab) % 180;
                if(J < 0)
                    J += 180;
            }
            else
            {
                const A = sph1 + cyl1;
                const B = -cyl1;
                const C = axe1 + 90;
                const D = sph2 + cyl2;
                const E = -cyl2;
                const F = axe2 + 90 - stab;

                let CC = C;
                if(C - F > 90)
                    CC -= 180;

                let FF = F;
                if(F - C > 90)
                    FF -= 180;

                const FC = (FF - CC) * Math.PI / 180;
                H = -Math.sqrt(B * B + E * E + 2 * B * E * Math.cos(2 * FC));
                G = A + D + 0.5 * (B + E - H);
                const I1 = B + E * Math.cos(2 * FC);
                const I2 = -I1 / H;
                const I3 = 180 * Math.acos(I2.toFixed(3)) / Math.PI;
                const I = 0.5 * I3;

                if(FF < CC)
                    J = CC - I - 90;
                else
                    J = CC + I - 90;
            }

            if(J < 0)
                J += 180;

            // Affect results
            this.result.sphere   = G.toFixed(2);
            this.result.cylinder = H.toFixed(2);
            this.result.axis     = Math.round(J);
            this.result.addition = add1 + add2;
        },

        attribute()
        {
            if(this.attributing)
            {
                return;
            }

            if(!['right', 'left'].includes(this.side))
            {
                throw new RangeError(`Invalid value for side: "${this.side}"`);
            }

            if(this[`${this.side}IsForbidden`])
            {
                return;
            }

            this.attributing = true;

            const adaptation = this[`${this.side}Adaptation`];

            // Values will be set only if parameter exists.
            adaptation.getPrototype().setValue('LENS_SPHERE',        parseFloat(this.result.sphere));
            adaptation.getPrototype().setValue('LENS_CYLINDER',      parseFloat(this.result.cylinder));
            adaptation.getPrototype().setValue('LENS_CYLINDER_AXIS', parseFloat(this.result.axis));
            adaptation.getPrototype().setValue('LENS_ADDITION',      parseFloat(this.result.addition));

            this.$store.dispatch('lensOrder/template', { side: this.side })
                .then(responses =>
                {
                    this.$emit('close');
                })
                .finally(() =>
                {
                    this.attributing = false;
                });
        },

        constrainTo90(n)
        {
            let out = n;

            while(out > 90) out -= 180;
            while(out < -90) out += 180;

            return out;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-orc :deep(.sl-orc__input input)
{
    @apply text-center;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line">
        <div class="sl-order-form__heading">
            <div v-html="displayedHeading"></div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'HeadingFormLine',

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        code:
        {
            type:     String,
            required: true,
        },

        line:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        parameter()
        {
            return this.line.leftParameter || this.line.rightParameter;
        },

        pValue()
        {
            return this.parameter.getValue();
        },

        displayedHeading()
        {
            if(this.parameter.defined_value_label !== null)
            {
                return this.parameter.defined_value_label;
            }

            if(this.parameter.defined_value !== null)
            {
                return this.parameter.defined_value;
            }

            if(typeof this.pValue !== 'undefined' && this.pValue !== null)
            {
                const tkey = `parameters.${this.parameter.code}.values.${this.pValue}.label`;
                const translation = this.$t(tkey);
                if(translation !== tkey)
                {
                    return translation;
                }
            }

            return this.parameter.form_label;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
// Vue Transition
.list-enter-active, .list-leave-active
{
    transition: all 0.5s ease;
}

.list-enter-to, .list-leave-from
{
    @apply max-h-[1000px] opacity-100;
}

.list-enter-from, .list-leave-to
{
    @apply max-h-0 opacity-0;
}

// Line & Label
.sl-order-form__line
{
    @apply cursor-default block px-2 py-1 min-h-0 bg-gray-200 text-center text-primary-600 text-sm;
}

// #region XXX: Easter Egg for PHK
// .sl-order-form__line
// {
//     --angle: -45deg;
//     background-image: repeating-linear-gradient(var(--angle), white 0, white 5mm, red 5mm, red 10mm, white 10mm, white 15mm, blue 15mm, blue 20mm);
//     background-size: 200%;
//     background-position-x: -4mm;
//     animation: slide 1.5s linear infinite;
//     font-weight: bold;

//     > div
//     {
//         @apply inline-block px-2 py-0 bg-black bg-opacity-30 rounded backdrop-blur-sm;
//     }
// }

// @keyframes slide {
//     0% {
//         background-position-x: -4mm;
//         color: white;
//     }

//     25% {
//         color: red;
//     }

//     50% {
//         color: cyan;
//     }

//     75% {
//         color: yellow;
//     }

//     100% {
//         background-position-x: -32mm;
//         color: white;
//     }
// }
// #endregion
</style>

export default class PriceLenses
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const requests = [];
        const rightAdaptation = this.v.$store.state.lensOrder.adaptations.right;
        const leftAdaptation = this.v.$store.state.lensOrder.adaptations.left;

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.pricing'));

        // @HACK FOR THERAPEUTIC - START
        const overridenValues = {
            quantity: 0,
        };

        if(rightAdaptation)
        {
            overridenValues.quantity += rightAdaptation.getPrototype().getValue('quantity');
        }

        if(leftAdaptation)
        {
            overridenValues.quantity += leftAdaptation.getPrototype().getValue('quantity');
        }
        // @HACK FOR THERAPEUTIC - END

        if(rightAdaptation)
        {
            console.log("Price right side", rightAdaptation);

            requests.push(rightAdaptation.price(overridenValues));
        }

        if(leftAdaptation)
        {
            console.log("Price left side", leftAdaptation);

            requests.push(leftAdaptation.price(overridenValues));
        }

        return Promise.all(requests)
            .then(response =>
            {
                console.log("Pricing OK !");

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log("Pricing error:", error);

                this.v.showPricingError = true;

                return Promise.reject(error);
            });
    }
}

/* ==================
 * Lens Order Module
 * ==================
 */

import { isEmpty } from 'lodash-es';

export function setupLensOrderModule(i18n, VueHoneybadger)
{
    return {
        namespaced: true,


        // ------------------------------------------------------------ STATE

        state()
        {
            return {
                adaptations:
                {
                    right: null,
                    left:  null,
                },
                viewAs: '', // either "CUSTOMER" or not
            };
        },


        // ------------------------------------------------------------ GETTERS

        getters:
        {
            getPrototype: state => side => state.adaptations[side]?.getPrototype() || null,

            getPrototypeValue: state => (side, paramCode) =>
            {
                return state.adaptations[side] ? state.adaptations[side].getPrototype().getValue(paramCode) : null;
            },

            getOfg: state => side => state.adaptations[side]?.getOfg() || null,

            viewAsCustomer: state => state.viewAs === 'CUSTOMER',
        },


        // ------------------------------------------------------------ MUTATIONS

        mutations:
        {
            resetAdaptation(state, side = null)
            {
                if(!side)
                {
                    state.adaptations.right = null;
                    state.adaptations.left = null;
                }
                else
                {
                    state.adaptations[side] = null;
                }
            },

            setAdaptation(state, { side, adaptation })
            {
                state.adaptations[side] = adaptation;
            },

            setPrototypeValue(state, { side, paramCode, paramValue })
            {
                if(side === 'right' || !side)
                {
                    state.adaptations.right.getPrototype().setValue(paramCode, paramValue);
                }

                if(side === 'left' || !side)
                {
                    state.adaptations.left.getPrototype().setValue(paramCode, paramValue);
                }
            },

            setOfgValue(state, { side, paramCode, paramValue })
            {
                if(side === 'right' || !side)
                {
                    state.adaptations.right.getOfg().setValue(paramCode, paramValue);
                }

                if(side === 'left' || !side)
                {
                    state.adaptations.left.getOfg().setValue(paramCode, paramValue);
                }
            },

            setOfgOpen(state, { side, ofgOpen, ofg })
            {
                if(ofgOpen)
                {
                    if(!ofg && !state.adaptations[side].getOfg())
                    {
                        throw new Error(`Cannot open panel without an OFG`);
                    }

                    if(ofg)
                    {
                        state.adaptations[side].setOfg(ofg);
                    }
                }

                state.adaptations[side].setOfgOpen(ofgOpen);
            },

            setViewAs(state, viewAs)
            {
                state.viewAs = viewAs;
            },
        },


        // ------------------------------------------------------------ ACTIONS

        actions:
        {
            resetAdaptation({ commit }, side = null)
            {
                return new Promise((resolve, reject) =>
                {
                    commit('resetAdaptation', side);

                    resolve();
                });
            },

            template({ state, rootState }, { side = null, initValues = {}, target })
            {
                if(!side && !isEmpty(initValues))
                {
                    console.log('INIT VALUES', initValues, !side && initValues, side);

                    throw new Error(`When "initValues" are given during templating, "side" is mandatory.`);
                }

                const requests = [];
                const eid = rootState.account.cEntity.id;
                const getTarget = (target === 'ofg' ? 'getOfg' : 'getPrototype');

                if(state.adaptations.right && (side === 'right' || !side))
                {
                    requests.push(state.adaptations.right[getTarget]().template(eid, initValues, state.viewAs));
                }

                if(state.adaptations.left && (side === 'left' || !side))
                {
                    requests.push(state.adaptations.left[getTarget]().template(eid, initValues, state.viewAs));
                }

                return Promise.all(requests);
            },

            templateAndValidate({ state, rootState }, { side = null, options = {} })
            {
                const requests = [];
                const eid = rootState.account.cEntity.id;

                if(state.adaptations.right && (side === 'right' || !side))
                {
                    requests.push(state.adaptations.right.getPrototype().templateAndValidate(eid, options, state.viewAs));
                }

                if(state.adaptations.left && (side === 'left' || !side))
                {
                    requests.push(state.adaptations.left.getPrototype().templateAndValidate(eid, options, state.viewAs));
                }

                return Promise.all(requests);
            },

            openOfg({ commit, getters, rootGetters }, { side, formCode })
            {
                return new Promise((resolve, reject) =>
                {
                    const options = {
                        side,
                        ofgOpen: true,
                    };

                    // Only set the form if it has changed
                    if(formCode !== getters.getOfg(side)?.code)
                    {
                        options.ofg = rootGetters['forms/getOfgByCode'](formCode);

                        if(!options.ofg)
                        {
                            throw new Error(`OFG with code \"${formCode}\" not found.`);
                        }
                    }

                    commit('setOfgOpen', options);

                    resolve();
                });
            },

            closeOfg({ commit }, side)
            {
                commit('setOfgOpen', { side, ofgOpen: false });

                return Promise.resolve();
            },

            toggleViewAsCustomer({ commit, state, rootState }, viewAs)
            {
                if(state.viewAs === 'CUSTOMER')
                {
                    commit('setViewAs', rootState.account.cUser.role);
                }
                else
                {
                    commit('setViewAs', 'CUSTOMER');
                }
            },
        },
    };
};

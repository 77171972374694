<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="hidden lg:flex grow items-center justify-center">
        <router-link
            ref="new_order"
            :to="{ name: 'order-lenses', params: { lang: $route.params.lang, entity_id: $route.params.entity_id } }"
            class="sl-navbar__link-item"
            @click="trackNav('new_order')"
        >
            {{ $t('main_menu.new_order') }}
        </router-link>

        <div class="sl-navbar__separator"></div>

        <router-link :to="{ name: 'order-list', params: $route.params }" class="sl-navbar__link-item">
            {{ $t('main_menu.orders') }}
        </router-link>

        <div v-can="'invoice:view'" class="sl-navbar__separator"></div>

        <router-link v-can="'invoice:view'" :to="{ name: 'invoice-list', params: $route.params }" class="sl-navbar__link-item">
            {{ $t('dropdown_menu.invoicing') }}
        </router-link>

        <div class="sl-navbar__separator"></div>

        <a href="https://order.swisslens.ch/pro/retours.php" target="_blank" class="sl-navbar__link-item">
            {{ $t('main_menu.returns') }}
        </a>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { DEFAULT_MAIN_MENU_ITEM } from '@/store/mainStore';

export default
{
    name: 'MainMenu',

    watch:
    {
        /**
         * Make the current main menu HTML element accessible via the store.
         *
         * @param {string|undefined} mainMenu
         */
        '$route'()
        {
            this.setCurrentMainMenuElement();
        },
    },

    mounted()
    {
        this.setCurrentMainMenuElement();
    },

    methods:
    {
        setCurrentMainMenuElement()
        {
            const mainMenu = this.$route.meta.mainMenu || DEFAULT_MAIN_MENU_ITEM;

            let cMainMenuElement = null;
            if(mainMenu)
            {
                cMainMenuElement = this.$refs[mainMenu];
            }

            this.$store.dispatch('setCurrentMainMenuElement', cMainMenuElement);
        },

        /**
         * @param {string} item
         */
        trackNav(item)
        {
            const eventName = 'nav-' + item.toLowerCase();
            this.$gtag.event(eventName);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-navbar__link-item
{
    @apply mx-3 inline-block text-white text-sm;
}
</style>

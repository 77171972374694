<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-reorder-box">
        <!-- Order Identifier Input (SN/OEM) -->
        <div class="flex items-center">
            <div class="w-1/3 text-gray-500 pl-6">
                {{ $t('order.renewal_form.label') }}
            </div>

            <input
                :value="orderIdentifier"
                type="text"
                class="sl-reorder-box__field w-1/3"
                :placeholder="$t('order.renewal_form.placeholder')"
                @input="onInput($event.target.value)"
                @keypress.enter="$emit('next')"
            >

            <!-- Fill Helper -->
            <div
                v-if="suggestedOrderIdentifier && otherSideOrderIdentifier && suggestedOrderIdentifier != orderIdentifier"
                class="w-1/3 pl-2 mt-0.5 text-primary-500 cursor-pointer"
                @click="setOrderIdentifierFromOtherSide"
            >
                &larr;&nbsp;{{ suggestedOrderIdentifier }}
            </div>
        </div>

        <!-- Error -->
        <div v-if="error" class="mt-2 w-full text-red-600 text-sm text-center">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { ONLY_NUMBERS } from '@/utils/Regexes';

let searchSnDebounce;

export default
{
    name: 'RenewalForm',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
        orderIdentifier: // Lens Serial Number or OEM Number
        {
            type:    [String, Number],
            default: null,
        },
        otherSideOrderIdentifier:
        {
            type:    [String, Number],
            default: null,
        },
        error:
        {
            type:    String,
            default: null,
        },
    },

    emits: ['update:orderIdentifier', 'next'],

    computed:
    {
        /**
         * Suggested lens serial number, based on that of the other side.
         * If the other side doesn't contain only numbers, it's considered
         * an OEM number and no suggestion is made (`null`).
         *
         * @returns {Number|null}
         */
        suggestedOrderIdentifier()
        {
            // OEM numbers cannot be calculated
            if(!ONLY_NUMBERS.test(this.otherSideOrderIdentifier))
            {
                return null;
            }

            if(this.side === 'left')
            {
                return parseInt(this.otherSideOrderIdentifier) + 1;
            }
            else
            {
                return parseInt(this.otherSideOrderIdentifier) - 1;
            }
        },
    },

    methods:
    {
        setOrderIdentifierFromOtherSide()
        {
            this.$emit('update:orderIdentifier', this.suggestedOrderIdentifier);
        },

        onInput(orderIdentifier)
        {
            orderIdentifier = (orderIdentifier.length > 0) ? orderIdentifier : null; // Important !

            if(orderIdentifier)
            {
                orderIdentifier = orderIdentifier.replaceAll(/\s/g, '');
            }

            // Avoid sending ajax request on every inputs.
            clearTimeout(searchSnDebounce);

            searchSnDebounce = setTimeout(() =>
            {
                this.$emit('update:orderIdentifier', orderIdentifier);
            }, 400);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-reorder-box
{
    @apply relative py-4 bg-primary-10;
}

.sl-reorder-box__field
{
    @apply py-1 px-3 text-center border border-primary-200 outline-none rounded-full text-primary-500 tracking-wider;
}

.sl-reorder-box__field::placeholder
{
    @apply text-sm;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number]
{
    -moz-appearance: textfield;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-ofg__line sl-ofg__line--radio" :class="{ 'sl-ofg__line--error': error }">
        <div class="sl-ofg__field">
            <!-- Label -->
            <div class="sl-ofg__label">
                <label :for="name">
                    {{ parameter.form_label }}

                    <!-- Note -->
                    <tippy
                        v-if="hasNote || hasDefaultValue"
                        tag="div"
                        :placement="side"
                        class="sl-ofg__note"
                    >
                        <mdi-icon
                            icon="mdiInformationOutline"
                            class="w-4 h-4 text-gray-400"
                        />

                        <template #content>
                            <ofg-field-tooltip-content :parameter="parameter" />
                        </template>
                    </tippy>
                </label>
            </div>

            <!-- Input -->
            <div class="sl-ofg__input">
                <!-- Field Wrapper -->
                <div
                    class="sl-order-form__field-wrapper lsn-form__radio-item-wrapper"
                    :tabindex="tabIndex"
                    @keydown.up.prevent="selectPrevious()"
                    @keydown.left.prevent="selectPrevious()"
                    @keydown.right.prevent="selectNext()"
                    @keydown.down.prevent="selectNext()"
                >
                    <!-- Field -->
                    <div
                        v-for="item in parameter.list_items"
                        :key="item.ovalue"
                        class="lsn-form__radio-item"
                        :class="{ 'lsn-form__radio-item--selected': item.ovalue == modelValue, 'lsn-form__radio-item--disabled': !item.is_enabled }"
                        @click="setValue(item)"
                    >
                        <!-- Label -->
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-html="item.label"></div>

                        <!-- Help -->
                        <div
                            v-if="item.help"
                            v-tippy
                            class="lsn-form__radio-item-help"
                            :content="item.help"
                        >
                            <mdi-icon
                                icon="mdiInformationOutline"
                                class="w-4 h-4 text-gray-400"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Helper -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="helper" class="sl-ofg__helper" v-html="helper"></div>
        </div>

        <!-- Error -->
        <div v-if="error" class="sl-ofg__error">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OfgAbstractField       from './OfgAbstractField.vue';
import OfgFieldTooltipContent from '../OfgFieldTooltipContent.vue';

export default
{
    name: 'OfgRadioButtonField',

    components:
    {
        OfgFieldTooltipContent,
    },

    extends: OfgAbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
        };
    },

    computed:
    {
        helper()
        {
            return null;
        },

        cIndex()
        {
            return this.parameter.list_items.findIndex(i => i.ovalue == this.modelValue);
        },
    },

    methods:
    {
        setValue(item)
        {
            if(!item.is_enabled)
            {
                return;
            }

            this.isTouched = true;
            this.$emit('update:modelValue', item.ovalue);
        },

        selectPrevious()
        {
            let prevIndex = this.cIndex;
            for(let i = (this.cIndex === -1 ? this.parameter.list_items.length : this.cIndex) - 1; i >= 0; i--)
            {
                if(this.parameter.list_items[i].is_enabled)
                {
                    prevIndex = i;
                    break;
                }
            }

            if(prevIndex !== this.cIndex)
            {
                this.setValue(this.parameter.list_items[prevIndex]);
            }
        },

        selectNext()
        {
            let nextIndex = this.cIndex;
            for(let i = this.cIndex + 1; i < this.parameter.list_items.length; i++)
            {
                if(this.parameter.list_items[i].is_enabled)
                {
                    nextIndex = i;
                    break;
                }
            }

            if(nextIndex !== this.cIndex)
            {
                this.setValue(this.parameter.list_items[nextIndex]);
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__ofg">
                    <a
                        :tabindex="tabIndex"
                        @click.prevent="togglePanel"
                        @keypress.enter="togglePanel"
                    >
                        {{ label }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from '../AbstractField.vue';

export default
{
    name: 'OfgButton',

    extends: AbstractField,

    computed:
    {
        isOpen()
        {
            return this.$store.state.lensOrder.adaptations[this.side].ofgOpen;
        },

        form()
        {
            return this.$store.state.lensOrder.adaptations[this.side].ofg;
        },

        label()
        {
            const tkey = this.isOpen
                ? 'ofg.close_button.label'
                : 'ofg.open_button.label';

            return this.$t(tkey);
        },
    },

    methods:
    {
        togglePanel()
        {
            const wasOpen = this.isOpen;

            // Always close panel to force values to be reloaded
            this.$store.dispatch('lensOrder/closeOfg', this.side);

            // Open panel if it wasn't when the user clicked
            if(!wasOpen)
            {
                this.$nextTick(() =>
                {
                    this.$store.dispatch('lensOrder/openOfg', {
                        side:     this.side,
                        formCode: this.parameter.defined_value,
                    });

                    if(!this.$store.state.lensOrder.adaptations[this.side].getOfg().initialized)
                    {
                        this.$store.dispatch('lensOrder/template', { side: this.side, target: 'ofg' });
                    }
                });
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__ofg a
{
    @apply cursor-pointer outline-none border border-transparent px-2 py-1 text-primary-500 text-sm select-none transition
        hover:underline
        focus-visible:no-underline focus-visible:ring-0 focus-visible:border-primary-500
        active:text-primary-700;
}
</style>

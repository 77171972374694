<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-footer__contents">
        {{ cEntity.name }}

        <span class="mx-2">|</span>

        {{ $t('common.client_number') }}:&nbsp;{{ cEntity.ext_id }}

        <span class="mx-2">|</span>

        <!-- Delivery Address -->
        <span v-if="address">
            <span v-if="address.line1">
                {{ address.line1 }},
            </span>

            <span v-if="address.line2">
                {{ address.line2 }},
            </span>

            <span v-if="address.line3">
                {{ address.line3 }},
            </span>

            <span v-if="address.line4">
                {{ address.line4 }},
            </span>

            <span v-if="address.po_box">
                {{ $t('common.address.po_box') }} {{ address.po_box }},
            </span>

            <span>
                {{ address.country }}-{{ address.postcode }} {{ address.city }}
            </span>
        </span>

        <!-- Phone -->
        <template v-if="phone">
            <span class="mx-2">|</span>

            <span>
                <a :href="clickToCall">{{ phone }}</a>
            </span>
        </template>

        <!-- E-mail -->
        <span v-if="emails.length" class="mx-2">|</span>

        <template v-for="(email, index) in emails" :key="email">

            <span>
                <a :href="'mailto:' + email">{{ email }}</a>
            </span>

            <span v-if="index != (emails.length - 1)">, </span>
        </template>

        <!-- Member Code -->
        <template v-if="cEntity.member_of && cEntity.member_of.member_code">
            <span class="mx-2">|</span>

            <span>
                {{ $t('common.member_code') }}&nbsp;{{ cEntity.member_of.member_code }}
            </span>
        </template>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'StaffFooterContents',

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        address()
        {
            return this.cEntity.addresses.find(a => a.used_for === 'SHIPPING');
        },

        phone()
        {
            const phone = this.cEntity.contacts.find(c => c.type === 'PHONE');
            const phoneNumber = phone?.formatted_value || phone?.cvalue || '';

            // Use only non-breaking spaces to avoid splitting the number over multiple lines
            return phoneNumber.replace(/\s/g, '\u00a0');
        },

        emails()
        {
            return this.cEntity.contacts
                .filter(c => c.type === 'EMAIL' && (c.notification_distributions || []).includes('ORDERS_CONFIRMATION'))
                .map(email => (email?.formatted_value || email?.cvalue || '').toLowerCase());
        },

        clickToCall()
        {
            return 'tel:' + this.phone.replace(/\s/g, '');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table :data="invoices" class="sl-paid-invoice-table">
        <!-- Embedded Table Header Caption -->
        <template #subtable-caption>
            <slot name="subtable-caption" />
        </template>

        <!-- Table Header -->
        <template #header>
            <lsn-table-cell class="sl-invoice__date">
                {{ $t("invoicing.table.headers.date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__reference">
                {{ $t("invoicing.table.headers.reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__paid-date">
                {{ $t("invoicing.table.headers.paid_date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__price">
                {{ $t("invoicing.table.headers.amount") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__balance">
                {{ $t("invoicing.table.headers.balance") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__currency">
                {{ $t("invoicing.table.headers.currency") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__due-date">
                {{ $t("invoicing.table.headers.due_date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-invoice__actions" />
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <paid-invoice-line-summary :invoice="item" />
        </template>

        <template #details="{ item }">
            <invoice-line-details :invoice="item" />
        </template>
    </lsn-table>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable               from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell           from '@/components/LsnTable/LsnTableCell.vue';
import PaidInvoiceLineSummary from '@/components/invoice/PaidInvoiceLineSummary.vue';
import InvoiceLineDetails     from '@/components/invoice/InvoiceLineDetails.vue';

export default
{
    name: 'PaidInvoiceTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        PaidInvoiceLineSummary,
        InvoiceLineDetails,
    },

    props:
    {
        invoices:
        {
            type:     Object,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.sl-invoice__price,
.sl-invoice__balance
{
    @apply text-right;
}

.sl-invoice__reference,
.sl-invoice__paid-date,
.sl-invoice__due-date,
.sl-invoice__currency,
.sl-invoice__actions
{
    @apply text-center;
}
</style>

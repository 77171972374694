import constants from '@/constants/constants';

/**
 * Custom Vue directive to hide an element based on the current user
 * having prototypes with a certain tag or set of tags.
 */
export default(el, binding) =>
{
    // Retrieve directive parameters
    if(typeof binding.value !== 'string' && !Array.isArray(binding.value))
    {
        throw new Error('Invalid format for v-has-prototypes-by-tag directive.');
        // Valid formats are: <div v-has-active-prototypes-by-tag="'TAG_CODE'">
        //   - <div v-has-active-prototypes-by-tag="'TAG_CODE'">
        //   - <div v-has-active-prototypes-by-tag="['TAG_CODE1', 'TAG_CODE2']">
    }

    // At least one prototype in the user's catalog must have all selected tags
    const shouldDisplay = binding.instance.$store.getters['prototypes/hasActivePrototypesByTag'](binding.value);

    if(shouldDisplay)
    {
        // Element is shown. Do nothing :-)
    }
    else
    {
        // Remove element from DOM
        el.remove();
    }
};

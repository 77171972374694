/**
 * Once both prototype have been templated, loop through parameters
 * and search for "grouped" parameters. Then, check that parameter is
 * "grouped" on both side and that value is NULL on one side but not
 * on the other. Finally, replace NULL value by value from the other side.
 * Note: Since grouping key are the same on both side, possible values are
 * necessarily also the same.
 * @param {*} state
 */

export default class CompleteGroupedParameterValues
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        let rightAdaptation = this.v.$store.state.lensOrder.adaptations.right;
        let leftAdaptation = this.v.$store.state.lensOrder.adaptations.left;

        if(rightAdaptation && leftAdaptation)
        {
            let rightPrototype = rightAdaptation.getPrototype();
            let leftPrototype = leftAdaptation.getPrototype();

            rightPrototype.getParameters().forEach((rightParameter, code) =>
            {
                if(['Y', 'F'].includes(rightParameter.is_grouped))
                {
                    let leftParameter = leftPrototype.getParameterByCode(code);

                    if(leftParameter && ['Y', 'F'].includes(leftParameter.is_grouped))
                    {
                        if(rightParameter.grouping_key == leftParameter.grouping_key)
                        {
                            let rightValue = rightParameter.getValue();
                            let leftValue = leftParameter.getValue();

                            if(rightValue === null && leftValue !== null)
                            {
                                rightParameter.setValue(leftValue);
                            }
                            else if(leftValue === null && rightValue !== null)
                            {
                                leftParameter.setValue(rightValue);
                            }
                        }
                    }
                }
            });

            console.log(`Grouped values have been set from right to left`);
        }
        else
        {
            console.log(`Only one side => no value to complete`);
        }

        return true;
    }
}

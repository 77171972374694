<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div>
        <!-- Patient / External Reference / Article Label -->
        <div class="flex flex-wrap grow">
            <!-- Patient -->
            <div class="sl-lens-details__patient">
                {{ order.article_data.PATIENT_REFERENCE1 }}
            </div>

            <!-- External Reference (if any) -->
            <div v-if="order.extra?.ext_ref" class="sl-lens-details__ext-ref">
                {{ order.extra.ext_ref }}
            </div>

            <!-- Article Label -->
            <div class="sl-lens-details__article">
                {{ order.article.label }}
            </div>

            <div v-if="sn" class="w-full mt-1 text-xs text-gray-500">
                SN: {{ sn }}
            </div>
        </div>

        <!-- Lens Side -->
        <div class="sl-lens-details__side">
            <sl-lens-side :side="order.article_data.LENS_SIDE" />
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'LensLineFollowUpWidget',

    props:
    {
        order:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        sn()
        {
            return this.order.article_data['LENS_SERIAL_NUMBER'] || null;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-lens-details__patient
{
    @apply text-sm text-gray-500;
}

.sl-lens-details__ext-ref
{
    @apply ml-3 text-sm text-gray-400;
}

.sl-lens-details__article
{
    @apply w-full mt-1 text-xs text-gray-400;
}

.sl-lens-details__side
{
    @apply flex items-center justify-self-end mx-2;
}
</style>

import { isEmpty } from 'lodash-es';

export const ID_TYPES = Object.freeze({
    LENS_SN:         'sn',
    LENS_OEM_NUMBER: 'oem',
    ORDER_REFERENCE: 'order-reference',
    BASKET_LINE_ID:  'basket-line-id',
});

const PROTOTYPE_CODE_KEY = 'prototype-code';

const SIDE_RIGHT = 'right';
const SIDE_LEFT  = 'left';

export default class LensOrderUrlManager
{
    lang = null;

    entity_id = null;

    right = {
        identifierType:  null,
        orderIdentifier: null,
        prototypeCode:   null,
    };

    left = {
        identifierType:  null,
        orderIdentifier: null,
        prototypeCode:   null,
    };

    action = null;

    queryParams = {};

    constructor(route = null)
    {
        if(route)
        {
            this.setFromRoute(route);
        }
    }

    setFromRoute(route)
    {
        this.setLanguage(route.params.lang);
        this.setEntity(route.params.entity_id);

        const sides = route.params.sides || [];
        this.parseSideParams(SIDE_RIGHT, sides);
        this.parseSideParams(SIDE_LEFT, sides);

        if(route.path.includes('order/lenses/edit'))
        {
            this.setAction('edit');
        }
        else
        {
            this.setAction(null);
        }

        this.queryParams = route.queryParams;
    }

    parseSideParams(side, sides)
    {
        const start = sides.indexOf(side);
        if(start === -1)
        {
            this.setIdentifierType(side, null);
            this.setOrderIdentifier(side, null);
            this.setPrototypeCode(side, null);

            return;
        }

        const end = sides.indexOf(this.getOtherSide(side));
        if(end > start)
        {
            sides = sides.slice(start + 1, end);
        }
        else
        {
            sides = sides.slice(start + 1);
        }

        const validKeys = Object.values(ID_TYPES);

        for(let i = 0; i < sides.length; i += 2)
        {
            const key   = sides[i];
            const value = sides[i + 1];

            if(validKeys.includes(key))
            {
                this.setIdentifierType(side, key);
                this.setOrderIdentifier(side, value);
            }
            else if(key === PROTOTYPE_CODE_KEY)
            {
                this.setPrototypeCode(side, value);
            }
        }
    }


    // ------------------------------------------------------------ PATH

    getPath()
    {
        const parts = ['', this.lang, this.entity_id, 'order', 'lenses'];
        const params = [];

        if(this.action)
        {
            parts.push('edit');
        }

        for(const side of [SIDE_RIGHT, SIDE_LEFT])
        {
            if(this.has(side))
            {
                parts.push(side);

                if(this.hasOrderIdentifier(side))
                {
                    parts.push(this.getIdentifierType(side));
                    parts.push(this.getOrderIdentifier(side));
                }

                if(this.hasPrototypeCode(side))
                {
                    parts.push(PROTOTYPE_CODE_KEY);
                    parts.push(this.getPrototypeCode(side));
                }
            }
        }

        let url = parts.join('/');

        if(!isEmpty(this.queryParams))
        {
            for(const key in this.queryParams)
            {
                params.push(key + '=' + this.queryParams[key]);
            }

            url += '?' + params.join('&');
        }

        return url;
    }


    // ------------------------------------------------------------ LANGUGAGE

    setLanguage(lang)
    {
        this.lang = lang;
    }


    // ------------------------------------------------------------ ENTITY

    setEntity(hashedEid)
    {
        this.entity_id = hashedEid;
    }


    // ------------------------------------------------------------ ACTION

    setAction(action)
    {
        this.action = action;
    }


    // ------------------------------------------------------------ SIDE

    has(side)
    {
        return (
            this.getIdentifierType(side) !== null &&
            this.getOrderIdentifier(side) !== null
        ) || this.getPrototypeCode(side) !== null;
    }


    // ------------------------------------------------------------ ORDER IDENTIFIER

    hasOrderIdentifier(side)
    {
        return this.getOrderIdentifier(side) !== null;
    }

    getOrderIdentifier(side)
    {
        const orderIdentifier = this[side].orderIdentifier;
        if(orderIdentifier === null)
        {
            return null;
        }

        const type = this.getIdentifierType(side);
        switch(type)
        {
            // Integers
            case ID_TYPES.LENS_SN:
            case ID_TYPES.BASKET_LINE_ID:
                return parseInt(orderIdentifier);

            // Strings
            case ID_TYPES.LENS_OEM_NUMBER:
            case ID_TYPES.ORDER_REFERENCE:
                return orderIdentifier;

            default:
                throw new RangeError(`Invalid order identifier type: "${type}"; raw value: ${orderIdentifier}`);
        }
    }

    setOrderIdentifier(side, identifier)
    {
        this[side].orderIdentifier = identifier;
    }

    getIdentifierType(side)
    {
        return this[side].identifierType;
    }

    setIdentifierType(side, identifierType)
    {
        this[side].identifierType = identifierType;
    }


    // ------------------------------------------------------------ LENS SN

    hasLensSn(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.LENS_SN && this.getOrderIdentifier(side) !== null;
    }

    getLensSn(side)
    {
        return this.getOrderIdentifier(side);
    }

    setLensSn(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.LENS_SN);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ LENS OEM NUMBER

    hasOemNumber(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.LENS_OEM_NUMBER && this.getOrderIdentifier(side) !== null;
    }

    getOemNumber(side)
    {
        return this.getOrderIdentifier(side);
    }

    setOemNumber(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.LENS_OEM_NUMBER);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ ORDER REFERENCE

    hasOrderReference(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.ORDER_REFERENCE && this.getOrderIdentifier(side) !== null;
    }

    getOrderReference(side)
    {
        return this.getOrderIdentifier(side);
    }

    setOrderReference(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.ORDER_REFERENCE);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ BASKET LINE ID

    hasBasketLineId(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.BASKET_LINE_ID && this.getOrderIdentifier(side) !== null;
    }

    getBasketLineId(side)
    {
        return this.getOrderIdentifier(side);
    }

    setBasketLineId(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.BASKET_LINE_ID);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ PROTOTYPE CODE

    hasPrototypeCode(side)
    {
        return this.getPrototypeCode(side) !== null;
    }

    getPrototypeCode(side)
    {
        return this[side].prototypeCode;
    }

    setPrototypeCode(side, prototypeCode)
    {
        this[side].prototypeCode = prototypeCode;
    }


    // ------------------------------------------------------------ QUERY PARAMS

    bypassEntityCheck(bypass = true)
    {
        this.queryParams.force_current_entity = (bypass) ? 1 : 0;

        return this;
    }


    // ------------------------------------------------------------ RESET

    reset(side)
    {
        this[side] = {
            identifierType:  null,
            orderIdentifier: null,
            prototypeCode:   null,
        };
    }


    // ------------------------------------------------------------ HELPERS

    getOtherSide(side)
    {
        return side === SIDE_LEFT ? SIDE_RIGHT : SIDE_LEFT;
    }
}

export default class UpdateOrders
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const orders = [];
        const rightAdaptation = this.v.$store.state.lensOrder.adaptations.right;
        const leftAdaptation = this.v.$store.state.lensOrder.adaptations.left;

        this.v.$store.commit('overlay/setText', this.v.$t('overlay.updating_orders'));

        if(rightAdaptation)
        {
            orders.push(rightAdaptation.toOrder());
        }

        if(leftAdaptation)
        {
            orders.push(leftAdaptation.toOrder());
        }

        return this.v.$store.dispatch('orders/update', { orders })
            .then(response =>
            {
                console.log('These orders have been updated:', orders);

                return Promise.resolve(response);
            });
    }
}

<template>
    <lsn-table :data="deliveryNotes" class="sl-delivery-notes" openable>
        <template #subtable-caption>
            {{ $t("invoicing.delivery_notes.table.title") }}
        </template>

        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-delivery-note__date">
                {{ $t("invoicing.table.headers.date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-delivery-note__reference">
                {{ $t("invoicing.table.headers.reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-delivery-note__price">
                {{ $t("invoicing.table.headers.amount") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-delivery-note__currency">
                {{ $t("invoicing.table.headers.currency") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-delivery-note__actions" />
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <delivery-note-line-summary :delivery-note="item" :tracking="tracking" :date-field="dateField" />
        </template>

        <template #details="{ item }">
            <delivery-note-line-details :delivery-note="item" />
        </template>
    </lsn-table>
</template>

<script>
import LsnTable                from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell            from '@/components/LsnTable/LsnTableCell.vue';
import DeliveryNoteLineSummary from '@/components/delivery-note/DeliveryNoteLineSummary.vue';
import DeliveryNoteLineDetails from '@/components/delivery-note/DeliveryNoteLineDetails.vue';

export default
{
    name: 'DeliveryNoteTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        DeliveryNoteLineSummary,
        DeliveryNoteLineDetails,
    },

    props:
    {
        deliveryNotes:
        {
            type:     Array,
            required: true,
        },

        /**
         * Which property of the delivery notes to use for the `date` column.
         */
        dateField:
        {
            type:    String,
            default: 'fermeture',
        },

        tracking:
        {
            type:    Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss">
/* Delivery Note Table */
.sl-delivery-notes
{
    --column-width: 100px;
}

/* Openable: Column width definitions */
.lsn-table.lsn-table--openable.sl-delivery-notes > .lsn-table__header,
.lsn-table.lsn-table--openable.sl-delivery-notes > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns: var(--open-toggle-width) repeat(4, var(--column-width)) calc(100% - var(--open-toggle-width) - (4 * var(--column-width)));
}

/* Not openable: Column width definitions */
.lsn-table:not(.lsn-table--openable).sl-delivery-notes > .lsn-table__header,
.lsn-table:not(.lsn-table--openable).sl-delivery-notes > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns: repeat(4, var(--column-width)) calc(100% - (4 * var(--column-width)));
}

/* Column alignments */
.sl-delivery-note__price,
.sl-delivery-note__actions
{
    @apply text-right;
}

.sl-delivery-note__currency
{
    @apply text-center;
}

/* Open sub-content: Align with parent table by removing padding-right */
.sl-delivery-notes > .lsn-table__body > .lsn-table__line > .lsn-table__line-details
{
    @apply pr-0;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table :data="virtualStockProposals" class="sl-virtual-stock-proposal-table">

        <!-- Embedded Table Header Caption -->
        <template #subtable-caption>
            <slot name="subtable-caption" />
        </template>

        <!-- Table Header -->
        <template #header>
            <lsn-table-cell class="sl-virtual-stock-proposal__date">
                {{ $t('invoicing.table.headers.date') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__reference">
                {{ $t('invoicing.table.headers.reference') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__paid-date">
                {{ $t("invoicing.table.headers.paid_date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__status">
                {{ $t('invoicing.table.headers.status') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__price">
                {{ $t('invoicing.table.headers.amount') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__balance">
                {{ $t('invoicing.table.headers.balance') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__currency">
                {{ $t('invoicing.table.headers.currency') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-virtual-stock-proposal__actions" />
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <virtual-stock-proposal-line-summary :virtual-stock-proposal="item" />
        </template>

    </lsn-table>
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import LsnTable                         from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell                     from '@/components/LsnTable/LsnTableCell.vue';
import VirtualStockProposalLineSummary  from '@/components/virtual-stock-proposal/VirtualStockProposalLineSummary.vue';

export default
{
    name: 'VirtualStockProposalTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        VirtualStockProposalLineSummary,
    },

    props:
    {
        virtualStockProposals:
        {
            type:     Object,
            required: true,
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-virtual-stock-proposal__price,
.sl-virtual-stock-proposal__balance
{
    @apply text-right;
}

.sl-due-virtual-stock-proposal-table .sl-virtual-stock-proposal__balance
{
    @apply font-bold;
}

.sl-virtual-stock-proposal__reference,
.sl-virtual-stock-proposal__paid-date,
.sl-virtual-stock-proposal__status,
.sl-virtual-stock-proposal__currency,
.sl-virtual-stock-proposal__due-date,
.sl-virtual-stock-proposal__actions
{
    @apply text-center;
}

</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line sl-order-form__line-grouped">
        <!-- Field Wrapper -->
        <div class="sl-order-form__field-wrapper">
            <!-- Label -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <label class="sl-order-form__label" v-html="parameter.form_label + ' :'"></label>

            <!-- Value -->
            <div class="sl-order-form__info ml-2 text-sm">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="inline-block align-middle" v-html="displayedValue"></div>

                <!-- Helper -->
                <div
                    v-if="helper"
                    v-tippy="{ animation : 'fade' }"
                    class="sl-order-form__info-help ml-1"
                    :content="helper"
                >
                    <mdi-icon icon="mdiInformationOutline" />
                </div>
            </div>

            <!-- todo: display error -->
        </div>

        <!-- Supplement -->
        <div v-if="supplement" class="sl-order-form__supplement-wrapper">
            <div class="sl-order-form__supplement">
                {{ supplement }}
            </div>
        </div>

        <!-- Note -->
        <div v-if="parameter.note" class="sl-order-form__note-wrapper mt-1">
            <div class="sl-order-form__note">
                {{ parameter.note }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractGroupedField from './AbstractGroupedField.vue';

export default
{
    name: 'GroupedInfoField',

    extends: AbstractGroupedField,

    computed:
    {
        helper()
        {
            return this.parameter.help;
        },

        displayedValue()
        {
            if(this.parameter.defined_value_label !== null)
            {
                return this.parameter.defined_value_label;
            }

            if(this.parameter.defined_value !== null)
            {
                return this.parameter.defined_value;
            }

            const tkey = `parameters.${this.parameter.code}.values.${this.modelValue}.label`;
            const translation = this.$t(tkey);
            if(translation !== tkey)
            {
                return translation;
            }

            return this.formattedValue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

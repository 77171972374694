<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-badge" :class="badgeClass">
        <div class="lsn-badge__caption">
            {{ caption }}
        </div>

        <div v-if="$slots.default" class="lsn-badge__contents">
            <slot />
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: "LsnBadge",

    props:
    {
        caption:
        {
            type:     String,
            required: true,
        },

        theme:
        {
            type:    String,
            default: "",
        },

        thick:
        {
            type:    Boolean,
            default: false,
        },
    },

    computed:
    {
        badgeClass()
        {
            return {
                "lsn-badge--thick":       this.thick,
                "lsn-badge--no-contents": !this.$slots.default,
                "lsn-badge--success":     this.theme === "success",
                "lsn-badge--warn":        this.theme === "warn",
                "lsn-badge--danger":      this.theme === "danger",
                "lsn-badge--disabled":    this.theme === "disabled",
            };
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
/* ------------------------------------------------------------ BASIC BADGES */
.lsn-badge
{
    @apply cursor-default inline-block align-top rounded bg-primary-100 text-primary-900 text-xs overflow-hidden shadow-sm transition-shadow
        hover:shadow-md;

    user-select: none;
}

.lsn-badge__caption
{
    @apply inline-block align-top px-2 py-0.5;
}

.lsn-badge__contents
{
    @apply inline-block align-top px-2 py-0.5 bg-primary-10 text-primary-700;
}

/* ------------------------------------------------------------ THICK BADGES */
.lsn-badge--thick
{
    @apply p-0 text-center;
}

.lsn-badge--thick .lsn-badge__caption,
.lsn-badge--thick .lsn-badge__contents
{
    @apply block px-3 py-1;
}

/* ------------------------------------------------------------ THEMES */
.lsn-badge--no-contents
{
    @apply text-white;
}

.lsn-badge--success
{
    &.lsn-badge--no-contents
    {
        @apply bg-green-600;
    }

    .lsn-badge__contents
    {
        @apply bg-green-100 text-green-700;
    }
}

.lsn-badge--warn
{
    &.lsn-badge--no-contents
    {
        @apply bg-yellow-600;
    }

    .lsn-badge__contents
    {
        @apply bg-yellow-100 text-yellow-700;
    }
}

.lsn-badge--danger
{
    &.lsn-badge--no-contents
    {
        @apply bg-red-600;
    }

    .lsn-badge__contents
    {
        @apply bg-red-100 text-red-700;
    }
}

.lsn-badge--disabled
{
    &.lsn-badge--no-contents
    {
        @apply bg-gray-600;
    }

    .lsn-badge__contents
    {
        @apply bg-gray-100 text-gray-400;
    }
}
</style>

export default Object.freeze({
    level1: [
        'SOFT',
        'RGP',
        'SCLERAL',
        'HYBRID',
        'ORTHOK',
        'COLORED',
        'MYOPIA_CONTROL',
        'LUNELLE',
        'LENSY',
        'ARCADIA',
        'CRISTALENS',
        'YSOPTIC',
    ],

    level2: [
        'SPHERICAL',
        'TORIC',
    ],

    level3: [
        'UNIFOCAL',
        'MULTIFOCAL',
    ],
});

import { ref, toValue } from 'vue';
import axios from '@/axios';

export default function useCsvDownloader(fileUrl, outputFilename)
{
    // ------------------------------------------------------------ DATA

    const isCsvDownloading = ref(false);


    // ------------------------------------------------------------ METHODS

    function downloadCsv()
    {
        // Prevent downloading multiple concurrent files
        if(isCsvDownloading.value)
        {
            return;
        }

        isCsvDownloading.value = true;

        axios.get(toValue(fileUrl), { timeout: 5000 })
            .then(response =>
            {
                // Use an in-memory <a> tag to download the base64-encoded PDF file
                const a    = document.createElement('a');
                a.href     = 'data:text/csv;charset=utf-8,' + response.data;
                a.target   = '_blank';
                a.download = toValue(outputFilename);
                a.click();
            })
            .catch(error =>
            {
                // Nothing
            })
            .then(() =>
            {
                isCsvDownloading.value = false;
            });
    }


    // ------------------------------------------------------------ EXPORT

    return {
        isCsvDownloading,
        downloadCsv,
    };
};

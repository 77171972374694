import { merge }       from 'lodash-es';
import axios           from '@/axios';
import { getInstance } from '@/store/mainStore';
import constants       from '@/constants/constants';
import LensPrototype   from './LensPrototype';
import Order           from './Order';
import BasketLine      from './BasketLine';

/**
 * @class
 */
export default class Adaptation
{
    // ------------------------------------------------------------ CLASS MEMBERS

    /**
     * Main Vuex store instance.
     * @var {Object}
     */
    store = null;

    /**
     * Related entity.
     * @var {Object}
     */
    entity = {};

    /**
     * Related order reference, if any.
     * @var {String}
     */
    reference = null;

    /**
     * Related basket line ID, if any.
     * @var {String}
     */
    basket_line_id = null;

    /**
     * Related prototype.
     * @var {LensPrototype}
     */
    prototype = null;

    /**
     * Related Online Fitting Guide, if any.
     * @var {LensPrototype}
     */
    ofg = null;

    /**
     * Is the related OFG open?
     * @var {Boolean}
     */
    ofgOpen = false;

    /**
     * Related article (available after pricing).
     * @var {Object}
     */
    article = null;

    /**
     * Related supplements (available after pricing).
     * @var {Object}
     */
    supplements = [];

    /**
     * Previous order reference (in case of re-order).
     * @var {String}
     */
    previous_order_reference = null;

    /**
     * Currency code (edit order only).
     * @var {String}
     */
    currency_code = null;

    /**
     * Customer price (edit order only).
     * Since there is a function "price", we
     * use "customer_price" here.
     * @var {String}
     */
    customer_price = null;

    /**
     * Special price (edit order only).
     * @var {String}
     */
    special_price = null;

    /**
     * Is currently pricing (status).
     * @var {Boolean}
     */
    pricing = false;


    // ------------------------------------------------------------ CONSTRUCTOR

    constructor()
    {
        this.store = getInstance();
        this.entity = this.store.state.account.cEntity;
    }


    // ------------------------------------------------------------ PROTOTYPE

    setPrototype(prototypeAttributes)
    {
        if(!this.prototype)
        {
            this.prototype = new LensPrototype(prototypeAttributes);
        }
        else
        {
            this.prototype.setAttributes(prototypeAttributes);
        }

        return this.prototype;
    }

    getPrototype()
    {
        return this.prototype;
    }


    // ------------------------------------------------------------ ONLINE FITTING GUIDE (OFG)

    setOfg(ofgAttributes)
    {
        this.ofg = new LensPrototype(ofgAttributes);
    }

    getOfg()
    {
        return this.ofg;
    }

    setOfgOpen(ofgOpen)
    {
        this.ofgOpen = !!ofgOpen;
    }

    getOfgOpen()
    {
        return this.ofgOpen;
    }


    // ------------------------------------------------------------ PRICING

    price(overridenValues = {})
    {
        console.log('Pricing start');

        // Set "pricing" status
        this.pricing = true;

        // Build URL
        const url = '/api/prototype/price/:eid/:prototypeCode'
            .replace(':eid', this.entity.id)
            .replace(':prototypeCode', this.prototype.code);

        // Get parameter values
        const data = {
            values:          merge({}, this.prototype.getValues(), overridenValues), // used to hack price with new values
            previous_values: this.prototype.getPreviousValues(),
        };

        // Run query
        return axios.post(url, data)
            .then(response =>
            {
                console.log('Pricing: response from CS Designer:', response.data);

                // Reset supplements array
                this.resetSupplement();
                const articleCodes = response.data;

                // Loop through returned articles and set main article and supplements
                for(const articleCode of articleCodes)
                {
                    const article = this.store.getters['catalog/getByCode'](articleCode);
                    if(!article || article.status === constants.articles.status.INACTIVE)
                    {
                        // fixme: Should also reject if trying to make a new order and the status is REORDER_ONLY
                        console.log(`Article ${articleCode} is inactive for entity ${this.entity.id}`);

                        return Promise.reject(response);
                    }

                    if(article.type === constants.articles.type.SUPPLEMENT)
                    {
                        this.addSupplement(article);
                        console.log('PRICED (supplement)', articleCode, article);
                    }
                    else
                    {
                        this.setArticle(article);
                        console.log('PRICED (article)', articleCode, article);
                    }
                };

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Pricing error', error);

                return Promise.reject(error);
            })
            .finally(() =>
            {
                this.pricing = false;
            });
    }

    setArticle(article)
    {
        this.article = article;
    }

    addSupplement(supplement)
    {
        this.supplements.push(supplement);
    }

    resetSupplement()
    {
        this.supplements = [];
    }


    // ------------------------------------------------------------ BASKET LINE

    toBasketLine()
    {
        const basketLine = new BasketLine({
            id:                       this.basket_line_id,
            article_code:             this.article.code,
            previous_order_reference: this.previous_order_reference,
            order_by:                 this.entity.id,
            delivery_to:              this.entity.id,
        });

        basketLine.supplements = this.supplements.map(supplement =>
        {
            return {
                quantity:     1,
                article_code: supplement.code,
            };
        });

        basketLine.setArticleDataAttribute('PROTOTYPE_CODE', this.getPrototype().code);

        if(this.ofg)
        {
            basketLine.setArticleDataAttribute('OFG', this.ofg.getValues());
        }

        this.prototype.getParameters().forEach((parameter, key) =>
        {
            const value = parameter.getValue();

            switch(parameter.data_section)
            {
                case 'article_data':
                    basketLine.setArticleDataAttribute(key, value);

                    // Save default value (if any)
                    const defaultValue = parameter.getDefaultValue();

                    if(defaultValue !== null)
                    {
                        basketLine.setArticleDataAttributeDefaultValue(key, defaultValue);
                    }
                    break;

                case 'logistics_data':
                    basketLine.setLogisticsDataAttribute(key, value);
                    break;

                case 'return_data':
                    basketLine.setReturnDataAttribute(key, value);
                    break;

                case 'extra':
                    basketLine.setExtraAttribute(key, value);
                    break;

                case 'NATIVE':
                    basketLine[key] = value;
                    break;
            }


        });

        console.log('Adpatation to basket line', this, basketLine);

        return basketLine;
    }


    // ------------------------------------------------------------ ORDER

    toOrder()
    {
        const order = new Order({
            reference:                this.reference,
            article:                  this.article,
            previous_order_reference: this.previous_order_reference,
            order_by:                 this.entity.id,
            delivery_to:              this.entity.id,
            special_price:            this.special_price,
        });

        order.supplements = this.supplements.map(supplement =>
        {
            return {
                quantity:     1,
                article_code: supplement.code,
            };
        });

        order.setArticleDataAttribute('PROTOTYPE_CODE', this.getPrototype().code);

        if(this.ofg)
        {
            order.setArticleDataAttribute('OFG', this.ofg.getValues());
        }

        this.getPrototype().getParameters().forEach((parameter, key) =>
        {
            const value = parameter.getValue();

            switch(parameter.data_section)
            {
                case 'article_data':
                    order.setArticleDataAttribute(key, value);
                    break;

                case 'logistics_data':
                    order.setLogisticsDataAttribute(key, value);
                    break;

                case 'return_data':
                    order.setReturnDataAttribute(key, value);
                    break;

                case 'extra':
                    order.setExtraAttribute(key, value);
                    break;

                case 'NATIVE':
                    order[key] = value;
                    break;
            }
        });

        console.log('Adpatation to order', this, order);

        return order;
    }
}

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'AbstractField',

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        side:
        {
            type:     String,
            required: true,
        },

        parameter:
        {
            type:    Object,
            default: () => ({}),
        },

        name:
        {
            type:     String,
            required: true,
        },

        error:
        {
            type:    String,
            default: null,
        },

        modelValue:
        {
            type:    [String, Number, Object, Boolean, Array],
            default: null,
        },
    },

    computed:
    {
        tabIndex()
        {
            return this.index * (this.side === 'left' ? 10 : 1);
        },

        supplement()
        {
            if(this.parameter.priced_article_code)
            {
                const article = this.$store.getters['catalog/getByCode'](this.parameter.priced_article_code);
                if(article)
                {
                    return `+ ${this.max2decimals(article.price)} ${article.currency_code}`;
                }
            }

            return null;
        },

        formattedValue()
        {
            return this.format(this.modelValue);
        },
    },

    methods:
    {
        max2decimals(price)
        {
            return parseFloat(price).toFixed(2).replace(/\.0{0,2}$/, '');
        },

        format(value)
        {
            if(value === null)
            {
                return null;
            }

            return this.$pf(value, this.parameter.code, 'value');
        },
    },
};
</script>

export default Object.freeze({
    status: Object.freeze({
        WAITING_FOR_REVIEW:     'WAITING_FOR_REVIEW',
        WAITING_FOR_DISPATCH:   'WAITING_FOR_DISPATCH',
        DISPATCHED:             'DISPATCHED',
        OUTSOURCED:             'OUTSOURCED',
        WAITING_FOR_PRODUCTION: 'WAITING_FOR_PRODUCTION',
        PRODUCTION:             'PRODUCTION',
        WAITING_FOR_SHIPPING:   'WAITING_FOR_SHIPPING',
        SHIPPED:                'SHIPPED',
    }),
});

export default class SetAdaptationValuesByPreviousOrder
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        let previousOrder = this.v.sides[this.side].previousOrder;

        // If there is a previous order.
        if(previousOrder)
        {
            let adaptation = this.v.$store.state.lensOrder.adaptations[this.side];

            // If this is a re-order => set previous reference
            if(!this.v.isOrderEdition)
            {
                adaptation.reference = null;
                adaptation.previous_order_reference = previousOrder.reference;

                console.log(`Adaptation previous reference was set to ${previousOrder.reference}`);
            }
            else // This is an order edition => set reference.
            {
                adaptation.reference = previousOrder.reference;
                adaptation.previous_order_reference = previousOrder.previous_order_reference;
                adaptation.currency_code = previousOrder.currency_code;
                adaptation.special_price = previousOrder.special_price;

                // Sum order price and all order supplements price.
                let fullPrice = previousOrder.price;

                for(let supplement of previousOrder.supplements)
                {
                    fullPrice += supplement.price;
                }

                adaptation.customer_price = fullPrice;

                console.log(`Adaptation reference was set to ${previousOrder.reference}`);
            }
        }

        return true;
    }
}

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="p-4 pl-10">
        <!-- Delivery Notes -->
        <delivery-note-table
            :delivery-notes="invoice.delivery_notes"
            :date-field="notesDateField"
            tracking
            embedded
        />

        <!-- Credit Notes -->
        <credit-note-table
            :credit-notes="invoice.credit_notes"
            :date-field="notesDateField"
            class="mt-4"
            embedded
        />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import DeliveryNoteTable from '@/components/delivery-note/DeliveryNoteTable.vue';
import CreditNoteTable   from '@/components/credit-note/CreditNoteTable.vue';

export default
{
    name: 'InvoiceLineDetails',

    components:
    {
        DeliveryNoteTable,
        CreditNoteTable,
    },

    props:
    {
        invoice:
        {
            type:     Object,
            required: true,
        },

        notesDateField:
        {
            type:    String,
            default: 'fermeture',
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>

import { merge } from 'lodash-es';
import axios     from '@/axios';

export default class TemplatePrototypeForms
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;

        if(lensOrderUrlManager.has(this.side))
        {
            const adaptation = this.v.$store.state.lensOrder.adaptations[this.side];
            const previousOrder = this.v.sides[this.side].previousOrder;

            // If there is a previous order.
            if(previousOrder && !adaptation.getPrototype().isInitialized())
            {
                return this.templateByOrder(lensOrderUrlManager, previousOrder, !this.v.isOrderEdition);
            }
            else if(lensOrderUrlManager.hasBasketLineId(this.side) && !adaptation.getPrototype().isInitialized())
            {
                return this.templateByBasketLine(lensOrderUrlManager);
            }
            else
            {
                return this.v.$store.dispatch('lensOrder/template', { side: this.side });
            }
        }

        console.log(`No prototype to template for ${this.side} side`);

        return true;
    }

    //-------------------------------------------------------------------------- SUB-PROCESSES

    template()
    {
        console.log(`Template prototype of ${this.side} side (simple)`);

        return this.v.$store.dispatch('lensOrder/template', { side: this.side });
    }

    /**
     * If "isReOrder" parameter is "true", only values that
     * are "preserved during re-order" (c.f. CS-Designer "parameters" tables)
     * are kept. If "isReOrder" is "false", all values from order are kept.
     * @param {Object} lensOrderUrlManager
     * @param {Object} order
     * @param {Boolean} isReOrder
     * @returns
     */
    templateByOrder(lensOrderUrlManager, order, isReOrder)
    {
        // Build URL for CS Designer
        const url = '/api/prototype/pre-process-values/:eid/:prototypeCode'
            .replace(':eid', this.v.cEntity.id)
            .replace(':prototypeCode', lensOrderUrlManager.getPrototypeCode(this.side));

        // POST values (order values).
        const values = merge({},
            order.article_data,
            order.logistics_data,
            order.return_data,
            order.extra
        );

        // POST data.
        const data = {
            values,
            options: { is_re_order: isReOrder },
        };

        console.log(`Pre-process values of ${this.side} side before templating it`, data);

        return axios.post(url, data)
            .then(response =>
            {
                // Lens values (returned by CS Designer preProcessValues())
                const values = response.data;

                console.log(`Template prototype of ${this.side} side with values from pre-process values from CS Designer`, values);

                return this.v.$store.dispatch('lensOrder/template', {
                    side:       this.side,
                    initValues: values,
                });
            });
    }

    templateByBasketLine(lensOrderUrlManager)
    {
        const basketLineId = lensOrderUrlManager.getBasketLineId(this.side);
        const basketLine = this.v.$store.getters['basket/getById'](basketLineId);

        // POST values (from basket line).
        const values = merge({},
            basketLine.article_data,
            basketLine.logistics_data,
            basketLine.return_data,
            basketLine.extra,
            { quantity: basketLine.quantity }
        );

        console.log(`Template prototype of ${this.side} side with values from basket line`, values);

        return this.v.$store.dispatch('lensOrder/template', {
            side:       this.side,
            initValues: values,
        });
    }
}

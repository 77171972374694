<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-return-table__column sl-return__reference">
        {{ oReturn.id_commande }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-table__column sl-return__serial-number">
        {{ oReturn.numero_sn }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-table__column sl-return__label">
        {{ oReturn.label }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-table__column sl-return__price">
        {{ formattedPrice }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-table__column sl-return__currency">
        {{ oReturn.fk_reference_monnaie }}
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'ReturnLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        oReturn:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        formattedPrice()
        {
            return this.$f(parseFloat(this.oReturn.display_price).toFixed(2), 'thousand');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>

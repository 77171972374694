<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-delivery-note-table__column sl-delivery-note__date">
        {{ deliveryNoteDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-delivery-note-table__column sl-delivery-note__reference">
        {{ deliveryNote.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-delivery-note-table__column sl-delivery-note__price">
        {{ f(deliveryNote.total_brut, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-delivery-note-table__column sl-delivery-note__currency">
        {{ deliveryNote.fk_reference_monnaie }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-delivery-note-table__column sl-delivery-note__actions">
        <template v-if="tracking">
            <a
                v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                :content="trackingStatus"
                :href="canTrack ? trackingUrl : '#!'"
                :target="canTrack ? '_blank' : '_self'"
                :class="{ 'lsn-table__link--disabled': !canTrack }"
                @click.stop
            >
                {{ t('invoicing.delivery_notes.track') }}
            </a>

            |
        </template>

        <lsn-loader-link
            :label="t('common.download')"
            :is-loading="isFileDownloading"
            @click.stop="downloadFile"
        />
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { useI18n }                 from 'vue-i18n';
import LsnTableCell                from '@/components/LsnTable/LsnTableCell.vue';
import LsnLoaderLink               from '@/components/LsnLoaderLink.vue';
import { getInstance as getStore } from '@/store/mainStore';
import { f }                       from '@/utils/vue-formatter';
import useFileDownloader           from '@/composables/FileDownloader';
import useDeliveryNoteComposable   from '@/composables/DeliveryNoteComposable';


// ------------------------------------------------------------ PROPS

const props = defineProps({
    deliveryNote:
    {
        type:     Object,
        required: true,
    },

    /**
     * The property of the delivery note to use for its `date` column.
     */
    dateField:
    {
        type:     String,
        required: true,
    },

    /**
     * Whether to show a tracking link.
     */
    tracking:
    {
        type:    Boolean,
        default: false,
    },
});


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = getStore();

// FileDownloader
const { isFileDownloading, downloadFile } = useFileDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.deliveryNote.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/delivery-note/${eid}/${documentId}/pdf`;
    },
    () => props.deliveryNote.reference + '.pdf'
);

// DeliveryNoteComposable
const {
    deliveryNoteDate,
    trackingUrl,
    trackingStatus,
    canTrack,
} = useDeliveryNoteComposable(props);
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>

/* ============
 * Auth Module
 * ============
 */

// import Cookie   from '@/utils/Cookie';
import { Idle } from 'idlejs';
import moment   from 'moment';
import axios    from '@/axios';
import { getUserConfigurations } from '@/vue-configurations';

const $uconfig = getUserConfigurations();

let refreshAccessTokenTimerId = null;

export function setupAuthModule()
{
    return {
        namespaced: true,

        state()
        {
            return {
                authenticated:  false, // If current user is authenticated
                sessionEndAtTs: null,  // Token expiration date as timestamp UNIX (in seconds)
            };
        },

        mutations:
        {
            /**
             * Set authentication status.
             *
             * @param {Object} state
             * @param {Boolean} status
             */
            setAuthenticationStatus(state, status)
            {
                state.authenticated = status;
            },

            /**
             * Set application as authenticated, save access token in
             * local storage and add token to HTTP header.
             *
             * @param {Object} state
             * @param {Object} tokens
             */
            login(state, tokens)
            {
                // Avoid setting a bad token
                if(tokens.refresh_token)
                {
                    state.authenticated = true;
                    localStorage.setItem('refresh_token', tokens.refresh_token);
                }
            },

            setAccessToken(state, tokens)
            {
                // Avoid setting a bad token
                if(tokens.access_token)
                {
                    localStorage.setItem('access_token', tokens.access_token);

                    // Set session expire timestamp from JWT token
                    let payload = tokens.access_token.split('.')[1];
                    payload = JSON.parse(window.atob(payload));
                    state.sessionEndAtTs = payload.exp;

                    // Set Axios token
                    axios.defaults.headers.common.Authorization = `Bearer ${tokens.access_token}`;
                }
            },

            setSsoCookie(state, ssoToken)
            {
                // Cookie.setItem('sso_token', ssoToken, 86400, null, import.meta.env.VITE_AUTH_API_SSO_DOMAIN);
            },
        },

        actions:
        {
            /**
             * Check if tokens exists (so a session).
             * @param {Object} dispatch
             * @param {Object} commit
             */
            check({ commit })
            {
                const accessToken = localStorage.getItem('access_token');
                const refreshToken = localStorage.getItem('refresh_token');

                if(accessToken !== null && refreshToken !== null)
                {
                    const parts = (accessToken).split('.');
                    const payload = JSON.parse(atob(parts[1]));
                    const expirationDate = moment.unix(payload.exp);

                    console.log('--- TOKEN EXPIRATION', expirationDate.format('DD.MM.YYYY HH:mm:ss'));

                    // If current access token is not expired => login.
                    if(!expirationDate.isBefore(moment()))
                    {
                        const tokens = { access_token: accessToken, refresh_token: refreshToken };

                        commit('login', tokens);
                        commit('setAccessToken', tokens);

                        console.log('-- ALREADY LOGGED IN');
                    }
                }
            },

            authenticate({ commit }, credentials)
            {
                const data = {
                    app_code:   import.meta.env.VITE_AUTH_API_APP_CODE,
                    app_secret: import.meta.env.VITE_AUTH_API_APP_SECRET,
                    username:   credentials.username,
                    password:   credentials.password,
                };

                return axios.post(import.meta.env.VITE_AUTH_API_LOGIN_LOCATION, data)
                    .then(response =>
                    {
                        commit('login', response.data);
                        commit('setAccessToken', response.data);
                        commit('setSsoCookie', response.data.sso_token);
                    });
            },

            autoRefreshAccessToken({ commit, state })
            {
                refreshAccessTokenTimerId = setInterval(() =>
                {
                    const currentTs = Date.now() / 1000; // PHP timestamps are in seconds, JavaScript's are in milliseconds

                    // If token will expire in the next 3 minutes
                    if( (state.sessionEndAtTs - currentTs) < 180 )
                    {
                        const data = {
                            app_code:      import.meta.env.VITE_AUTH_API_APP_CODE,
                            app_secret:    import.meta.env.VITE_AUTH_API_APP_SECRET,
                            refresh_token: localStorage.getItem('refresh_token'),
                        };

                        axios.post(import.meta.env.VITE_AUTH_API_REFRESH_LOCATION, data)
                            .then(response =>
                            {
                                commit('setAccessToken', response.data);
                            })
                            .catch(error =>
                            {
                                console.log(error.response);
                            });
                    }
                },
                2 * 60 * 1000); // every 2 minutes
            },

            checkIdle({ dispatch })
            {
                const idle = new Idle();
                idle.whenNotInteractive()
                    .within($uconfig.get('session_duration', 20))
                    .do(() =>
                    {
                        dispatch('logout');
                        dispatch('orders/stopWaitingForReviewRefreshInterval', null, { root: true });
                    })
                    .start();

                return true;
            },

            logout()
            {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                // Cookie.removeItem('sso_token', null, import.meta.env.VITE_AUTH_API_SSO_DOMAIN);

                location.reload();
            },

            // ------------------------------------------------------------ SSO (future)

            // ssoLogin({ commit })
            // {
            //     const data = {
            //         app_code:   import.meta.env.VITE_AUTH_API_APP_CODE,
            //         app_secret: import.meta.env.VITE_AUTH_API_APP_SECRET,
            //         sso_token:  Cookie.getItem('sso_token'),
            //     };

            //     return axios.$http.post(import.meta.env.VITE_AUTH_API_SSO_LOGIN_LOCATION, data)
            //         .then(response =>
            //         {
            //             commit('login', response.data);
            //             commit('SET_ACCESS_TOKEN', response.data);
            //             commit('SET_SSO_TOKEN', response.data.sso_token);

            //         })
            //         .catch(error =>
            //         {
            //             console.log(error.response);
            //         });
            // },
        },
    };
};

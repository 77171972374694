<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <router-link v-slot="{ navigate }" :to="{name: 'basket', params: $route.params}" custom>
        <div class="sl-navbar__icon-item" @click="navigate">
            <mdi-icon icon="mdiBasket" />

            <div v-if="itemsCount" class="sl-basket__count">
                {{ itemsCount }}
            </div>
        </div>
    </router-link>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'BasketMenu',

    computed:
    {
        itemsCount()
        {
            return this.$store.state.basket.lines.reduce(
                (carry, currentLine) => carry + currentLine.quantity, 0
            );
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-basket__count
{
    @apply absolute bg-yellow-400 text-primary-500 rounded-full text-center;

    right: 5px;
    top: 5px;
    padding: 3px 6px;
    font-size: 0.675rem;
}
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="flex h-screen w-full justify-center bg-primary-10">
        <div class="text-center" style="margin-top: 20%;">
            <img class="inline" src="/img/logo-swisslens-blue.svg">

            <br>
            <br>

            <img class="inline" src="/img/loader-2.gif">

            <div class="mt-8 text-lg text-gray-400">
                {{ $t('common.loading') }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'Initializing',
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

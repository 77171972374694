export default class SilentlySetPrototypeCodesInUrl
{
    /**
     * Vue component object.
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;
        const rightPreviousOrder  = this.v.sides.right.previousOrder;
        const leftPreviousOrder   = this.v.sides.left.previousOrder;
        let prototypeCode;
        let basketLineId;
        let basketLine;
        let needUrlUpdate = false;

        if(rightPreviousOrder && !lensOrderUrlManager.hasPrototypeCode('right'))
        {
            prototypeCode = rightPreviousOrder.getArticleDataAttribute('PROTOTYPE_CODE');
            lensOrderUrlManager.setPrototypeCode('right', prototypeCode);

            needUrlUpdate = true;
        }

        if(leftPreviousOrder && !lensOrderUrlManager.hasPrototypeCode('left'))
        {
            prototypeCode = leftPreviousOrder.getArticleDataAttribute('PROTOTYPE_CODE');
            lensOrderUrlManager.setPrototypeCode('left', prototypeCode);

            needUrlUpdate = true;
        }

        if(!lensOrderUrlManager.hasPrototypeCode('right') && lensOrderUrlManager.hasBasketLineId('right'))
        {
            basketLineId  = lensOrderUrlManager.getBasketLineId('right');
            basketLine    = this.v.$store.getters['basket/getById'](basketLineId);
            prototypeCode = basketLine.getArticleDataAttribute('PROTOTYPE_CODE');
            lensOrderUrlManager.setPrototypeCode('right', prototypeCode);

            needUrlUpdate = true;
        }

        if(!lensOrderUrlManager.hasPrototypeCode('left') && lensOrderUrlManager.hasBasketLineId('left'))
        {
            basketLineId = lensOrderUrlManager.getBasketLineId('left');
            basketLine = this.v.$store.getters['basket/getById'](basketLineId);
            prototypeCode = basketLine.getArticleDataAttribute('PROTOTYPE_CODE');
            lensOrderUrlManager.setPrototypeCode('left', prototypeCode);

            needUrlUpdate = true;
        }

        if(needUrlUpdate)
        {
            this.v.dispatchOnRouteChange = false;

            this.v.$router.replace({ path: lensOrderUrlManager.getPath() })
                .then(() =>
                {
                    console.log('URL has been updated with prototype code(s) from previous order(s) (URL param watcher disabled)');

                    this.v.dispatchOnRouteChange = true;
                });
        }

        return true;
    }
}

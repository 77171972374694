<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-ofg__line" :class="{ 'sl-ofg__line--error': error }">
        <div class="sl-ofg__field">
            <!-- Label -->
            <div class="sl-ofg__label">
                <label :for="name">
                    {{ parameter.form_label }}

                    <!-- Note -->
                    <tippy
                        v-if="hasNote || hasDefaultValue"
                        tag="div"
                        :placement="side"
                        class="sl-ofg__note"
                    >
                        <mdi-icon
                            icon="mdiInformationOutline"
                            class="w-4 h-4 text-gray-400"
                        />

                        <template #content>
                            <ofg-field-tooltip-content :parameter="parameter" />
                        </template>
                    </tippy>
                </label>
            </div>

            <!-- Input -->
            <div class="sl-ofg__input">
                <textarea
                    :id="name"
                    v-model.lazy="tempValue"
                    :name="name"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    :disabled="!parameter.is_enabled"
                    @focus="onFocus"
                    @blur="onBlur"
                ></textarea>
            </div>

            <!-- Helper -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="helper" class="sl-ofg__helper" v-html="helper"></div>
        </div>

        <!-- Error -->
        <div v-if="error" class="sl-ofg__error">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OfgAbstractField       from './OfgAbstractField.vue';
import OfgFieldTooltipContent from '../OfgFieldTooltipContent.vue';

export default
{
    name: 'OfgTextareaField',

    components:
    {
        OfgFieldTooltipContent,
    },

    extends: OfgAbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.modelValue;
                }
                else
                {
                    // @todo Must return formatted values.
                    return this.modelValue;
                }
            },
            set(value)
            {
                if(value === null || value === this.value)
                {
                    return;
                }

                this.$emit('update:modelValue', value);
            },
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-sider class="sl-wfr sl-widgets w-80">
        <div class="sl-wfr__title">
            {{ $t('orders.waiting_for_review.title') }}
        </div>

        <div
            v-for="(orders, entityId) in ordersByClient"
            :key="entityId"
            class="sl-wfr__entity sl-widget"
        >
            <div class="sl-wfr__head sl-widget__header sl-widget__header--flex">
                <div class="sl-widget__title">
                    {{ orders[0].entity.name }}
                </div>

                <div class="sl-wfr__country">
                    {{ orders[0].entity.country }}
                </div>
            </div>

            <div class="sl-widget__body">
                <tippy
                    v-for="order in orders"
                    :key="order.id"
                    tag="div"
                    placement="left"
                    class="sl-wfr__order"
                    :class="order.logistics_data.URGENT ? 'sl-wfr__order--urgent' : ''"
                    @click="editOrder(order)"
                >
                    <div>{{ formatDate(order.odate) }} - {{ order.article_data.PATIENT_REFERENCE1 }}</div>

                    <template #content>
                        <div class="flex items-center mb-1 text-primary-500 font-medium">
                            <div>
                                {{ order.reference }}
                            </div>

                            <div class="mx-1 text-primary-400">
                                &middot;
                            </div>

                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <div v-html="getPrototypeName(order.article_data.PROTOTYPE_CODE)"></div>
                        </div>

                        <div class="italic">
                            {{ order.article_data.LENS_COMMENT }}
                        </div>
                    </template>
                </tippy>
            </div>
        </div>
    </lsn-sider>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { groupBy }         from 'lodash-es';
import moment              from 'moment';
import LensOrderUrlManager from '@/views/order/lenses/LensOrderUrlManager';

export default
{
    name: 'OrdersWaitingForReview',

    emits: ['close'],

    computed:
    {
        ordersByClient()
        {
            const orders = JSON.parse(JSON.stringify(this.$store.state.orders.waitingForReview));

            return groupBy(orders.sort((o1, o2) =>
            {
                let sortOrder = 0;

                // First, sort by urgency.
                if(o1.logistics_data.URGENT && !o2.logistics_data.URGENT)
                    sortOrder -= 4;
                else if(o2.logistics_data.URGENT && !o1.logistics_data.URGENT)
                    sortOrder += 4;

                // Then, by country.
                if(o1.entity.country < o2.entity.country)
                    sortOrder -= 2;
                else if(o1.entity.country > o2.entity.country)
                    sortOrder += 2;

                // Finally, by reference.
                sortOrder += o1.reference < o2.reference ? -1 : 1;

                return sortOrder;
            }), 'order_by');
        },
    },

    methods:
    {
        editOrder(order)
        {
            const lensOrderUrlManager = new LensOrderUrlManager();
            const lensPairId = order.article_data.LENS_PAIR_ID;
            let side1 = order.article_data.LENS_SIDE;
            let side2 = null;
            let otherSideOrder = null;

            lensOrderUrlManager.setLanguage(this.$route.params.lang);
            lensOrderUrlManager.setEntity(order.order_by);
            lensOrderUrlManager.setAction('edit');

            if(lensPairId)
            {
                otherSideOrder = this.$store.getters['orders/getWaitingForReviewOrderByLensPairId'](lensPairId);

                if(otherSideOrder)
                {
                    side2 = otherSideOrder.article_data.LENS_SIDE;
                }
            }

            if(side1 == 'single' && side2 == 'left')
            {
                side1 = 'right';
            }
            else if(side1 == 'single' && side2 == 'right')
            {
                side1 = 'left';
            }
            else if(side1 == 'single' && side2 == 'single')
            {
                side1 = 'right';
                side2 = 'left';
            }
            else if(side1 == 'single' && side2 === null)
            {
                side1 = 'left';
            }

            lensOrderUrlManager.setOrderReference(side1, order.reference);

            if(side2)
            {
                if(side2 == 'single' && side1 == 'left')
                {
                    side2 = 'right';
                }
                else if(side2 == 'single' && side1 == 'right')
                {
                    side2 = 'left';
                }

                lensOrderUrlManager.setOrderReference(side2, otherSideOrder.reference);
            }

            const routeData = this.$router.resolve({
                path: lensOrderUrlManager.getPath(),
            });

            window.open(routeData.href, '_blank');

            this.$emit('close');
        },

        formatDate(date)
        {
            return moment(date).format('DD.MM.YY HH:MM');
        },

        getPrototypeName(prototypeCode)
        {
            return this.$store.state.prototypes.prototypes.find(p => p.code === prototypeCode)?.label || prototypeCode;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-wfr
{
    @apply mt-16 overflow-auto;
}

.sl-wfr__title
{
    @apply mb-4 text-3xl text-center text-primary-700 font-thin;
}

.sl-wfr__entity
{
    @apply text-gray-600;
}

.sl-wfr__head
{
    @apply items-start;
}

.sl-wfr__country
{
    @apply flex shrink-0 justify-center items-center mt-0.5 ml-2 rounded w-8 h-5 border border-gray-400 text-gray-500 text-xs text-center;
}

.sl-wfr__order
{
    @apply cursor-pointer rounded px-2 py-2 leading-none
        hover:bg-primary-500/20 hover:text-primary-500;

    &:hover
    {
        @apply backdrop-filter backdrop-blur-xs;
    }

    &:not(:last-child)
    {
        @apply mb-1;
    }
}

.sl-wfr__order--urgent
{
    @apply text-red-600
        hover:bg-red-500/20 hover:text-red-600;
}
</style>

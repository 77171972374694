import { getInstance as getI18n }  from '@/i18n';
import { getInstance as getStore } from '@/store/mainStore';
import Formatters from './Formatters';

export function pf(parameterValue, parameterCode, context = null)
{
    const i18n  = getI18n();
    const store = getStore();
    const formattersString = store.getters['parameters/getFormatters'](parameterCode);

    if(formattersString)
    {
        const formatters = formattersString.split('|');

        for(const formatter of formatters)
        {
            const parts = formatter.split(':');
            const fn    = parts[0];
            const args  = [];

            if(parts[1])
            {
                args.push(...parts[1].split(','));
            }

            args.push({ i18n, pcode: parameterCode, context });
            args.unshift(parameterValue);

            parameterValue = Formatters[fn].apply(null, args);
        }
    }

    return parameterValue;
};

export function f(val, formattersString)
{
    const i18n = getI18n();
    const formatters = formattersString.split('|');

    for(const formatter of formatters)
    {
        const parts = formatter.split(':');
        const fn    = parts[0];
        const args  = [];

        if(parts[1])
        {
            args.push(...parts[1].split(','));
        }

        args.push({ i18n });
        args.unshift(val);

        val = Formatters[fn].apply(null, args);
    }

    return val;
};

export default
{
    install(app)
    {
        app.config.globalProperties.$pf = pf;
        app.config.globalProperties.$f  = f;
    },
};

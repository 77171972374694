<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-table-cell class="sl-virtual-stock-proposal__date">
        {{ invoiceDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-virtual-stock-proposal__reference">
        {{ virtualStockProposal.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__paid-date">
        {{ paidDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-virtual-stock-proposal__status">
        <div class="lsn-table__tag">
            {{ status }}
        </div>
    </lsn-table-cell>

    <lsn-table-cell class="sl-virtual-stock-proposal__price">
        {{ f(virtualStockProposal.total_brut, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-virtual-stock-proposal__balance">
        {{ f(virtualStockProposal.solde, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-virtual-stock-proposal__currency">
        {{ virtualStockProposal.fk_reference_monnaie }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-virtual-stock-proposal__actions">
        <lsn-loader-link
            :label="t('common.download')"
            :is-loading="isFileDownloading"
            @click.stop="downloadFile"
        />
    </lsn-table-cell>

</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>

import { computed }                from 'vue';
import { useI18n }                 from 'vue-i18n';
import moment                      from 'moment';
import LsnTableCell                from '@/components/LsnTable/LsnTableCell.vue';
import LsnLoaderLink               from '@/components/LsnLoaderLink.vue';
import { getInstance as getStore } from '@/store/mainStore';
import { f }                       from '@/utils/vue-formatter';
import useFileDownloader           from '@/composables/FileDownloader';


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = getStore();

const { isFileDownloading, downloadFile } = useFileDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.virtualStockProposal.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/virtual-stock-proposal/${eid}/${documentId}/pdf`;
    },
    () => props.virtualStockProposal.reference + '.pdf'
);

// ------------------------------------------------------------ PROPS

const props = defineProps({
    virtualStockProposal:
    {
        type:     Object,
        required: true,
    },
});

// ------------------------------------------------------------ COMPUTED

/**
 * The formatted date of the invoice.
 *
 * @return {string}
 */
const invoiceDate = computed(() =>
{
    return moment(props.virtualStockProposal.fermeture).format('DD.MM.YYYY');
});

/**
 * The localized status text of the virtual stock proposal.
 *
 * @return {string} Either "Partial payment", "Open" or "Paid".
 */
const status = computed(() =>
{
    if(props.virtualStockProposal.solde == 0)
    {
        return t('invoicing.status.paid');
    }
    else if(props.virtualStockProposal.solde == props.virtualStockProposal.total_brut)
    {
        return t('invoicing.status.open');
    }
    else
    {
        return t('invoicing.status.partial_payment');
    }
});

/**
 * The formatted date when the invoice was paid (acquittement).
 *
 * @return {string}
 */
const paidDate = computed(() =>
{
    if(!props.virtualStockProposal.acquittement)
    {
        return '-';
    }

    return moment(props.virtualStockProposal.acquittement).format('DD.MM.YYYY');
});

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">



</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__date">
        {{ monthlySummaryDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__reference">
        {{ monthlySummary.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__total">
        {{ f(monthlySummary.total_net, 'decimal:2|thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__currency">
        {{ currency }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-monthly-summary-table__column sl-monthly-summary__actions">
        <lsn-loader-link
            :label="t('common.download')"
            :is-loading="isFileDownloading"
            @click.stop="downloadFile"
        />
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { computed }                from 'vue';
import { useI18n }                 from 'vue-i18n';
import moment                      from 'moment';
import LsnTableCell                from '@/components/LsnTable/LsnTableCell.vue';
import LsnLoaderLink               from '@/components/LsnLoaderLink.vue';
import { getInstance as getStore } from '@/store/mainStore';
import { f }                       from '@/utils/vue-formatter';
import useFileDownloader           from '@/composables/FileDownloader';


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = getStore();

const { isFileDownloading, downloadFile } = useFileDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.monthlySummary.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/monthly-summary/${eid}/${documentId}/pdf`;
    },
    () => props.monthlySummary.reference + '.pdf'
);


// ------------------------------------------------------------ PROPS

const props = defineProps({
    monthlySummary:
    {
        type:     Object,
        required: true,
    },
});


// ------------------------------------------------------------ COMPUTED

/**
 * The formatted date of the monthly summary.
 * @return {string}
 */
const monthlySummaryDate = computed(() =>
{
    return moment(props.monthlySummary.insertion).format('DD.MM.YYYY');
});

const currency = computed(() =>
{
    return store.state.catalog.articles[0]?.currency_code;
});
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Re-order: not same patient modal warning -->
    <lsn-modal :is-open="isOpen">
        <div class="sl-prompt sl-prompt--info sl-choose-patient-modal">
            <div class="sl-prompt__contents">
                <div class="sl-prompt__title">
                    {{ $t('order.choose_patient_modal.title') }}
                </div>

                <div class="sl-prompt__text">
                    <div class="mb-4 leading-5 text-gray-500">
                        {{ $t('order.choose_patient_modal.text') }}
                    </div>

                    <!-- Patients -->
                    <div class="flex">
                        <div class="grow w-1/2 p-4 mr-4 border border-primary-200 rounded-xl cursor-pointer transition hover:bg-primary-10 hover:border-primary-300" @click="next(rightPatientReference)">
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-sm text-primary-500">
                                    {{ $t('common.sn_colon') }}
                                    {{ rightLensSerialNumber }}
                                </div>

                                <div class="mb-2 text-sm text-gray-500">
                                    {{ $t('order.choose_patient_modal.patient_reference') }}
                                </div>

                                <div class="text-primary-500 tracking-wider">
                                    {{ rightPatientReference }}
                                </div>
                            </div>
                        </div>

                        <div class="grow w-1/2 p-4 ml-4 border border-primary-200 rounded-xl cursor-pointer transition hover:bg-primary-10 hover:border-primary-300" @click="next(leftPatientReference)">
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-sm text-primary-500">
                                    {{ $t('common.sn_colon') }}
                                    {{ leftLensSerialNumber }}
                                </div>

                                <div class="mb-2 text-sm text-gray-500">
                                    {{ $t('order.choose_patient_modal.patient_reference') }}
                                </div>

                                <div class="text-primary-500 tracking-wider">
                                    {{ leftPatientReference }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Buttons -->
            <div class="sl-prompt__actions">
                <button class="sl-prompt__button sl-prompt__button--white" @click="close">
                    {{ $t('common.cancel') }}
                </button>
            </div>
        </div>
    </lsn-modal>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'ChoosePatientModal',

    props:
    {
        isOpen:
        {
            type:    Boolean,
            default: false,
        },
        sides:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['close', 'next'],

    computed:
    {
        rightLensSerialNumber()
        {
            return this.sides.right.previousOrder?.article_data?.LENS_SERIAL_NUMBER;
        },

        leftLensSerialNumber()
        {
            return this.sides.left.previousOrder?.article_data?.LENS_SERIAL_NUMBER;
        },

        rightPatientReference()
        {
            return this.sides.right.previousOrder?.article_data?.PATIENT_REFERENCE1;
        },

        leftPatientReference()
        {
            return this.sides.left.previousOrder?.article_data?.PATIENT_REFERENCE1;
        },
    },

    methods:
    {
        next(patientReference)
        {
            this.sides.right.previousOrder.setArticleDataAttribute('PATIENT_REFERENCE1', patientReference);
            this.sides.left.previousOrder.setArticleDataAttribute('PATIENT_REFERENCE1', patientReference);

            this.$emit('next', 'ValidateLensSidesConsistency');

            this.close();
        },

        close()
        {
            this.$emit('close');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-choose-patient-modal
{
    width: 550px;
}
</style>
